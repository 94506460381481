import React from "react";
import Facilities from "./Facilities";
import imgAbout from "./imgAbout.png";
import Logo from '../../../assets/img/RedCar.png';
import './members.css'
import IconBox from "../../../components/Icons/IconBox";
import { FaRegThumbsUp, FaPhoneAlt, FaCog, FaMoneyBillWave} from "react-icons/fa";
import { useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";




const Members = ({dir}) => {

    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
      });


  const { t } = useTranslation();


  return (
    <>
      <Facilities dir={dir}/>
      

      <div className="container" style={{ marginTop: '100px', marginBottom: '100px' }}>
        <div className="row d-flex mt-5" style={{ justifyContent: 'space-between', alignItems: "center", marginTop: '200px' }}>
          {isDesktop ? (
            <>
              <div className="divD col-md-5" style={{ backgroundColor: '' }}>
                <img src={Logo} alt="Logo" className="img-fluid" />
              </div>

              <div className="divG col-md-6 py-2" style={{ paddingLeft: '55px', paddingRight: '55px' }}>
                <div className={` mb-1 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{ fontSize: '20px' }}>
                  {t('about.title')}
                </div>

                <p className={` mb-3 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>
                  {t('about.description')}
                </p>

                <div className="div">
                  <div className="divItem d-flex mb-4" style={{ backgroundColor: '' }}>
                    <div className="col-md-2 d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaMoneyBillWave h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="col-md-10">
                      <h5 className={`${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} >{t('about.features.easyPrices')}</h5>
                      <h6 className={`${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`}>{t('about.features.easyPricesDescription')}</h6>
                    </div>
                  </div>

                  <div className="divItem d-flex mb-4" style={{ backgroundColor: '' }}>
                    <div className="col-md-2 d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaCog h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="col-md-10">
                      <h5 className={`${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>{t('about.features.breakdownAssistance')}</h5>
                      <h6 className={`${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`}>{t('about.features.breakdownAssistanceDescription')}</h6>
                    </div>
                  </div>

                  <div className="divItem d-flex mb-4" style={{ backgroundColor: '' }}>
                    <div className="col-md-2 d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaRegThumbsUp h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="col-md-10">
                      <h5 className={`${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>{t('about.features.trustedService')}</h5>
                      <h6 className={`${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`}>{t('about.features.trustedServiceDescription')}</h6>
                    </div>
                  </div>

                  <div className="divItem d-flex" style={{ backgroundColor: '' }}>
                    <div className="col-md-2 d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaPhoneAlt h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="col-md-10">
                      <h5 className={`${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>{t('about.features.customerSupport')}</h5>
                      <h6 className={`${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`}>{t('about.features.customerSupportDescription')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="divG col-md-7 py-2">
                <div className={` mb-1 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{ fontSize: '20px' }}>
                  {t('about.title')}
                </div>

                <p className={` mb-4 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>
                  {t('about.description')}
                </p>

                <div className="div" style={{ marginBottom: '40px' }}>
                  <div className="divItem d-flex mb-4" style={{ justifyContent: '' }}>
                    <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center', marginRight: '20px' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaMoneyBillWave h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="">
                      <h5 className={` ${dir === 'ltr' ? 'LatoM' : 'cairoM'}`}>{t('about.features.easyPrices')}</h5>
                      <h6 className={` ${dir === 'ltr' ? 'Lato' : 'cairoR'}`}>{t('about.features.easyPricesDescription')}</h6>
                    </div>
                  </div>

                  <div className="divItem d-flex mb-4" style={{ justifyContent: '' }}>
                    <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center', marginRight: '20px' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaCog h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="">
                      <h5 className={` ${dir === 'ltr' ? 'LatoM' : 'cairoM'}`}>{t('about.features.breakdownAssistance')}</h5>
                      <h6 className={` ${dir === 'ltr' ? 'Lato' : 'cairoR'}`}>{t('about.features.breakdownAssistanceDescription')}</h6>
                    </div>
                  </div>

                  <div className="divItem d-flex mb-4" style={{ justifyContent: '' }}>
                    <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center', marginRight: '20px' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaRegThumbsUp h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="">
                      <h5 className={` ${dir === 'ltr' ? 'LatoM' : 'cairoM'}`}>{t('about.features.trustedService')}</h5>
                      <h6 className={` ${dir === 'ltr' ? 'Lato' : 'cairoR'}`}>{t('about.features.trustedServiceDescription')}</h6>
                    </div>
                  </div>

                  <div className="divItem d-flex" style={{ justifyContent: '' }}>
                    <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center', marginRight: '20px' }}>
                      <IconBox pointer as='box' p={3} cursor="pointer" variant={''} rounded={'full'} h={"50px"} w={"50px"} bg={'#6518FF'}>
                        <FaPhoneAlt h={"16px"} w={"16px"} color={'white'} />
                      </IconBox>
                    </div>
                    <div className="">
                      <h5 className={` ${dir === 'ltr' ? 'LatoM' : 'cairoM'}`}>{t('about.features.customerSupport')}</h5>
                      <h6 className={` ${dir === 'ltr' ? 'Lato' : 'cairoR'}`}>{t('about.features.customerSupportDescription')}</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divD col-md-5">
                <img src={Logo} alt="Logo" className="img-fluid" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Members;
