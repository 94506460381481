import React from 'react'
import ContactElements from './ContactElements'
import  './Contact.css'
import SendMessage from './SendMessage';
import Ftr from "../../components/footer/Ftr";
import { Box, Heading, Text, useColorMode } from '@chakra-ui/react';
import WorkZoneMap from './WorkZoneMap';
import colors from '../../utils/colors';
import { useTranslation } from 'react-i18next';


const Contact = ({dir}) => {
  const { t } = useTranslation();
  const colorMode = useColorMode();
    
        
    
  return (
    
    <>

    <Box mt={'120px'} mb={'80px'}>
        <ContactElements dir={dir}/>
    </Box>


    <div className="container mb-5">
        <div className="row" style={{justifyContent:'space-between'}}>
                <div className="col-md-6 mb-5" style={{}}>
                    <div className="div">
                        <Text fontSize={'3xl'} className={`${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} >{t('contactPage.heading')}</Text>
                        <hr className='mb-3'/>
                        {/* <Text fontSize={'md'} mb={5} className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{t('contactPage.description')}</Text> */}
                        <h5 style={{fontSize:'1rem'}}  className={`mb-4 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{t('contactPage.description')}</h5>

                    </div>

                    <WorkZoneMap />


                </div>
                <div className="col-md-5">
                    <SendMessage dir={dir}/>
                </div>
        </div>
    </div>
    <Ftr/>

    </>
  )
}

export default Contact