// userSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    updateUserLocalisation: (state, action) => {
      if (state.userInfo) {
        state.userInfo.localisation = action.payload;
      }
    },
  },
});

export const { setUserInfo, updateUserLocalisation } = userSlice.actions;

export const selectUserInfo = (state) => state.user.userInfo;

export default userSlice.reducer;




// // userSlice.js

// import { createSlice } from '@reduxjs/toolkit';

// export const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     userInfo: null,
//   },
//   reducers: {
//     setUserInfo: (state, action) => {
//       state.userInfo = action.payload;
//     },
//   },
// });

// export const { setUserInfo } = userSlice.actions;

// export const selectUserInfo = (state) => state.user.userInfo;

// export default userSlice.reducer;
