import React, { useState, useEffect } from "react";
import "./tracker.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from "react-i18next";

const MouseGradientTracker = ({dir}) => {
  const [gradient, setGradient] = useState("");
  const { t } = useTranslation();

  const trackMouse = (e) => {
    const x = e.clientX;
    const y = e.clientY;
    setGradient(`radial-gradient(circle at ${x}px ${y}px, #6415FF, #181c2e)`);
  };

  useEffect(() => {
    window.addEventListener("mousemove", trackMouse);
    return () => window.removeEventListener("mousemove", trackMouse);
  }, []);

//   return <div className="tracker" style={{ background: gradient }}></div>;

return (
    <>
      <div className=" trackermb-5 pt-5" style={{backgroundColor: "",}}>
          <div className="div container d-flex justify-content-between " style={{background: gradient ,borderRadius:'20px 20px 0px 0px'}} >
              <div className='' style={{padding:'15px 5px'}} >
                <h2 className={`text-left mt-2 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}  style={{color:'#fff' }}>
                   {t('luxury')} 
                </h2>
                </div>
                <div>
                    <ArrowDropDownIcon  sx = {{fontSize:"80px"}} style={{color: '#fff', cursor: 'default' , float: 'right'}}/>
                </div>
          </div>
        </div>
    </>
  )
};

export default MouseGradientTracker;
