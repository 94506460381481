import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, HStack, Stack, Tooltip, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import IconBox from '../Icons/IconBox';
import { SiFireship } from 'react-icons/si';
import colors from '../../utils/colors';
import { baseUrlNet } from '../../utils/useRequest';

const Card = ({ id, pricePerDay, lettersPer90Kms, name, placesNumber, hasHeater, mainImage, model, outOfStock, highDemand, dir }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const { t } = useTranslation();
  const styleCarBox = {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    overflow: 'clip'
  };

  const bgColorCard = useColorModeValue('#fff', '#202c33');

  return (
    <div className='text-center shadow' style={{ position: 'relative', height: '490px', backgroundColor: bgColorCard, width: isMobile ? "80vw" : "320px", borderRadius: '25px', marginBottom: "1rem" }}>
      
      {highDemand && (
        <Tooltip hasArrow  className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} placement='left' label={t('card.highDemand')} colorScheme='teal'>
          <div style={{ position: 'absolute', top: '145px', right: '30px', zIndex: '3' }}>
            <IconBox pointer as='box' p={3} cursor="pointer" variant={'solid'} rounded={'full'} h={"45px"} w={"45px"} bg={colors.sidebarColor} disabled>
              <SiFireship h={"16px"} w={"16px"} color={'#6415FF'} />
            </IconBox>
          </div>
        </Tooltip>
      )}

      <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <h2 className={`mt-4 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{ color: '#6415FF', marginRight: '10px', fontSize: '30px', fontWeight: '600' }}>
          {Math.floor(pricePerDay)} {t('card.currency')}
        </h2>
        <h6 className={`mt-4 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{ color: '#A7A7A7' }}>{t('card.perDay')}</h6>
      </div>
      <Tooltip hasArrow className='Montserrat' placement='bottom' label={`${name}`} zIndex={89} colorScheme='purple'>
        <Heading as={'h3'} noOfLines={1} className='mb-5' style={{ fontFamily: 'Montserrat', fontSize: '28px', fontWeight: '600' }}>{`${model}`}</Heading>
      </Tooltip>
      <div className='mb-4 d-flex mt-2' style={{ styleCarBox, height: '190px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={`${baseUrlNet}${mainImage}`} alt="Car" className="img-fluid mb-3" style={{ display: 'inline-block' }} />
      </div>
      <div className="row">
        <ul className={`d-flex col-md-12 mt-1 mb-0 justify-content-around ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} style={{ fontFamily: '', fontSize: '13px', fontWeight: '500' }}>
          <li>{t('card.seats', { count: placesNumber })}</li>
          <li>{t('card.acHeater')}</li>
          {/* <li>{hasHeater ? t('card.acHeater') : t('card.acNoHeater')}</li> */}
          <li>{t('card.fuelConsumption', { count: lettersPer90Kms })}</li>
        </ul>
      </div>
      <hr />
      {outOfStock ? (
        <div className="d-flex px-1 mt-4" style={{ alignItems: 'center', justifyContent: 'space-around', flexWrap: "wrap", gap: "1rem" }}>
          <Button cursor={''} className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} colorScheme='red' variant={'outline'} borderRadius={'20px'} w={'12rem'} fontSize={'12px'} >
            {t('card.outOfStock')}
          </Button>
        </div>
      ) : (
        <HStack justifyContent={'space-evenly'}  className="px-1 mt-4" >
          <Link to={`/car-details/${id}`}>
            <Button className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} color={'#fff'} bgColor={'#6200EE'} borderRadius={'20px'} fontSize={'12px'} w={'8rem'}>
              {t('card.rentNow')}
            </Button>
          </Link>

          <Link to={`/car-details/${id}`}>
            <Button className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} color={'#fff'} bgColor={'#181c2e'} borderRadius={'20px'} fontSize={'12px'}  w={'8rem'}>
              {t('card.viewDetails')}
            </Button>
          </Link>
          
        </HStack>

        // <Box className="d-flex px-1 mt-4" style={{ alignItems: 'center', justifyContent: 'space-around', gap: "1rem" }}>
        // <div className={`botton text-center ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>
        //   <Link to={`/car-details/${id}`}>
        //     <button className="btn btn-block p-2"  style={{ backgroundColor: colors.yellowSecondary, color: '#fff', borderRadius: '20px', width: '8rem', fontSize: '12px' }}>
        //       {t('card.rentNow')}
        //     </button>
        //   </Link>
        // </div>
        // <div className={`botton text-center ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>
        //   <Link to={`/car-details/${id}`}>
        //     <button className="btn btn-block p-2" style={{ backgroundColor: colors.greenPrimary, color: '#fff', borderRadius: '20px', width: '8rem', fontSize: '12px' }}>
        //       {t('card.viewDetails')}
        //     </button>
        //   </Link>
        // </div>
        // </Box>
      )}
    </div>
  );
}


export default Card;










