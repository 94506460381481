import { Box, Flex, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import React from "react";
import colors from "../../utils/colors.js";

const HoursWorked = ({ title, percentage, chart }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { colorMode } = useColorMode();


  const bgCard = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );


  const bgColorCard = useColorModeValue(colors.sidebarColor,  '#242526')

  return (
    <Card p='28px 10px 16px 0px' mb={{ sm: "26px", lg: "0px" }} 
    
    // bg={colorMode === "dark" ?  bgCard : 'white'}
    bgColor={bgColorCard}
    >
      <CardHeader mb='20px' pl='22px'>
        <Flex direction='column' alignSelf='flex-start'>
          <Text  color={textColor}  fontSize='lg' fontWeight='bold' className={'jakB'} mb='6px'>
            {title}
          </Text>
          {/* <Text fontSize='md' fontWeight='medium' color='gray.400'>
            <Text
              as='span'
              color={percentage > 0 ? "green.400" : "red.400"}
              fontWeight='bold'>
              {`${percentage}%`} plus
            </Text>{" "}
            in 2024
          </Text> */}
        </Flex>
      </CardHeader>
      <Box w='full' h={{ sm: "300px" }} ps='8px' bgColor={''}>
        {chart}
      </Box>
    </Card>
  );
};

export default HoursWorked;
