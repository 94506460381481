import React from "react";
import ReactApexChart from "react-apexcharts";
import { FormControl, Stack, Box, Text } from "@chakra-ui/react";
import axios from "axios";
import { baseURL } from "../../utils/useRequest";
import DateRangeModal from "./dateModal/DateRangeModal";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    const isDarkMode = props.colorMode === 'dark';

    this.state = {
      chartData: [],
      chartOptions: {
        chart: {
          type: 'bar'
        },
        xaxis: {
          type: "datetime", // Use 'datetime' type for dates
          categories: [],
          labels: {
            format: 'dd/MM', // Format dates as 'day/month/year'
            style: {
              colors: isDarkMode ? '#FFFFFF' : '#000000'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: isDarkMode ? '#FFFFFF' : '#000000'
            }
          }
        },
        legend: {
          show: true,
          labels: {
            colors: isDarkMode ? '#FFFFFF' : '#000000'
          }
        },
        grid: {
          strokeDashArray: 5,
        },
        fill: {
          colors: ["#fecd07"],
        },
        colors: ["#008ffb"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: true
        }
      },
      startDate: props.startDate,
      endDate: props.endDate,
      token: props.token
    };

    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.fetchDataFromApi();
  }

  fetchDataFromApi = async () => {
    const { startDate, endDate, token } = this.state;

    try {
      const response = await axios.get(`${baseURL}/demands/count-by-range?startDate=${startDate}&endDate=${endDate}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const demandCounts = response.data;

      // Extract dates and counts for the chart
      const categories = Object.keys(demandCounts);
      const demandData = Object.values(demandCounts);

      const chartData = [
        {
          name: "Nombre de Demandes",
          data: demandData
        }
      ];

      this.setState((prevState) => ({
        chartData,
        chartOptions: {
          ...prevState.chartOptions,
          xaxis: {
            ...prevState.chartOptions.xaxis,
            categories: categories.map(date => new Date(date).getTime()),
          },
        },
      }));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  handleDateRangeSelect = ({ startDateRange, endDateRange }) => {
    this.setState(
      {
        startDate: startDateRange,
        endDate: endDateRange,
      },
      this.fetchDataFromApi
    );
  };

  render() {
    const { chartData } = this.state;
    const hasData = chartData.length > 0 && chartData[0].data.length > 0;

    return (
      <>
        <Stack height={"100%"} bgColor={""} position={"relative"}>
          <FormControl
            maxW={"250px"}
            id="endDate"
            isRequired
            position={"absolute"}
            top={"-50px"}
            right={"0"}
          >
            <DateRangeModal onDateRangeSelect={this.handleDateRangeSelect} />
          </FormControl>

          {!hasData && (
            <Box
              position="absolute"
              top="35%"
              left="50%"
              transform="translate(-50%, -70%)"
              zIndex="1"
            >
              <Text className="jakR" fontSize="lg" color="gray.500">
                Pas de données
              </Text>
            </Box>
          )}

          <ReactApexChart
            ref={this.chartRef}
            options={this.state.chartOptions}
            series={this.state.chartData}
            type="bar"
            width="100%"
            height="100%"
          />
        </Stack>
      </>
    );
  }
}

export default LineChart;




// import React from "react";
// import ReactApexChart from "react-apexcharts";
// import { FormControl, Stack, Box, Text } from "@chakra-ui/react";
// import axios from "axios";
// import { baseURL } from "../../utils/useRequest";
// import DateRangeModal from "./dateModal/DateRangeModal";
// import { useSelector } from "react-redux";

// class LineChart extends React.Component {
//   constructor(props) {
//     super(props);

//     const isDarkMode = props.colorMode === 'dark';

//     this.state = {
//       chartData: [],
//       chartOptions: {
//         xaxis: {
//           type: "category",
//           categories: [],
//           labels: {
//             style: {
//               colors: isDarkMode ? '#FFFFFF' : '#000000'
//             }
//           }
//         },
//         yaxis: {
//           labels: {
//             style: {
//               colors: isDarkMode ? '#FFFFFF' : '#000000'
//             }
//           }
//         },
//         legend: {
//           show: true,
//           labels: {
//             colors: isDarkMode ? '#FFFFFF' : '#000000'
//           }
//         },
//         grid: {
//           strokeDashArray: 5,
//         },
//         fill: {
//           type: "gradient",
//           gradient: {
//             shade: "light",
//             type: "vertical",
//             shadeIntensity: 0.5,
//             gradientToColors: undefined,
//             inverseColors: true,
//             opacityFrom: [0.4, 0.8],
//             opacityTo: [0,0],
//             stops: [],
//           },
//           colors: ["#008ffb", "#FB7E3A" ],
//         },
//         colors: ["#008ffb", "#FB7E3A"],
//       },
//       startDate: props.startDate,
//       endDate: props.endDate,
//       token: props.token
//     };

//     this.chartRef = React.createRef();
//   }

//   componentDidMount() {
//     this.fetchDataFromApi();
//   }

//   fetchDataFromApi = async () => {
//     const { startDate, endDate, token } = this.state;
//     try {
//       const response = await axios.get(`${baseURL}/demands/count-by-range?startDate=${startDate}&endDate=${endDate}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       });

//       const demandCounts = response.data;

//       // Extract dates and counts for the chart
//       const categories = Object.keys(demandCounts);
//       const demandData = Object.values(demandCounts);

//       const chartData = [
//         {
//           name: "Nombre de Demandes",
//           data: demandData
//         }
//       ];

//       this.setState((prevState) => ({
//         chartData,
//         chartOptions: {
//           ...prevState.chartOptions,
//           xaxis: {
//             ...prevState.chartOptions.xaxis,
//             categories: categories,
//           },
//         },
//       }));
//     } catch (error) {
//       console.error("Error fetching data: ", error);
//     }
//   };

//   handleDateRangeSelect = ({ startDateRange, endDateRange }) => {
//     this.setState(
//       {
//         startDate: startDateRange,
//         endDate: endDateRange,
//       },
//       this.fetchDataFromApi
//     );
//   };

//   render() {
//     const { chartData } = this.state;
//     const hasData = chartData.length > 0 && chartData[0].data.length > 0;

//     return (
//       <>
//         <Stack height={"100%"} bgColor={""} position={"relative"}>
//           <FormControl
//             maxW={"250px"}
//             id="endDate"
//             isRequired
//             position={"absolute"}
//             top={"-50px"}
//             right={"0"}
//           >
//             <DateRangeModal onDateRangeSelect={this.handleDateRangeSelect} />
//           </FormControl>

//           {!hasData && (
//             <Box
//               position="absolute"
//               top="35%"
//               left="50%"
//               transform="translate(-50%, -70%)"
//               zIndex="1"
//             >
//               <Text className="jakR" fontSize="lg" color="gray.500">
//                 Pas de données
//               </Text>
//             </Box>
//           )}

//           <ReactApexChart
//             ref={this.chartRef}
//             options={this.state.chartOptions}
//             series={this.state.chartData}
//             type="area"
//             width="100%"
//             height="100%"
//           />
//         </Stack>
//       </>
//     );
//   }
// }

// export default LineChart;

