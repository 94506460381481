import img1 from './aero.webp';
import img2 from './casa.webp';
import img3 from './marrakech.jpg';
import img4 from './tanger.jpg';

export const products = [
  {
    id: '1',
    value: '0',
    textKey: 'citiesLocations.aeroMohammedV.text',
    subTextKey: 'citiesLocations.aeroMohammedV.subText',
    imageUrl: img1
  },
  {
    id: '2',
    value: '3',
    textKey: 'citiesLocations.casablanca.text',
    subTextKey: 'citiesLocations.casablanca.subText',
    imageUrl: img2
  },
  {
    id: '3',
    value: '0',
    textKey: 'citiesLocations.marrakech.text',
    subTextKey: 'citiesLocations.marrakech.subText',
    imageUrl: img3
  },
  {
    id: '4',
    value: '0',
    textKey: 'citiesLocations.tanger.text',
    subTextKey: 'citiesLocations.tanger.subText',
    imageUrl: img4
  }
];