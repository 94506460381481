import { Avatar, Box, Flex, Grid, HStack, Icon, Image, SimpleGrid, Skeleton, SkeletonCircle, SkeletonText, SlideFade, Stack, Text, VStack, useColorMode, useDisclosure } from '@chakra-ui/react';
import React from 'react'
import Card from '../../../../components/Card/Card';

import colors from '../../../../utils/colors';

const SkeltonCard = () => {

    const { colorMode } = useColorMode();
  return (
    <Card  ms={{ lg: "24px" }}  bgColor={colorMode === 'dark' ? '#242526' : colors.sidebarColor} borderRadius={"24px"}>
              <Flex direction="column" alignItems="center" rounded="md" px="4" py={5}
                      position="relative" 
                      bg={colorMode === "dark" ? ''  : ''}
                      borderRadius='20px'>

                <Box position="absolute" inset="0" height="32"  bgColor={colorMode === "dark" ?  '#202C33'  : '#513238'} roundedTop="inherit">
                  <Skeleton height='inherit' borderRadius='20px 20px 0px 0px  '></Skeleton>
                </Box>



                {/* <SkeletonText size='32' mt='25px'/> */}
                <VStack spacing="1" flex="1"  mt='5px'>
                    <Box  bg={colorMode === "dark" ?  ''  : 'white'} height={'150px'} width={{base:'190px', lg:'370px'}} >
                      <Skeleton mt='4'  height='inherit'   borderRadius='20px'/>
                    </Box>
                </VStack>

                
                
                <HStack justifyContent={'space-between'} width={'full'} mt={14}>

                  <VStack>
                    <Box p='16px' pe={{ sm: '16px', md: '8px', lg: '16px' }} minW={{ sm: '180px', md: '120px', lg: '200px' }} borderRadius='16px' mb='16px' boxShadow='lg' bg={colorMode === 'dark' ? '#323A44' : "#fff"} >
                      <SkeletonText mt='4' noOfLines={2} spacing='4' skeletonHeight='2' />
                    </Box>

                    <Box p='16px' pe={{ sm: '16px', md: '8px', lg: '16px' }} minW={{ sm: '180px', md: '120px', lg: '200px' }} borderRadius='16px' mb='16px' boxShadow='lg' bg={colorMode === 'dark' ? '#323A44' : "#fff"} >
                      <SkeletonText mt='4' noOfLines={2} spacing='4' skeletonHeight='2' />
                    </Box>
                  </VStack>
                  
                  <Stack position="relative" bgColor={''} width={"170px"} height={'170px'} justifyContent="center" alignItems="center" 
                    >

                       
                      

                        <Box position="absolute" borderRadius="50%" width="75%" height="75%" backgroundColor={colorMode === 'dark' ? '#242526' : "#fff"} zIndex={2}
                        />

                        <Box width={"140px"} height={'140px'} zIndex={1}>
                          <SkeletonCircle size="140px" />
                        </Box>


                        <VStack spacing="1" flex="1" zIndex={2} position="absolute" >
                           
                            <Box  bg={colorMode === "dark" ?  ''  : 'white'} width={'40px'}>
                              <SkeletonText mt='2' noOfLines={1}  skeletonHeight='6' />
                            </Box>
                            <Box  bg={colorMode === "dark" ?  ''  : 'white'} width={'50px'}>
                              <SkeletonText mt='2' noOfLines={1}  skeletonHeight='3' />
                            </Box>
                            
                        </VStack>




                  </Stack>
                </HStack>

               
                <HStack justifyContent={'space-between'} width={'full'} mt={4} mb={2}>
                          
                          <Box  bg={colorMode === "dark" ?  ''  : 'white'} width={'100%'}>
                            <SkeletonText mt='4' noOfLines={1}  skeletonHeight='4' />
                          </Box>

                          <Box  bg={colorMode === "dark" ?  ''  : 'white'} width={'100%'}>
                            <SkeletonText mt='4' noOfLines={1}  skeletonHeight='4' />
                          </Box>
                         
                </HStack>
              </Flex>
          </Card>
  )
}

export default SkeltonCard
