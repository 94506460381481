import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  IconButton,
  Badge,
  Avatar,
  VStack,
} from '@chakra-ui/react';
import DeleteItem from './DeleteItem';
import { FiEdit2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMemberInfo } from '../../../redux/slices/memberSlice';
import { baseURL } from '../../../utils/useRequest';
import axios from 'axios';
import { FaUserGroup } from 'react-icons/fa6';
import { jwtDecode } from 'jwt-decode';
import MessageModal from './MessageModal';

const MemberTable = ({ initialMembers, startIndex, endIndex, removeDeletedMember }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [members, setMembers] = useState(initialMembers);
  const [imageDataMap, setImageDataMap] = useState({});
  const token = useSelector(state => state.token.token.access_token);
  const role = useSelector(state => jwtDecode(state.token.token.access_token).role.toString());

  // useEffect(() => {
  //   const fetchAvatars = async () => {
  //     const imageDataMapCopy = {};
  //     await Promise.all(members.slice(startIndex, endIndex).map(async (member) => {
  //       if (member.imageFileId) {
  //         try {
  //           const response = await axios.get(`${baseURL}/v1/file/files/${member.imageFileId}`, {
  //             headers: { Authorization: `Bearer ${token}` }
  //           });
  //           const imageData = `data:image/png;base64,${response.data.data}`;
  //           imageDataMapCopy[member.id] = imageData;
  //         } catch (error) {
  //           console.error("Error fetching image data:", error);
  //         }
  //       } else {
          
  //         imageDataMapCopy[member.id] = null; 
  //       }
  //     }));
  //     setImageDataMap(imageDataMapCopy);
  //   };

  //   fetchAvatars();
  // }, [members, startIndex, endIndex, token]);

  
  useEffect(() => {
    setMembers(initialMembers);
  }, [initialMembers]);

  const handleButtonClick = (member) => {
    dispatch(setMemberInfo(member));
    navigate(`/dash/modifier-membre`);
  };

  const formatCreatedAt = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    
    return `${hours}:${minutes} - ${day}/${month}/${year}`;
  };
 
  return (
    <Table>
      <Thead>
        <Tr bgColor={'gray.200'}>
          <Th>Avatar</Th>
          <Th>Le nom complet</Th>
          <Th>Email</Th>
          <Th>Télephone</Th>
          <Th>Date de Réception</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {members.slice(startIndex, endIndex).map((member) => (
          <Tr key={member.id}>

            <Td>
              <Avatar me={{ md: "22px" }} src={''} w="45px" h="45px" borderRadius="full" name={`${member.name}`} />
            </Td>

            <Td><Text color="muted" className='jakB' textTransform={'uppercase'}>{member.name}</Text></Td>

            <Td><Text color="muted" className='jakR'>{member.email}</Text></Td>


            <Td>
                <Badge size="xl" colorScheme={"yellow"}>{member.phoneNumber}</Badge>
            </Td>

            <Td><Text color="muted" className='jakR'>{formatCreatedAt(member.createdAt)}</Text></Td>


            <Td>
              <HStack spacing="1">
               
               <MessageModal message={member.message}/>
               <DeleteItem  firstname={member.name} email={member.email} id={member.id} removeDeletedMember={removeDeletedMember}/>
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default MemberTable;
