import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Divider,
    Heading,
    Stack,
    VStack,
    HStack,
    IconButton,
    Badge,
    useToast,
  } from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi';
import { baseURL } from '../../../utils/useRequest';
import axios from 'axios';
import { useSelector } from 'react-redux';


const DeleteItem = ({id, email, removeDeletedMember}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast();
    const token = useSelector(state => state.token.token.access_token);


    const handleDelete = async () => {
        try {
            const response = await axios.delete(
                `${baseURL}/messages/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            removeDeletedMember(email);
            console.log('removeDeletedMembe', email )
            toast({
                title: "Message supprimé avec succès",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            onClose();
           

        } catch (error) {
            console.error("Erreur lors de la suppression du message :", error);
            toast({
                title: "Erreur lors de la suppression du message",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            onClose();
        }
    };

   
    

    

  return (
    <>
       <IconButton icon={<FiTrash2 fontSize="1.25rem" />} colorScheme='red' aria-label="Delete member" onClick={onOpen}/>
        <Modal isCentered size={{ base: 'sm', md: 'xl',sm:'sm' }} isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" blockScrollOnMount={false}
        borderRadius="3xl" borderLeft="16px" borderColor="teal.400">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader className={'jakB'} textAlign={'center'} >Supprimer le message</ModalHeader>
            <ModalCloseButton />
            <Divider my={0}/>
   
            <ModalBody>
            <Text className='jakR' fontSize='md'>La suppression de ce message est irréversible.</Text>
            
            </ModalBody>

            <ModalFooter>
                <Button className={'jakR'} width={'10em'} variant={'solid'} size='sm' mr={3} onClick={onClose}>
                    Annuler
                </Button>
                <Button className={'jakR'} colorScheme="red" width={'10em'} size='sm' onClick={handleDelete}>
                    Supprimer
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
  )
}

export default DeleteItem