import { Avatar, Box, Flex, Grid, HStack, Icon, Image, SimpleGrid, Skeleton, SkeletonCircle, SkeletonText, SlideFade, Stack, Text, VStack, useColorMode, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card/Card';
import CardBody from '../../../../components/Card/CardBody';
import DemandCongeCard from './DemandCongeCard';
import StatsCongeInDemand from './StatsCongeInDemand';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import colors from '../../../../utils/colors';
import { baseUrlNet } from '../../../../utils/useRequest';
import SkeltonCard from './SkeltonCard';
import RetourCarCard from './retourCar/RetourCarCard';

const DemandsResult = ({demands, tab}) => {

    const { colorMode } = useColorMode();
    const { isOpen, onToggle } = useDisclosure()

    const [selectedDemand, setSelectedDemand] = useState(null);
    const [selectedCardId, setSelectedCardId] = useState(null); 

  const handleDemandClick = (demand) => {
    setSelectedDemand(demand);
    setSelectedCardId(demand.id);
  };

  const useRole = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.role.toString();
  };

  const role = useRole();

  const token = useSelector(state => state.token.token.access_token);

  const [imageData, setImageData] = useState(null);
  const styleCarBox = {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    overflow: 'clip',
  }


 // Reset selectedDemand to null when switching to Tab 0
 useEffect(() => {
  if (tab === 0) {
    setSelectedDemand(null);
    setSelectedCardId(null)
  }
}, [tab]);


  return (
    <>
       {tab === 0  &&  (
          <Grid templateColumns={{ sm: "1fr", lg: "60% 39%" }} p={0} gap={{base:'10', md:'0'}} >
          <Card
            // my={{ lg: "24px" }}
            me={{ lg: "24px" }}
            // bgImage={colorMode === "dark" ? "none" : bgGradient}
            bgColor={colorMode === "dark" ? "#242526" : colors.sidebarColor}
            px={5}
            py={2}
            borderRadius={"24px"}
            height={"531px"}
            sx={{
              overflow: "hidden", 
              "&:hover": {
                overflowY: "scroll", 
              },
            }}
          >
            <Flex direction="column">
              <CardBody>
                <Flex direction="column" w="100%">
                  {demands.map((demand) => {
                      return (
                          <DemandCongeCard
                              key={demand.id}
                              demand={demand}
                              onDemandClick={handleDemandClick}
                              isSelected={demand.id === selectedCardId} 
                          />
                      );
                  })}
                </Flex>
              </CardBody>
            </Flex>
          </Card>

          {selectedDemand === null ? (
            <>
                <SkeltonCard/>
            </> 
            ) : (
            <>
            <Card  ms={{ lg: "24px" }}  bgColor={colorMode === 'dark' ? '#242526' : colors.sidebarColor}  borderRadius={"24px"}>
                <Flex direction="column" alignItems="center" rounded="md" px="4" py={5}
                        position="relative" 
                        bg={colorMode === "dark" ? ''  : colors.sidebarColor}
                        borderRadius='20px'>

                  <Box position="absolute" inset="0" height="32"  
                  bgColor={colorMode === "dark" ?  '#929293'  : colors.greenPrimary}
                  roundedTop="inherit" />
                  <Box mb={20} className=' d-flex' style={{styleCarBox, backgroundColor:'',height:'100px',justifyContent:'center', alignItems:'center'}} 
                  zIndex={100} mt={'30px'} bgColor={''} >
                              <img src={`${baseUrlNet}${selectedDemand.carDetails.mainImage}`} alt="Car" className="img-fluid mb-3" style={{ display: 'inline-block' }} />
                  </Box>

                  <StatsCongeInDemand selectedDemand={selectedDemand} />

                </Flex>
            </Card>
            </>
            )}
    
          </Grid>
      )}



      {tab === 1 &&  (

        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} p={0}  >
        <Card
          // my={{ lg: "24px" }}
          me={{ lg: "24px" }}
          // bgImage={colorMode === "dark" ? "none" : bgGradient}
          bgRepeat="no-repeat"
          bgSize="cover"
          bgColor={colorMode === "dark" ? "#242526" : ""}
          px={5}
          py={2}
          borderRadius={"24px"}
          height={"531px"}
          sx={{
            overflow: "hidden", 
            "&:hover": {
              overflowY: "scroll", 
            },
          }}
        >
          <Flex direction="column">
            <CardBody>
            <SimpleGrid columns={{ sm: 1, lg: 2 }} spacingX={7} spacingY={5}>
            {demands.map((demand) => {
                    return (
                        <RetourCarCard
                            key={demand.id}
                            demand={demand}
                            onDemandClick={handleDemandClick}
                            isSelected={demand.id === selectedCardId} 
                            tab={tab}
                        />
                    );
                })}
            </SimpleGrid>
            </CardBody>
          </Flex>
        </Card>
        </Grid>
      )}


      {(tab === 2 || tab === 3) &&  (

        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} p={0}  >
        <Card
          // my={{ lg: "24px" }}
          me={{ lg: "24px" }}
          // bgImage={colorMode === "dark" ? "none" : bgGradient}
          bgRepeat="no-repeat"
          bgSize="cover"
          bgColor={colorMode === "dark" ? "#242526" : ""}
          px={5}
          py={2}
          borderRadius={"24px"}
          height={"531px"}
          sx={{
            overflow: "hidden", 
            "&:hover": {
              overflowY: "scroll", 
            },
          }}
        >
          <Flex direction="column">
            <CardBody>
            <SimpleGrid columns={{ sm: 1, lg: 2 }} spacingX={7} spacingY={0}>
            {demands.map((demand) => {
                    return (
                        <DemandCongeCard
                            key={demand.id}
                            demand={demand}
                            onDemandClick={handleDemandClick}
                            // isSelected={demand.id === selectedCardId} 
                            tab={tab}
                            // imageMain={selectedDemand.carDetails.mainImage}
                        />
                    );
                })}
            </SimpleGrid>
            </CardBody>
          </Flex>
        </Card>
        </Grid>
      )}
    </>
  )
}

export default DemandsResult




