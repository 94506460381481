// passwordResetSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, clearUser } = passwordResetSlice.actions;
export const selectUserReset = (state) => state.passwordReset.user;


export default passwordResetSlice.reducer;
