import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTab: 0, // Initial selected tab index
};

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
  },
});

export const { setSelectedTab } = tabSlice.actions;

export default tabSlice.reducer;
