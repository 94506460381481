import React from 'react'
import TopNavbar from '../../components/navbars/TopNavbar'
import Ftr from "../../components/footer/Ftr";
import Members from "./members/Members";
import Services from './Services';

const About = ({dir}) => {
  return (
    <>
  
    <Members dir={dir} className='mt-5'/>

    <Ftr/>
    </>
  )
}

export default About