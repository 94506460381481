import React, { useState } from 'react';
import { baseURL } from '../../utils/useRequest';
import axios from 'axios';
import { useColorModeValue, useToast } from '@chakra-ui/react';
import colors from '../../utils/colors';
import { useTranslation } from 'react-i18next';

const SendMessage = ({dir}) => {
  const initialData = {
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  };

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const { t } = useTranslation();

  const handleMessage = async (e) => {
    e.preventDefault();
    if (!data.name || !data.email || !data.phoneNumber || !data.message) {
      toast({
        description: t('contactPage.sendMessage.toast.warning'),
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
      return;
    }
    try {
      setLoading(true);
      console.log('messages', data);
      const response = await axios.post(`${baseURL}/open/messages`, data);
      setLoading(false);
      setData(initialData);
      toast({
        description: "Message has been sent successfully, we will contact you asap:)",
        status: "success",
        duration: 5000,
        isClosable: true,
        position:'bottom-left'
      });
    } catch (error) {
      setLoading(false);
      toast({
        description: "An error occurred, please try again later",
        status: "error",
        duration: 5000,
        isClosable: true,
        position:'bottom-left'
      });
    }
  };

  

  const onChange = (attr) => {
    setData(prevData => ({ ...prevData, ...attr }));
  };


  const bgColorCard = useColorModeValue(colors.yellowPrimary, colors.buttonBgColor);


  return (
    <form onSubmit={handleMessage}>
      <div className="card border-0 shadow" style={{ backgroundColor: '#6200EE', color: '#fff', borderRadius: '20px' }}>
        <div className="card-body">
          <div className="card-text p-2">
            <div className="form-group ">
              <input
                onChange={(e) => onChange({ name: e.target.value })}
                value={data.name}
                type='text'
                className={`form-control py-3 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} 
                placeholder={t('contactPage.sendMessage.form.namePlaceholder')}
                style={{ borderRadius: '20px', backgroundColor: '#fff' }}
              />
            </div><br />

            <div className="form-group ">
              <input
                onChange={(e) => onChange({ email: e.target.value })}
                value={data.email}
                type='email'
                className={`form-control py-3 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} 
                placeholder={t('contactPage.sendMessage.form.emailPlaceholder')}
                style={{ borderRadius: '20px', backgroundColor: '#fff' }}
              />
            </div><br />

            <div className="form-group">
              <input
                onChange={(e) => onChange({ phoneNumber: e.target.value })}
                value={data.phoneNumber}
                type='tel'
                className={`form-control py-3 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} 
                placeholder={t('contactPage.sendMessage.form.phonePlaceholder')}
                style={{ borderRadius: '20px', backgroundColor: '#fff', textAlign: dir === 'ltr' ? 'left' : 'right' }}
              />
            </div>
            
            <br />

            <div className="form-group  ">
              <textarea
                onChange={(e) => onChange({ message: e.target.value })}
                value={data.message}
                className={`form-control ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} 
                rows="8"
                placeholder={t('contactPage.sendMessage.form.messagePlaceholder')}
                style={{ borderRadius: '20px', backgroundColor: '#fff' }}
              />
            </div>

            <hr />

            <div className={`botton text-center ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} style={{marginBottom:'10px'}}>
              <button type='submit' className="btn btn-block py-3"
                style={{ backgroundColor: '#181c2e', color: '#fff', width: '100%', borderRadius: '20px' }}>{t('contactPage.sendMessage.form.submitButton')}</button>
            </div>
          </div>
        </div>
      </div>

    </form>
  );
};

export default SendMessage;