import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Divider,
    Heading,
    Stack,
    VStack,
    HStack,
    IconButton,
    Badge,
    useToast,
  } from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { baseURL } from '../../../utils/useRequest';
import colors from '../../../utils/colors';


const DeleteCar = ({name, model, id, deleteCarCallback}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast();
    const token = useSelector(state => state.token.token.access_token);


    const handleDelete = async () => {
        try {
            const response = await axios.delete(
                `${baseURL}/cars/${id}`
                , {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
            deleteCarCallback(id);
            toast({
                title: "voiture supprimé avec succès",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            onClose();
           

        } catch (error) {
            console.error("Erreur lors de la suppression du membre :", error);
            toast({
                title: "Erreur lors de la suppression du voiture",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            onClose();
        }
    };

   
    

    

  return (
    <>
       <div className='botton text-center'>
            <button onClick={onOpen} className="btn btn-block p-2" 
                style={{backgroundColor: "#d30505",color:'#fff',borderRadius:'20px',width:'8rem',fontSize:'13px',fontFamily:'Montserrat'}}>
                    DELETE
            </button> 
        </div>
        <Modal isCentered size={{ base: 'sm', md: 'xl',sm:'sm' }} isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" blockScrollOnMount={false}
        borderRadius="3xl" borderLeft="16px" borderColor="teal.400">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader className={'MontserratB'}  >Supprimer une voiture</ModalHeader>
            {/* <Text pt={4} pl={4} className={'MontserratB'} bgColor={''}>Return date :</Text> */}
            <ModalCloseButton />
            <Divider my={0}/>
   
            <ModalBody>
            <Text className='MontserratB' fontSize='md' mb={2}>Êtes-vous sûr de vouloir supprimer ceci de votre liste ?</Text>
                <Stack spacing={-4}  >

                        <Stack direction={'row'}>
                            <Text className={'MontserratM'} >Voiture :</Text>
                            <Text className={'Montserrat'} >{name} {model}</Text>
                        </Stack>

                        
                        
    
                </Stack>
               
            
            </ModalBody>

            <ModalFooter>
                <Button className={'jakR'} width={'10em'} variant={'solid'} size='sm' mr={3} onClick={onClose}>
                    Annuler
                </Button>
                <Button className={'jakR'} colorScheme="red" width={'10em'} size='sm' onClick={handleDelete}>
                    Supprimer
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>




    </>
  )
}

export default DeleteCar