import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import colors from '../../../utils/colors';
import { Button, Checkbox, useColorModeValue, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseURL } from '../../../utils/useRequest';
import CarImageUploader from './CarImageUploader';

export default function Page1({ cb, resetData: resetDataProp }) {
  const [loading, setLoading] = useState(false);
  const [mainImageFile, setMainImageFile] = useState(null);
  const [otherImageFiles, setOtherImageFiles] = useState([]);
  const [data, setData] = useState({
    name: "",
    model: "",
    year:"",
    totalCar:1,
    pricePerDay: 0,
    carburantType: "Petrol",
    categoryType: "Convertible",
    transmission: "Automatic",
    descriptionFr: "",
    descriptionEn: "",
    descriptionAr: "",  
    placesNumber: 0,
    hasHeater: true,
    isLuxuryCar: false,
    lettersPer90Kms: 0,
    doorsNumber: 0,
    functionalities: {
      "All Wheel Drive": false,
      "Audio Input": false,
      "Air Conditioning": false,
      "Heated Seats": false,
      "FM Radio": false,
      "Bluetooth": false,
      "GPS Navigation": false,
      "Sunroof": false,
      "Android Auto": false,
      "Camera": false,
      "Parking Sensors": false,
      "Ventilated Seats": false
    }
  });

  const toast = useToast();
  const navigate = useNavigate();
  const token = useSelector(state => state.token.token.access_token);

  const handleData = (attrValue) => {
    setData(prevData => ({ ...prevData, ...attrValue }));
  };

  const resetData = () => {
    setData({
      name: "",
      model: "",
      year: "",
      totalCar:1,
      pricePerDay: 0,
      carburantType: "Petrol",
      categoryType: "Convertible",
      transmission: "Automatic",
      descriptionFr: "",
      descriptionEn: "",
      descriptionAr: "",
      placesNumber: 0,
      hasHeater: true,
      isLuxuryCar: false,
      lettersPer90Kms: 0,
      doorsNumber: 0,
      functionalities: {
        "All Wheel Drive": false,
        "Audio Input": false,
        "Air Conditioning": false,
        "Heated Seats": false,
        "FM Radio": false,
        "Bluetooth": false,
        "GPS Navigation": false,
        "Sunroof": false,
        "Android Auto": false,
        "Camera": false,
        "Parking Sensors": false,
        "Ventilated Seats": false
      }
    });
    if (resetDataProp) {
      resetDataProp();
    }
  };

  
  const submit = () => {
    setLoading(true);
    const formData = new FormData();
    const { functionalities, ...carData } = data;
    const requestCarData = {
      ...carData,
      functionalities: Object.keys(functionalities).filter(key => functionalities[key]),
      outOfStock: false,
      carSortedNumber: 0,
    };
  
    formData.append('car', JSON.stringify(requestCarData));
    if (mainImageFile) formData.append('mainImage', mainImageFile);
    otherImageFiles.forEach(file => formData.append('otherImages', file));

    // console.log('forrm', formData);
  
    // Log the FormData content
    // formData.forEach((value, key) => {
    //   console.log(key, value);
    // });

  
    axios.post(`${baseURL}/cars/addCar`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(res => {
        setLoading(false);
        resetData();
        toast({
          description: "Car has been added successfully, add more and grow your business;",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate('/dash/cars-list');
      })
      .catch(e => {
        setLoading(false);
        toast({
          description: "An error occurred. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };



  const bgColor = useColorModeValue('#eee', '#4c4e4f');

  const colorText = useColorModeValue('#000', '#fff');


    // Mapping des fonctionnalités en français
    const functionalitiesTranslationFr = {
      "All Wheel Drive": "Traction intégrale",
      "Audio Input": "Entrée audio",
      "Air Conditioning": "Climatisation",
      "Heated Seats": "Sièges chauffants",
      "FM Radio": "Radio FM",
      "Bluetooth": "Bluetooth",
      "GPS Navigation": "Navigation GPS",
      "Sunroof": "Toit ouvrant",
      "Android Auto": "Android Auto",
      "Camera": "Caméra",
      "Parking Sensors": "Capteurs de stationnement",
      "Ventilated Seats": "Sièges ventilés"
    };

    const functionalitiesTranslationsAr = {
      "All Wheel Drive": "نظام الدفع الرباعي",
      "Audio Input": "مدخل صوتي",
      "Air Conditioning": "مكيف الهواء",
      "Heated Seats": "مقاعد مدفأة",
      "FM Radio": "راديو FM",
      "Bluetooth": "بلوتوث",
      "GPS Navigation": "نظام تحديد المواقع", 
      "Sunroof": "فتحة سقف",
      "Android Auto": "أندرويد أوتو",
      "Camera": "كاميرا",
      "Parking Sensors": "حساسات ركن السيارة",
      "Ventilated Seats": "مقاعد مهواة"
    };
  

  return (
    <div className="container p-0">
      <form style={{ color: '', height: '' }}  >
        <div style={{ borderRadius: '20px', backgroundColor: '' }}>
          <CarImageUploader setMainImageFile={setMainImageFile} setOtherImageFiles={setOtherImageFiles} />

          <div className="card-body" style={{ backgroundColor: '' }}>
            <div className="card-text py-2">
              <div className="row g-2 mb-4">

                <div className="form-floating col-md-3">
                  <select
                    onChange={(e) => handleData({ name: e.target.value })}
                    value={data.name}
                    className="form-select"
                    style={{ borderRadius: '20px', backgroundColor: bgColor, color: colorText }}
                  >
                    <option value="Alfa Romeo">Alfa Romeo</option>
                    <option value="Alsvin">Alsvin</option>
                    <option value="Aston Martin">Aston Martin</option>
                    <option value="Audi">Audi</option>
                    <option value="Bentley">Bentley</option>
                    <option value="BMW">BMW</option>
                    <option value="Bugatti">Bugatti</option>
                    <option value="Chevrolet">Chevrolet</option>
                    <option value="Chrysler">Chrysler</option>
                    <option value="Citroën">Citroën</option>
                    <option value="Dacia">Dacia</option>
                    <option value="Ferrari">Ferrari</option>
                    <option value="Fiat">Fiat</option>
                    <option value="Ford">Ford</option>
                    <option value="Honda">Honda</option>
                    <option value="Hyundai">Hyundai</option>
                    <option value="Jaguar">Jaguar</option>
                    <option value="Jeep">Jeep</option>
                    <option value="Kia">Kia</option>
                    <option value="Lamborghini">Lamborghini</option>
                    <option value="Land Rover">Land Rover</option>
                    <option value="Lexus">Lexus</option>
                    <option value="Maserati">Maserati</option>
                    <option value="Mazda">Mazda</option>
                    <option value="McLaren">McLaren</option>
                    <option value="Mercedes">Mercedes</option>
                    <option value="Mitsubishi">Mitsubishi</option>
                    <option value="Nissan">Nissan</option>
                    <option value="Opel">Opel</option>
                    <option value="Peugeot">Peugeot</option>
                    <option value="Porsche">Porsche</option>
                    <option value="Range Rover">Range Rover</option>
                    <option value="Renault">Renault</option>
                    <option value="Rolls-Royce">Rolls-Royce</option>
                    <option value="Saab">Saab</option>
                    <option value="Subaru">Subaru</option>
                    <option value="Suzuki">Suzuki</option>
                    <option value="Tesla">Tesla</option>
                    <option value="Toyota">Toyota</option>
                    <option value="Volkswagen">Volkswagen</option>
                    <option value="Volvo">Volvo</option>
                  </select>
                  <label className='Montserrat' style={{color: colorText }}>Marque de la voiture</label>
                </div>


                <div className="form-floating col-md-3">
                  <input
                    onChange={(e) => handleData({ model: e.target.value })}
                    value={data.model}
                    type='text'
                    className='form-control'
                    placeholder='Modèle de la voiture'
                    style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                  />
                  <label className='Montserrat' style={{color: colorText }}>Modèle de la voiture</label>
                </div>

                <div className="form-floating col-md-2">
                  <input
                    onChange={(e) => handleData({ year: e.target.value })}
                    value={data.year}
                    type='text'
                    className='form-control'
                    placeholder='Modèle de la voiture'
                    style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                  />
                  <label className='Montserrat' style={{color: colorText }}>Year</label>
                </div>

                <div className="form-floating col-md-2">
                  <input
                    onChange={(e) => handleData({ totalCar: e.target.value })}
                    value={data.totalCar}
                    type='text'
                    className='form-control'
                    placeholder='Nombre de voitures'
                    style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                  />
                  <label className='Montserrat' style={{color: colorText }}>Nombre de voitures</label>
                </div>

                <div className="form-floating col-md-2">
                  <input
                    onChange={(e) => handleData({ pricePerDay: parseInt(e.target.value) })}
                    value={data.pricePerDay}
                    type='number'
                    className='form-control'
                    placeholder='Prix par jour (DH)'
                    style={{ borderRadius: '20px', backgroundColor: 'rgba(246, 224, 94, 0.6)', fontWeight:'bold' }}
                  />
                  <label className='Montserrat' style={{color: colorText }}>Prix par jour (DH)</label>
                </div>

                

              </div>

              <div className="row g-2 mb-4">
                                                   
                  <div className="form-floating col-md-3">
                      <select
                          value={data.carburantType}
                          onChange={(e) => handleData({ carburantType: e.target.value })}
                          className="form-select"
                          style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                      >
                          <option value="Petrol">Petrol</option>
                          <option value="Diesel">Diesel</option>
                          <option value="Electric">Electric</option>
                          <option value="hybrid">hybrid</option>
                      </select>
                      <label className='Montserrat' style={{color: colorText }}>Type de carburant</label>
                      </div>

                      <div className="form-floating col-md-3">
                      <select
                          value={data.transmission}
                          onChange={(e) => handleData({ transmission: e.target.value })}
                          className="form-select"
                          style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                      >
                          <option value="Automatic">Automatic</option>
                          <option value="Semi Automatic">Semi Automatic</option>
                          <option value="Manual">Manual</option>
                      </select>
                      <label className='Montserrat' style={{color: colorText }}>Transmission</label>
                      </div>

                      
                      <div className="form-floating col-md-3">
                      <select
                          value={data.categoryType}
                          onChange={(e) => handleData({ categoryType: e.target.value })}
                          className="form-select"
                          style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                      >
                          <option value="Convertible">Convertible</option>
                          <option value="Coupe">Coupe</option>
                          <option value="Sedan">Sedan</option>
                          <option value="EV">EV</option>
                          <option value="Hatchback">Hatchback</option>
                          <option value="Luxury">Luxury</option>
                          <option value="SUV">SUV</option>
                          <option value="Wagon">Wagon</option>
                      </select>
                      <label className='Montserrat' style={{color: colorText }}>Car Category</label>
                      </div>

                      <div className="form-floating col-md-3">
                        <select
                          value={data.isLuxuryCar}
                          onChange={(e) => handleData({ isLuxuryCar: e.target.value === 'true'  })}
                          className="form-select"
                          style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                        >
                          <option value="true">Oui</option>
                          <option value="false">Non</option>
                        </select>
                        <label className='Montserrat' style={{color: colorText }}>Is this a luxury car?</label>
                      </div>
                </div>


                <div className="form-floating mb-3">
                <textarea
                  onChange={(e) => handleData({ descriptionFr: e.target.value })}
                  value={data.descriptionFr}
                  className="form-control border-0"
                  placeholder='Description de la voiture'
                  style={{ backgroundColor: bgColor, borderRadius: '20px', height: '140px', color: colorText }}
                />
                <label className='Montserrat' style={{color: colorText }}>Description : Francais</label>
              </div>

              <div className="form-floating mb-3">
                <textarea
                  onChange={(e) => handleData({ descriptionEn: e.target.value })}
                  value={data.descriptionEn}
                  className="form-control border-0"
                  placeholder='Description de la voiture'
                  style={{ backgroundColor: bgColor, borderRadius: '20px', height: '140px', color: colorText }}
                />
                <label className='Montserrat' style={{color: colorText }}>Description : Anglais</label>
              </div>

              <div className="form-floating mb-3">
                <textarea
                  onChange={(e) => handleData({ descriptionAr: e.target.value })}
                  value={data.descriptionAr}
                  className="form-control border-0"
                  placeholder='Description de la voiture'
                  style={{ backgroundColor: bgColor, borderRadius: '20px', height: '140px', color: colorText }}
                />
                <label className='Montserrat' style={{color: colorText }}>Description : Arabe</label>
              </div>

              

              

              <div className="row g-2 mb-4">
                <div className="form-floating col-md-3">
                  <input
                    onChange={(e) => handleData({ placesNumber: parseInt(e.target.value) })}
                    value={data.placesNumber}
                    type='number'
                    className='form-control'
                    placeholder='Nombre de places'
                    style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                  />
                  <label className='Montserrat' style={{color: colorText }}>Nombre de places</label>
                </div>

                <div className="form-floating col-md-3">
                  <select
                    value={data.hasHeater}
                    onChange={(e) => handleData({ hasHeater: e.target.value === 'true' })}
                    className="form-select"
                    style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                  <label className='Montserrat' style={{color: colorText }}>AC / Heater</label>
                </div>

                <div className="form-floating col-md-3">
                  <input
                    onChange={(e) => handleData({ lettersPer90Kms: parseFloat(e.target.value) })}
                    value={data.lettersPer90Kms}
                    type='number'
                    className='form-control'
                    placeholder='Combien de L / 90km'
                    style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                  />
                  <label className='Montserrat' style={{color: colorText }}>Combien de L / 90km</label>
                </div>

                <div className="form-floating col-md-3">
                  <input
                    onChange={(e) => handleData({ doorsNumber: parseInt(e.target.value) })}
                    value={data.doorsNumber}
                    type='number'
                    className='form-control'
                    placeholder='Nombre de portes'
                    style={{ borderRadius: '20px' , backgroundColor: bgColor, color: colorText }}
                  />
                  <label className='Montserrat' style={{color: colorText }}>Nombre de portes</label>
                </div>
              </div>

              <div className="d-flex col-md-12 justify-content-around text-center py-3 mb-5 mt-1" style={{ backgroundColor: bgColor, borderRadius: '20px' }}>
                <div className="MontserratM col-md-3 d-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>Fonctionnalités de la voiture</div>
                <div className="FonctionnalitésVoiture row d-flex justify-content-around col-md-9">
                  <div className="div1 col-md-4">
                    {Object.keys(data.functionalities).slice(0, 4).map(key => (
                      <div className="form-check form-switch" key={uuid()}>
                        <input
                          checked={data.functionalities[key]}
                          onChange={(e) => handleData({ functionalities: { ...data.functionalities, [key]: e.target.checked } })}
                          className="form-check-input"
                          type="checkbox"
                        />
                        <label className="form-check-label cairoR">{functionalitiesTranslationsAr[key]}</label>
                      </div>
                    ))}
                  </div>
                  <div className="div2 col-md-4">
                    {Object.keys(data.functionalities).slice(4, 8).map(key => (
                      <div className="form-check form-switch" key={uuid()}>
                        <input
                          checked={data.functionalities[key]}
                          onChange={(e) => handleData({ functionalities: { ...data.functionalities, [key]: e.target.checked } })}
                          className="form-check-input"
                          type="checkbox"
                        />
                        <label className="form-check-label cairoR">{functionalitiesTranslationsAr[key]}</label>
                      </div>
                    ))}
                  </div>
                  <div className="div3 col-md-4">
                    {Object.keys(data.functionalities).slice(8, 12).map(key => (
                      <div className="form-check form-switch" key={uuid()}>
                        <input
                          checked={data.functionalities[key]}
                          onChange={(e) => handleData({ functionalities: { ...data.functionalities, [key]: e.target.checked } })}
                          className="form-check-input"
                          type="checkbox"
                        />
                        <label className="form-check-label cairoR">{functionalitiesTranslationsAr[key]}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

               


            </div>
          </div>

          <Button className={''} size={'lg'} colorScheme="teal" width={'100%'} borderRadius={'20px'} onClick={submit}>
             AJOUTER
          </Button>
          
        </div>
      </form>
    </div>
  );
}
