import { Button, Flex, Input, InputGroup, InputRightElement, Switch, Text, useColorMode, useColorModeValue, useToast } from "@chakra-ui/react";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'; 
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { baseURL } from "../../../utils/useRequest";
import axios from "axios";
import colors from "../../../utils/colors";
// import api from "../../../../../utils/api";


const ChangePwd = ({ title, subtitle1 }) => {
  const toast = useToast();


  const token = useSelector(state => state.token.token.access_token);

  
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const { colorMode } = useColorMode();

  const borderProfileColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );


  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const [input1Value, setInput1Value] = useState('');
  const [input2Value, setInput2Value] = useState('');
  const [input3Value, setInput3Value] = useState('');

  const handleInputChange1 = (event) => {
    setInput1Value(event.target.value);
    setShow1(event.target.value !== '');
  }

  const handleInputChange2 = (event) => {
    setInput2Value(event.target.value);
    setShow2(event.target.value !== '');
  }

  const handleInputChange3 = (event) => {
    setInput3Value(event.target.value);
    setShow3(event.target.value !== '');
  }

  const handleClick1 = () => setShow1(!show1);
  const handleClick2 = () => setShow2(!show2);
  const handleClick3 = () => setShow3(!show3);

  
  const handleChangePassword = async () => {
    try {
      // Check if any input field is empty
      if (!input1Value || !input2Value || !input3Value) {
        toast({
          description: 'Veuillez remplir tous les champs.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      // Check if the new passwords match
      if (input2Value !== input3Value) {
        toast({
          description: 'Les mots de passe ne correspondent pas.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      // Check if the new password meets the minimum length requirement
      if (input2Value.length < 6) {
        toast({
          description: 'Votre mot de passe doit contenir au moins 6 caractères.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      // Send PATCH request to backend API
      const response = await axios.patch(`${baseURL}/users/changePwd`, {
        currentPassword: input1Value,
        newPassword: input2Value,
        confirmationPassword: input3Value
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Clear input fields
      setInput1Value('');
      setInput2Value('');
      setInput3Value('');
  
      // Show success toast
      toast({
        // title: 'Mot de passe modifié',
        description: 'Votre mot de passe a été modifié avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error changing password:', error); // Handle error
      // Check if the error is due to wrong password
      if (error.response && error.response.status === 500 && error.response.data.message === 'Wrong password') {
        // Show error toast for wrong password
        toast({
          description: 'Mot de passe incorrect. Veuillez réessayer.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Show generic error toast
        toast({
          description: 'Une erreur s\'est produite lors du changement de mot de passe. Veuillez réessayer plus tard.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };
  
  


  
  return (
    <Card p='16px' 
    bg={colorMode === "dark" ? '#242526' : ''} boxShadow={'xl'}
     borderRadius={"25px"} zIndex={2} border='2px solid'
    borderColor={borderProfileColor}>
      <CardHeader pt='12px' px='5px' >
        <Text className={'jakB'} fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column'>
          <Text className={'jakR'} fontSize='sm' color='gray.500' fontWeight='600' mb='20px'>
            {subtitle1}
          </Text>
          <Flex align='center' my='20px'>
            <InputGroup size='md' >
                    <Input   
                      h={12} 
                      borderRadius={'xl'}
                      pr='4.5rem'
                      type={show1 ? 'password' : 'text'}
                      placeholder='Mot de passe actuel'
                      value={input1Value}
                      onChange={handleInputChange1}
                    />
                    <InputRightElement width='4rem' top={'4px'}>
                        <Button  p='0px' bg='transparent' _hover={{ bg: "none" }} h='2rem' size='md' onClick={handleClick1}>
                        {input1Value && (show1 ? <ViewIcon /> : <ViewOffIcon />)}
                        </Button>
                    </InputRightElement>
                </InputGroup>
          </Flex>

          <Flex align='center' mb='20px'>
            <InputGroup size='md' justifyItems={"center"}>
                    <Input 
                      h={12} 
                      borderRadius={'xl'}
                      pr='4.5rem'
                      type={show2 ? 'password' : 'text'}
                      placeholder='Nouveau mot de passe'
                      value={input2Value}
                      onChange={handleInputChange2}
                    />
                    <InputRightElement width='4rem'  top={'4px'} >
                        <Button  p='0px' bg='transparent' _hover={{ bg: "none" }} h='2rem' size='md' onClick={handleClick2}>
                        {input2Value && (show2 ? <ViewIcon /> : <ViewOffIcon />)}
                        </Button>
                    </InputRightElement>
                </InputGroup>
          </Flex>

          <Flex align='center' mb='20px'>
            <InputGroup size='md'>
                    <Input  
                      h={12} 
                      borderRadius={'xl'}
                      pr='4.5rem'
                      type={show3 ? 'password' : 'text'}
                      placeholder='Retapez le nouveau mot de passe'
                      value={input3Value}
                      onChange={handleInputChange3}
                    />
                    <InputRightElement width='4rem' top={'4px'}>
                        <Button  p='0px' bg='transparent' _hover={{ bg: "none" }} h='2rem' size='md' onClick={handleClick3}>
                        {input3Value && (show3 ? <ViewIcon /> : <ViewOffIcon />)}
                        </Button>
                    </InputRightElement>
                </InputGroup>
          </Flex>


          <Flex align='center' mb='20px'  mt='15px'>

          <Button borderRadius={'2xl'} colorScheme={colorMode === 'dark' ? 'orange' : 'yellow'} className="jakR" width={'full'} onClick={handleChangePassword}>
             Changer de mot de passe
          </Button>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ChangePwd;
