import React, { useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GrLanguage } from 'react-icons/gr';
import { AllemendFlag, FranceFlag, ItalyFlag, MarocFlag, SpainFlag, UsaFlag } from '../../Icons/Icons';

const LanguageSwitcher = ({ onChangeDir, dir }) => {
  const { i18n } = useTranslation();
  
  // State to hold the currently selected icon
  const [selectedIcon, setSelectedIcon] = useState(<GrLanguage />);

  const handleLanguageChange = (lang, IconComponent) => {
    i18n.changeLanguage(lang);

    const newDir = lang === 'ar' ? 'rtl' : 'ltr';
    onChangeDir(newDir);
    
    setSelectedIcon(<IconComponent fontSize={24} />);
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={selectedIcon} // Display the selected icon
        variant="outline"
        colorScheme='purple'
      />
      <MenuList>
        <MenuItem
          icon={<UsaFlag fontSize={24} />}
          onClick={() => handleLanguageChange('en', UsaFlag)}
        >
          English
        </MenuItem>
        <MenuItem
          icon={<FranceFlag fontSize={24} />}
          onClick={() => handleLanguageChange('fr', FranceFlag)}
        >
          Français
        </MenuItem>
        <MenuItem
          icon={<MarocFlag fontSize={24} />}
          onClick={() => handleLanguageChange('ar', MarocFlag)}
        >
          العربية
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitcher;

