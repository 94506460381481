import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Divider,
    Stack,
    useToast,
    FormControl,
    useColorMode,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    useColorModeValue,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { CalendarIcon } from '@chakra-ui/icons';
import fr from 'date-fns/locale/fr';
import { getDay } from 'date-fns';
import { useTranslation } from 'react-i18next';


const StartDateModal = ({ onStartDateSelect, dir }) => { 

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const { colorMode } = useColorMode();
    const [startDate, setStartDate] = useState(null);
    const todayDate = new Date();
    const { t } = useTranslation();
    
    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
      };

    const handleConfirmStartDate = () => {
        onStartDateSelect(startDate);
        onClose();
    };

    const bgColorInput = useColorModeValue('#eee', '#eee');


      

    return (
        <>
            <InputGroup onClick={onOpen} >
                <InputRightElement pointerEvents='none'>
                    <CalendarIcon color='gray.300' />
                </InputRightElement>
                <Input
                    size={'lg'}
                    fontSize={"md"}
                    py={3}
                    type='text'
                    // bgColor={colorMode === 'dark' ? 'whiteAlpha.300' : 'white'}
                    bgColor={bgColorInput}
                    focusBorderColor="whiteAlpha"
                    placeholder={t('form.startDate')}
                    value={startDate ? startDate.toLocaleDateString('fr-FR') : ''}
                    readOnly
                    className={' form-control border-0 py-3'}
                />
            </InputGroup>
            <Modal
                size={{ base: 'sm', md: 'lg', sm:'sm' }}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                blockScrollOnMount={false}
                borderRadius="3xl"
                borderLeft="16px"
                borderColor="teal.400"
            >
                <ModalOverlay />
                <ModalContent>
                <Text pt={4} pl={dir === 'ltr' ? 4 : 0} pr={dir === 'rtl' ? 4 : 0}  className={` ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} bgColor={''}>{t('form.startDate')}</Text>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={-4} mt={0}>
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                className={'Montserrat'}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                                calendarClassName={colorMode === 'dark' ? 'react-datepicker__calendar--dark' : 'react-datepicker__calendar--light'}
                                popperClassName={colorMode === 'dark' ? 'react-datepicker-popper--dark' : ''}
                                minDate={todayDate}
                                locale={fr}
                                shouldCloseOnSelect={false}
                                inline
                              
                            />
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}  variant={'ghost'} mr={3} onClick={onClose}>
                            {t('car_details.annuler_btn')}
                        </Button>
                        <Button  className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} colorScheme="teal" onClick={handleConfirmStartDate}>
                            {t('car_details.confirm_btn')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default StartDateModal;
