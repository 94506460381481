// Sidebar.js

import React, { useEffect, useState } from 'react';
import { Flex, Stack, Divider, Image, HStack, Box, Text, Progress, Button, Icon, useDisclosure, SlideFade, useColorMode, Badge } from '@chakra-ui/react';
import { FiHome,FiLogOut } from 'react-icons/fi';
import { NavButton } from './NavButton';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdAddCircle, MdNotificationAdd, MdOutlineHowToVote } from "react-icons/md";

import { useSelector, useDispatch } from 'react-redux'; 
import { jwtDecode } from 'jwt-decode';
import { baseURL, baseUrlNet } from '../../../utils/useRequest';
import axios from 'axios';
// eslint-disable-next-line
import { fetchEventSource } from '@microsoft/fetch-event-source';

import { IoCarSport, IoSettingsSharp } from "react-icons/io5";
import { FaFacebookMessenger, FaStar, FaUsers } from 'react-icons/fa';
import colors from '../../../utils/colors';
import { persistor } from '../../../redux/store';
import { BsPeople } from 'react-icons/bs';
import { selectUserInfo } from '../../../redux/slices/userSlice';
import { GoHomeFill } from 'react-icons/go';










export const Sidebar = ({ cb }) => {
  const [selectedRoute, setSelectedRoute] = useState('');
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);

  const { isOpen, onToggle } = useDisclosure()
  const { colorMode } = useColorMode();

  const user = useSelector(selectUserInfo);

  // Get the dispatch function
  const dispatch = useDispatch();

  const useRole = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.role.toString();
  };

  const role = useRole();


  const token = useSelector(state => state.token.token.access_token);

 

const handleLogout = async () => {
    
  try {
    
    const response = await axios.post(`${baseURL}/auth/logout`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    // console.log('Response:', response.data); // Log the response data

    await persistor.purge(); // Clear the persisted state
    await persistor.flush(); // Ensure the changes are flushed

    navigate('/login');
  } catch (error) {
    console.error('Logout error:', error);
  }
};
    

useEffect(() => {
  // Check if the role is ADMIN
  if (role === "ADMIN") {
    const controller = new AbortController();
    const signal = controller.signal;

    // Fetch Event Source with headers
    fetchEventSource(`${baseURL}/notifications/unread-notification-count/demand`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal,
      onmessage: (event) => {
        // Handle incoming server-sent events
        handleServerSentEvent(event);
      },
      onopen: (response) => {
        // Handle connection open
        // console.log('Connection opened');
      },
      onclose: () => {
        // Handle connection close
        // console.log('Connection closed');
      },
      onerror: (error) => {
        // Handle connection error
        console.error('Connection error:', error);
      }
    });

    // Cleanup function to abort the fetch and close the connection
    return () => {
      controller.abort();
    };
  }
}, [role, token]); // Include role and token in the dependency array


// Define a function to handle incoming server-sent events
const handleServerSentEvent = (event) => {
  // Parse the data from the event
  const data = JSON.parse(event.data);
  // Update the state with the new unread notification count
  setNotificationCount(data);
};




const handleMarkAllNotificationsAsRead = async (type) => {
  try {
      const response = await axios.put(`${baseURL}/notifications/${type}/mark-all-as-read`, null, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      
      setNotificationCount(0);
  } catch (error) {
      console.error('Error marking all notifications as read:', error);
  }
};

const handleButtonClick = (route) => {
  setSelectedRoute(route);
  cb(route)();
};




    const handleCurrentPage2Arg=(route, type)=>{
        return ()=>{
            setSelectedRoute(route);
            cb(route)();
            if (role === "CPI") {
                handleMarkAllNotificationsAsRead(type);
              }
           
        }
    };







  // 262948
  return (
    <Flex position={'relative'} bg={colorMode === "dark" ? '#242526'  :  colors.sidebarColor} color="white" height="100vh" maxW={{ base: 'full', sm: 'xs' }} py={{ base: '6', sm: '4' }} px={{ base: '6', sm: '5' }}>
     

      <Stack justify="space-between" spacing="1">
        <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>

        <Link to={"/"}>
        
          <HStack bgColor={""} justifyContent={"center"} my={4}>
          <Image src={colorMode === "dark" ? `${baseUrlNet}logo/sirycarWhite.svg` : `${baseUrlNet}logo/sirycar.svg`} alt="Logo" height="12" width="auto" />
          </HStack>
          </Link>

          <Stack spacing="3">
              <Stack position="relative">
                <NavButton  
                  isSelected={selectedRoute === ''}
                  onClick={() => handleButtonClick('')}
                  label="Tableau de bord"
                  icon={GoHomeFill}
                />

                  {role === "ADMIN" && (
                  <NavButton  
                  isSelected={selectedRoute === 'liste-chef'}
                  onClick={() => handleButtonClick('liste-chef')}
                  label={"Chefs de projet"}
                  icon={BsPeople}
                />)}

                {role === "CPI" &&
                  <NavButton  
                    isSelected={selectedRoute === 'liste-membre'}
                    onClick={() => handleButtonClick('liste-membre')}
                    label={"Equipe"}
                    icon={FaUsers}
                  />
                }

                <NavButton  
                  isSelected={selectedRoute === 'new-car'}
                  onClick={() => handleButtonClick('new-car')}

                  label="Add Car"
                  icon={MdAddCircle}
                  />

                <NavButton  
                      isSelected={selectedRoute === 'cars-list'}
                      onClick={() => handleButtonClick('cars-list')}
                      label="List Cars"
                      icon={IoCarSport}
                    />


                <NavButton  
                  isSelected={selectedRoute === 'booking'}
                  onClick={handleCurrentPage2Arg("booking","demand")}
                  label="Demands"
                  icon={MdNotificationAdd}
                />


                  {role === "CPI" && notificationCount > 0 && (
                    <Box position="absolute"  top="87%"  right="11px"  transform="translateY(-50%)"  bgColor={'red'} rounded={'full'} height={6} width={6} className='MontserratB' fontSize={'sm'} display="flex" justifyContent="center" alignItems="center">
                      {notificationCount}
                    </Box>
                  )}

              </Stack>

            

           

            <NavButton 
              isSelected={selectedRoute === 'messagerie'} 
              onClick={() => handleButtonClick('messagerie')}
              label="Messagerie"
              icon={FaFacebookMessenger}
            />
            


            <NavButton  
              isSelected={selectedRoute === 'reviews'} 
              onClick={() => handleButtonClick('reviews')}
              label="FeedBack"
              icon={FaStar}
            />


          </Stack>
          

        </Stack>

        


        <Stack spacing={{ base: '5', sm: '6' }}>
          
          <Stack spacing="1">
          <NavButton
              isSelected={selectedRoute === 'settings'}
              onClick={() => handleButtonClick('settings')}
              label="Paramètres"
              icon={IoSettingsSharp}
            />
            
            
          <Button
              className='Montserrat'
              justifyContent="start"
              variant={'solid'}
              onClick={handleLogout}
              color={ colorMode === "dark" ? '#fff'  : '#230077'}
              bgColor={colorMode === "dark" ? '#303031' : colors.yellowSecondary}
              _hover={{ bg: colors.yellowPrimary, color:'#000' }}
              _focus={{ outline: 'none' }}
              >

                <Flex align="center" bgColor={''} >
                  <Icon as={FiLogOut} boxSize="6" />
                  <Text ml="3" >se déconnecter</Text>
                </Flex>
            
            </Button>

            
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};

