// memberSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    memberInfo: null,
  },
  reducers: {
    setMemberInfo: (state, action) => {
      state.memberInfo = action.payload;
    },
  },
});

export const { setMemberInfo } = memberSlice.actions;

export const selectMemberInfo = (state) => state.member.memberInfo;

export default memberSlice.reducer;
