import { Button, HStack, Icon, Text, useColorMode, useToast } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import colors from '../../../utils/colors';

export const NavButton = ({ icon, label, onClick, isSelected, variantColor, ...buttonProps }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { colorMode } = useColorMode();


  // const backgroundColor = isSelected ? `orange.400` : label === 'Se Déconnecter' ? 'red.400' : '#854e56';
  const backgroundColor = isSelected ? (colorMode === "dark" ? '#929293' : colors.buttonBgColor) 
                                     : (label === 'Se Déconnecter' ? ('red.400') 
                                     : (colorMode === "dark" ? '#303031' : "")
                                     );




 
  const handleClick = () => {
    if (label === 'Se Déconnecter') {
      // Afficher le toast de déconnexion avec succès
      toast({
        // title: 'Déconnexion réussie',
        description: 'Vous vous êtes déconnecté avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/');
    } else {
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <Button
      className='Montserrat'
      variant="ghost"
      w={"200px"}
      justifyContent="start"
      // color={isSelected ? colors.selectedBtnText : '# '}
      color={isSelected ? colorMode === "dark" ? colors.selectedBtnText  : colors.selectedBtnText : colorMode === "dark" ? '#fff'  :  colors.buttonBgColor}
      bg={backgroundColor}
      _hover={{ bg: isSelected ? colorMode === "dark" ? '#929293'  : colors.buttonBgColor : colorMode === "dark" ? '#929293'  :  colors.buttonBgColor ,
                color: colors.selectedBtnText}}
      _focus={{ outline: 'none' }}
      onClick={handleClick}
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon as={icon} boxSize="6" />
        <Text ml="1">{label}</Text>
      </HStack>
    </Button>
  );
};






// import { Button, HStack, Icon, Text, useColorMode, useToast } from '@chakra-ui/react';
// import { FiLogOut } from 'react-icons/fi';
// import { useNavigate } from 'react-router-dom';
// import * as React from 'react';

// export const NavButton = ({ icon, label, onClick, isSelected, variantColor, ...buttonProps }) => {
//   const navigate = useNavigate();
//   const toast = useToast();
//   const { colorMode } = useColorMode();


//   // const backgroundColor = isSelected ? `orange.400` : label === 'Se Déconnecter' ? 'red.400' : '#854e56';
//   const backgroundColor = isSelected ? (colorMode === "dark" ? '#929293' : `#3d06ac`) 
//                                      : (label === 'Se Déconnecter' ? ('red.400') 
//                                      : (colorMode === "dark" ? '#303031' : '#e9e5ff')
//                                      );




 
//   const handleClick = () => {
//     if (label === 'Se Déconnecter') {
//       // Afficher le toast de déconnexion avec succès
//       toast({
//         // title: 'Déconnexion réussie',
//         description: 'Vous vous êtes déconnecté avec succès.',
//         status: 'success',
//         duration: 3000,
//         isClosable: true,
//       });

//       navigate('/');
//     } else {
//       if (onClick) {
//         onClick();
//       }
//     }
//   };

//   return (
//     <Button
//       className='Montserrat'
//       variant="ghost"
//       w={"200px"}
//       justifyContent="start"
//       color={isSelected ? '#d5ceff' : '#9576ff'}
//       bg={backgroundColor}
//       _hover={{ bg: isSelected ? colorMode === "dark" ? '#929293'  : `#3d06ac` : colorMode === "dark" ? '#929293'  : '#3d06ac' }}
//       _focus={{ outline: 'none' }}
//       onClick={handleClick}
//       {...buttonProps}
//     >
//       <HStack spacing="3">
//         <Icon as={icon} boxSize="6" />
//         <Text ml="1" mt={4}>{label}</Text>
//       </HStack>
//     </Button>
//   );
// };


