import React from 'react';
import unauthorized from "../../assets/images/unauthorized.svg";
import unauthorizedCss from "./protected.module.css";
import { Link, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';

export default function Protected({ component }) {
    const token = useSelector(state => state.token?.token?.access_token);
    if (!token) return <Navigate to="/login" />;
    
    try {
        const decodedToken = jwtDecode(token);
        const { exp } = decodedToken;
        const now = Date.now() / 1000;
        
        if (exp > now) {
            return component;
        } else {
            localStorage.removeItem("jwtToken");
            return <Navigate to="/login" />;
        }
    } catch (error) {
        localStorage.removeItem("jwtToken");
        return <Navigate to="/login" />;
    }
}




// import React from 'react'
// import unauthorized from "../../assets/images/unauthorized.svg";
// import unauthorizedCss from "./protected.module.css";
// import { Link } from 'react-router-dom';

// export default function Protected({component}) {
//     // const storedTimestamp = localStorage.getItem('loginTime');
//     // const now = Date.now();
//     // const durationInDays = (now - storedTimestamp) / (1000 * 60 * 60 * 24);
//     // if(localStorage.getItem("jwtToken") && durationInDays<=1) return component;
//     if(true) return component;
//     // localStorage.removeItem("jwtToken");
//     return (
//         <div className={unauthorizedCss.unauthorized}>
//             <div className="links">
//                 <h3>Whoops you should login first</h3>
//                 <Link className="navbar-brand" to="/login">
//                             <p>Login?</p>
//                 </Link>
//             </div>
            
//             <img src={unauthorized} alt="Cannot Login" />
//         </div>
//     )
// }
