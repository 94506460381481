import { Box, Button, CircularProgress, CircularProgressLabel, Flex, Icon, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "../../../../components/Card/Card";
import axios from 'axios'; 
import { baseURL } from "../../../../utils/useRequest";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { GoSidebarExpand, GoSidebarCollapse } from "react-icons/go";
import { useLocation } from "react-router-dom";
import colors from '../../../../utils/colors'

const StatsCars = ({ userInfo, onVisibilityChange }) => {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const [totalCars, setTotalCars] = useState(0);
  const [rentedCars, setRentedCars] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [isSectionVisible, setIsSectionVisible] = useState(true); // state to manage visibility
  const isCongePath = location.pathname === '/dash/conge';

  const token = useSelector(state => state.token.token.access_token);

  const useUserId = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.id;
  };

  const userId = useUserId();

  useEffect(() => {
    const fetchCarStats = async () => {
      try {
        const response = await axios.get(`${baseURL}/cars/statistics`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setTotalCars(response.data.totalCars);
        setRentedCars(response.data.rentedCars);

        const availableCars = response.data.totalCars - response.data.rentedCars;
        const percentage = (availableCars / response.data.totalCars) * 100;
        setPercentage(percentage);
      } catch (error) {
        console.error('Error fetching car statistics:', error);
      }
    };

    fetchCarStats();
  }, [userId, token]);

  useEffect(() => {
    onVisibilityChange(isSectionVisible);
  }, [isSectionVisible, onVisibilityChange]);

  const isDarkMode = colorMode === 'dark';

  const bgCard = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const borderColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );

  const bgStyle = isDarkMode
    ? {
        bg: bgCard,
        boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.02)',
        border: '2px solid',
        backdropFilter: 'saturate(200%) blur(50px)',
        borderColor: borderColor,
      }
    : { bg: 'linear-gradient(126.97deg, #343a40  28.26%, rgba(4, 12, 48, 0.5) 91.2%)' };

  const toggleSectionVisibility = () => {
    setIsSectionVisible(!isSectionVisible);
  };


  const availableCars = totalCars - rentedCars;

  const bgColorCard = useColorModeValue(colors.sidebarColor,  '#242526')
  const bgColorButton = useColorModeValue(colors.yellowPrimary,  '#929293')

  return (
    <> 
      {/* Stats */}
      <Card  
          bgColor={bgColorCard}
          p={'22px'}
          borderRadius={isCongePath ? "0px 22px 22px 0px" : "20px"}
          display={'relative'}
      >
        <Flex direction='column'>
          <Flex justify='space-between' align='center' mb='40px'>
            <Text fontSize='lg' fontWeight='bold' className={'jakB'} mb={0}>
              Informations
            </Text>
           
            <Button borderRadius='12px' w='38px' h='38px' bg={bgColorButton} _hover='none' _active='none' onClick={toggleSectionVisibility}>
              <Icon as={isSectionVisible ?  GoSidebarExpand : GoSidebarCollapse} color='white' height={'1.3em'} width={'1.3em'} />
            </Button>

          </Flex>
          <Flex direction={{ sm: 'column', md: 'row' }} justifyContent={'space-between'}>
            {isSectionVisible && (
              <Flex direction='column' w={{sm:'', md:'250px'}} me={{ md: '6px', lg: '52px' }} mb={{ sm: '16px', md: '0px' }} bgColor={''}>
                <Flex
                  direction='column'
                  p='22px'
                  pe={{ sm: '22e', md: '8px', lg: '22px' }}
                  minW={{ sm: '220px', md: '140px', lg: '220px' }}
                  borderRadius='20px'
                  mb='20px'
                  {...bgStyle}
                >
                  <Text color='gray.400' fontSize='sm' mb='4px' className={'jakR'}>
                     Nombre total de voitures
                  </Text>
                  <Text color='white' fontSize='lg' fontWeight='bold' className={'jakB'} mb={0}>
                    {totalCars}
                  </Text>
                </Flex>
                <Flex
                  direction='column'
                  p='22px'
                  pe={{ sm: '22px', md: '8px', lg: '22px' }}
                  minW={{ sm: '170px', md: '140px', lg: '170px' }}
                  borderRadius='20px'
                  {...bgStyle}
                >
                  <Text color='gray.400' fontSize='sm' mb='4px' className={'jakR'}>
                     Nombre de voitures louées
                  </Text>
                  <Text color='white' fontSize='lg' fontWeight='bold' className={'jakB'} mb={0}>
                    {rentedCars}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Box mx={{ sm: 'auto', md: '0px' }}>
              <CircularProgress
                size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
                value={percentage}
                thickness={6}
                color={colorMode === "dark" ? '#929293'  : colors.yellowPrimary}
                variant='vision'
              >
                <CircularProgressLabel>
                  <Flex direction='column' justify='center' align='center'>
                    <Text color='gray.400' fontSize='sm' className={'jakR'} mb={0}>
                      voitures
                    </Text>
                    {/* <Text
                      color='white'
                      fontSize={{ md: '36px', sm: '28px' }}
                      fontWeight='bold'
                      lineHeight={1.2}
                      className={'jakB'}
                    > */}
                      <Text
                       className={'jakB'}
                       fontSize={{ md: '36px', lg: '50px' }}
                       fontWeight='bold'
                       mb='4px'
                      //  color={colors.greenPrimary}
                     >
                      {availableCars}
                    </Text>
                    
                    <Text color='gray.400' fontSize='sm' className={'jakR'}>
                      disponible
                    </Text>
                  </Flex>
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default StatsCars;