
import React, { useEffect, useState } from 'react';
import { Box, Button, HStack, Heading, Image, Stack, Flex, useBreakpointValue, Text, useColorMode, useColorModeValue, Stat, SimpleGrid, StatLabel, StatNumber, StatHelpText, Spacer, ChakraProvider, Icon, Badge, Grid, CircularProgress, CircularProgressLabel, Progress } from '@chakra-ui/react';

import Card from "../../../components/Card/Card.js";
import CardBody from '../../../components/Card/CardBody.js';
import theme from "../../../styles/theme/themeAdmin.js";
import HoursWorked from '../../../components/chart/HoursWorked.js';
import LineChart from '../../../components/chart/LineChart.js';

import DateCard from '../../../components/Elements/dateLayout/DateCard.jsx';
import Clock from '../../../components/Elements/dateLayout/Clock.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../../redux/slices/userSlice.js';
import colors from '../../../utils/colors.js';
import StatsCars from './statsConge/StatsCars.jsx';



const DashDefault = () => {
  const { colorMode } = useColorMode();
  const userInfo = useSelector(selectUserInfo);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const token = useSelector(state => state.token.token.access_token);


  const borderColor = useColorModeValue("white", "rgba(255, 255, 255, 0.31)");
  const bgCard = useColorModeValue("hsla(0,0%,100%,.8)", "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)");

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [startTime, setStartTime] = useState(null);

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
  const hours24 = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const timeClick = `${hours24}:${minutes}`;

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isSectionVisible]);

  const [currentTime, setCurrentTime] = useState(new Date());
  const [formattedTime, setFormattedTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTime = new Date();
      setCurrentTime(newTime);

      const hours = String(newTime.getHours()).padStart(2, '0');
      const minutes = String(newTime.getMinutes()).padStart(2, '0');
      const seconds = String(newTime.getSeconds()).padStart(2, '0');

      setFormattedTime(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const pointezDate = `${formattedDate} à ${timeClick}`;

  const [weekStartDate, setWeekStartDate] = useState(null);
  const [weekEndDate, setWeekEndDate] = useState(null);

  const [monthStartDate, setMonthStartDate] = useState(null);
  const [monthEndDate, setMonthEndDate] = useState(null);
  
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useBreakpointValue({ base: false, lg: true });


  // useEffect(() => {
  //   const getWeekDates = () => {
  //     const today = new Date();
  //     const currentDay = today.getDay();
  //     const daysUntilMonday = currentDay === 0 ? 6 : currentDay - 1;
  //     const startDate = new Date(today);
  //     startDate.setDate(today.getDate() - daysUntilMonday);
  //     const formattedStartDate = startDate.toISOString().split('T')[0];

  //     const endDate = new Date(startDate);
  //     endDate.setDate(startDate.getDate() + 6);
  //     const formattedEndDate = endDate.toISOString().split('T')[0];

  //     return { startDate: formattedStartDate, endDate: formattedEndDate };
  //   };

  //   const { startDate, endDate } = getWeekDates();
  //   setWeekStartDate(startDate);
  //   setWeekEndDate(endDate);
  //   setIsLoading(false);

  // }, []);


  useEffect(() => {
    const getWeekDates = () => {
      const today = new Date();
      const currentDay = today.getDay();
      const daysUntilMonday = currentDay === 0 ? 6 : currentDay - 1;
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - daysUntilMonday);
      const formattedStartDate = startDate.toISOString().split('T')[0];
  
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      const formattedEndDate = endDate.toISOString().split('T')[0];
  
      return { startDate: formattedStartDate, endDate: formattedEndDate };
    };
  
    const getMonthDates = () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      
      const formattedMonthStartDate = firstDayOfMonth.toISOString().split('T')[0];
      const formattedMonthEndDate = lastDayOfMonth.toISOString().split('T')[0];
  
      return { startDate: formattedMonthStartDate, endDate: formattedMonthEndDate };
    };
  
    const { startDate: weekStartDate, endDate: weekEndDate } = getWeekDates();
    const { startDate: monthStartDate, endDate: monthEndDate } = getMonthDates();
  
    setWeekStartDate(weekStartDate);
    setWeekEndDate(weekEndDate);
    setMonthStartDate(monthStartDate);
    setMonthEndDate(monthEndDate);
    setIsLoading(false);
  }, []);
  

  const isDarkMode = colorMode === 'dark';
  const bgStyle = isDarkMode ? {
    bg: bgCard,
    boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.02)',
    border: '2px solid',
    backdropFilter: 'saturate(200%) blur(50px)',
    borderColor: borderColor,
  } : { bg: 'linear-gradient(126.97deg, #513138 28.26%, rgba(4, 12, 48, 0.5) 91.2%)' };

  const handleVisibilityChange = (isVisible) => {
    setIsSectionVisible(isVisible);
  };



  const dispatch = useDispatch();





 
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Stack spacing={{ base: '8', lg: '6' }}>
       
      {(userInfo.role === 'ADMIN' || userInfo.role === 'CPI' || userInfo.role === 'EMP') && (
          <> 
              <Card
                position={'relative'}
                p='10px'
                gridArea={{ md: '1 / 1 / 2 / 3', '2xl': 'auto' }}
                // bgImage={colorMode === "dark" ? "none" : man}
                bg={colorMode === "dark" ? '#242526' : colors.sidebarColor}
                backdropFilter='blur(2px)'
                bgSize='cover'
                mb={4}
                bgPosition='-50%'
              >
                <CardBody w='100%' h='100%'>
                  <Flex flexDirection={{ sm: 'column', lg: 'row' }} w='100%' h='100%'>
                    <Flex flexDirection='column' h='100%' p='22px' lineHeight='1.6'>
                      <Text className={'jakB'} fontSize='sm' fontWeight='bold' bgColor={''} mb={0}>
                        Bonjour,
                      </Text>
                      <Text className={'jakB'} fontSize='28px'  fontWeight='bold' mb='25px' bgColor={''}>
                        {userInfo.firstname} {" "} {userInfo.lastname}
                      </Text>
                      
                      <Spacer />

                      <Text className={'jakR'} fontSize='md' fontWeight='normal' mb='60px' >
                         Chez SiryCar, nous nous efforçons de vous offrir une expérience de location de voiture simple et efficace.<br/>De la réservation à la prise en charge, nous sommes là pour répondre à vos besoins.
                      </Text>
                    </Flex>
                  </Flex>
                </CardBody>

              {isDesktop &&
                <HStack spacing={4} position={'absolute'} top={7} right={6} alignItems={'start'}>
                  <DateCard />
                  <Clock />
                </HStack>
                }

              </Card>
          
            <Grid
              templateColumns={{ sm: "1fr", lg: isSectionVisible ? "1.3fr 1.7fr" : "0.3fr 2.7fr" }}
              templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
              gap='24px'
              mb={{ lg: "26px" }}
            >
              <StatsCars userInfo={userInfo} onVisibilityChange={handleVisibilityChange} />
              <div>
                {isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <HoursWorked
                    title={"Statistiques des commandes"}
                    percentage={5}
                    chart={<LineChart startDate={monthStartDate} endDate={monthEndDate} userId={userInfo.id} token={token} colorMode={colorMode} />}
                  />
                )}
              </div>
            </Grid> 
          </>
        )}
      </Stack>
    </ChakraProvider>
  );
};

export default DashDefault;

