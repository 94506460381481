
import React, { useEffect, useState } from 'react'
import Ftr from "../../components/footer/Ftr";
import axios from 'axios';
import { baseURL } from '../../utils/useRequest';
import Loader from '../../components/Elements/loaders/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from "uuid";

import "./carDetails.css";
import { useToast } from '@chakra-ui/toast';
import StartDateModal from './dateModal/StartDateModal';
import EndDateModal from './dateModal/EndDateModal';
import { motion } from "framer-motion";
import colors from '../../utils/colors';
import { Badge, Box, Button, Divider, HStack, Icon, SimpleGrid, Stack, Text, useBreakpointValue, useColorMode, useColorModeValue, VStack } from '@chakra-ui/react';
import ImgCarCarousel from './imgCarCarousel/ImgCarCarousel';
import { MdOutlineAddToPhotos } from "react-icons/md";
import { BsCheck2Circle, BsFuelPumpDiesel } from "react-icons/bs";
import DriverCard from './DriverCard';
import { ArrowBackIcon } from '@chakra-ui/icons';
import ConfirmBookingModal from './ConfirmBookingModal';
import { useTranslation } from 'react-i18next';
import { Select } from 'chakra-react-select';
import StartTime from './dateModal/StartTime';
import ReturnTime from './dateModal/ReturnTime';
import { TbManualGearbox, TbUsers } from 'react-icons/tb';
import { GiCarDoor } from 'react-icons/gi';
import { FaCheckCircle, FaCircle, FaTimesCircle } from 'react-icons/fa';




const CarDetails = ({dir}) => {
    const { colorMode } = useColorMode();
    
    const functionalities = [
        "All Wheel Drive",
        "Audio Input",
        "Air Conditioning",
        "Heated Seats",
        "FM Radio",
        "Bluetooth",
        "GPS Navigation",
        "Sunroof",
        "Android Auto",
        "Camera",
        "Parking Sensors",
        "Ventilated Seats"
    ];
    const { id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const { t, i18n } = useTranslation();


    const getDescriptionByLanguage = () => {
        switch (i18n.language) {
          case 'fr':
            return post.descriptionFr;
          case 'en':
            return post.descriptionEn;
          case 'ar':
            return post.descriptionAr;
          case 'es':
            return post.descriptionEs;
          case 'de':
            return post.descriptionDe;
          case 'it':
            return post.descriptionIt;
          default:
            return post.descriptionEn; // Fallback to English if language is not matched
        }
      };

    // const [isLoading, setLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [post, setPost] = useState(null);
    const [fetchingError, setFetchingError] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [showAddOns, setShowAddOns] = useState(false);
 

    const [fetchingMore, setFetchingMore] = useState(false);
    const [moreCars, setMoreCars] = useState([]);
    const initialBookingData = {
        name: "",
        phoneNumber: "",
        startingDate: "",
        startingTime:"",
        endingDate: "",
        endingTime: "",
        carId: "",
    }
    
    const handleStartDateSelect = (date) => {
        setDemand(p => ({ ...p, startingDate: date }));
    };

    const handleStartTimeSelect = (time) => {
        setDemand(p => ({ ...p, startingTime: time }));
    };
    
    const handleEndDateSelect = (date) => {
        setDemand(p => ({ ...p, endingDate: date }));
    };

    const handleEndTimeSelect = (time) => {
        setDemand(p => ({ ...p, endingTime: time }));
    };

    const [demand, setDemand] = useState(initialBookingData);

    const calculateTotalPrice = () => {
        if (demand.startingDate && demand.endingDate && post?.pricePerDay) {
            const startDate = new Date(demand.startingDate);
            const endDate = new Date(demand.endingDate);
            const daysDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)); // Calculate difference in days
            const pricePerDay = Math.floor(post.pricePerDay);
            const calculatedPrice = (daysDifference) * pricePerDay;
            return calculatedPrice;
        }
        return 0;
    };

    // Update total price whenever start date, end date, or price per day changes
    useEffect(() => {
        const calculatedTotalPrice = calculateTotalPrice();
        setTotalPrice(calculatedTotalPrice);
    }, [demand.startingDate, demand.endingDate, post?.pricePerDay]);

    const handleDemand = (attr) => {
        setDemand(p => ({ ...p, ...attr }));
    }



    const handleSubmit = () => {

        
        // if (!demand.name || !demand.phoneNumber || !demand.startingDate || !demand.endingDate) {
        //   toast({
        //     description: t('car_details.error_fill_fields'),
        //     status: "warning",
        //     duration: 5000,
        //     isClosable: true,
        //     position: 'bottom-left'
        //   });
        //   return;
        // }
    
        if (post.outOfStock) {
          toast({
            description: t('car_details.error_out_of_stock'),
            status: "error",
            duration: 5000,
            isClosable: true,
            position: 'bottom-left'
          });
          navigate('/');
          return;
        }
    
        setLoading(true); 
    
        const calculatedTotalPrice = calculateTotalPrice();
        const requestData = {
          ...demand,
          car: `${post.name} ${post.model}`,
          totalPrice: calculatedTotalPrice,
          carId: id,
          etat: 'EN_ATTENTE'
        };

        // console.log('requestData', requestData);
    
        axios.post(`${baseURL}/open/demands`, requestData)
          .then(({ data }) => {
            setLoading(false);
            toast({
              description: t('car_details.success_booking'),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: 'bottom-left'
            });
            // setDemand(initialBookingData);
            navigate('/');
          })
          .catch((e) => {
            setLoading(false); 
            toast({
              description: t('car_details.error_general'),
              status: "error",
              duration: 5000,
              isClosable: true,
              position: 'bottom-left'
            });
          });
      };
    


 

    useEffect(() => {
        setIsFetching(true);
        axios.get(`${baseURL}/open/cars/${id}`).then(({ data }) => {
            // console.log('car data', data);
            setIsFetching(false);
            setPost(_ => data);
            setFetchingError("");
        }).catch(e => {
            setIsFetching(false);
            setFetchingError("Could not fetch car details, plz make you are connected");
        })

        setFetchingMore(true);
        axios.get(`${baseURL}/open/cars`).then(({ data }) => {
            setFetchingMore(false);
            setMoreCars(_ => shuffleArray(data));
        }).catch(e => {
            setFetchingMore(false);
        })
    }, [id])

    useEffect(() => {
        if (post) {
            setDemand((prevDemand) => ({
                ...prevDemand,
                car: `${post.name} ${post.model}`,
                totalPrice: totalPrice // Use total price from state
        }));
    }
}, [post, totalPrice]);

    const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
          }
        }
      };
      
      const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1
        }
      };





    const colorSchemeButton = useColorModeValue('whiteAlpha', 'gray');
    const colorSchemeBookNow = useColorModeValue('teal', 'yellow');




    const bgCarDetails = useColorModeValue(colors.buttonBgColor, colors.buttonBgColor);
    const iconSwtchColor = useColorModeValue("green.400", "yellow.400");


    const isDesktop = useBreakpointValue({ base: false, lg: true });
    


    
    return (
        post &&
        <>
      
            <Stack maxW={'9xl'} mx={'auto'} mt={24} py={6} px={{ base: '6', lg: '20' }} bgColor={''}>
                <Box className="row" bgColor={''} justifyContent={'space-around'}>
                    <div className=" divGauche col-md-7 mb-4 " style={{ backgroundColor: '', }}>

                        <ImgCarCarousel mainImage={post.mainImage} otherImages={post.otherImages} dir={dir}  />

                        {isDesktop ? 
                        
                        <HStack justifyContent={'space-between'} mb={10} >

                            <VStack alignItems={'flex-start'} spacing={0}>
                                <Text style={{ fontSize: '30px', fontFamily: 'MontserratM', fontWeight: '700' }}>{`${post.name} ${post.model} ${post.year}`}</Text>
                                <Badge  textTransform="none"  className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} fontSize='1.1em' colorScheme={"teal"}> {t(`listing.categories.${post.categoryType}`)}</Badge>
                            </VStack>


                            <HStack spacing={0}>
                                <Text bgColor={''} transform={'scale(1, 1.1)'} fontSize={'6xl'} className={` ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>
                                    {Math.floor(post.pricePerDay)}
                                </Text>
                                <VStack spacing={0} bgColor={''} lineHeight={'normal'} alignItems={'flex-start'} >
                                <Text lineHeight={'normal'}  bgColor={''} className={` ${dir === 'ltr' ? 'jakR' : 'cairoR'}`} style={{ fontSize: '30px', fontWeight: '500', lineHeight:'1' }}>{t('card.currency')}</Text>
                                <Text lineHeight={'normal'} bgColor={''} className={` ${dir === 'ltr' ? 'jakR' : 'cairoR'}`} style={{ color: '#6415FF', fontSize: '25px', fontWeight: '500', lineHeight:'1'}}>{t('car_details.perDay')}</Text>

                                </VStack>
 
                            </HStack>
                        </HStack>

                        : 
                        <VStack alignItems={'flex-start'}  mb={10}  bgColor={''} >

                            <VStack spacing={0} bgColor={''}>
                                <Text style={{ fontSize: '20px', fontFamily: 'Montserrat', fontWeight: '700' }}>{`${post.name} ${post.model} ${post.year}`}</Text>
                                {/* <Divider my={0}/> */}
                            </VStack>


                            <HStack justifyContent={'space-between'} bgColor={''} width={'100%'} alignContent={'center'}>
                                <HStack spacing={0}>
                                    <Text bgColor={''} transform={'scale(1, 1.1)'} fontSize={'4xl'} className={` ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>
                                        {Math.floor(post.pricePerDay)}
                                    </Text>
                                    <VStack spacing={0} bgColor={''} lineHeight={'normal'} alignItems={'flex-start'} >
                                    <Text lineHeight={'normal'}  bgColor={''} className={` ${dir === 'ltr' ? 'jakR' : 'cairoR'}`} style={{  fontSize: '20px', fontWeight: '500', lineHeight:'1' }}>{t('card.currency')}</Text>
                                    <Text lineHeight={'normal'} bgColor={''} className={` ${dir === 'ltr' ? 'jakR' : 'cairoR'}`} style={{ color: colors.yellowPrimary, fontSize: '15px', fontWeight: '500', lineHeight:'1'}}>{t('car_details.perDay')}</Text>

                                    </VStack>

                                </HStack>


                                <Badge  textTransform="none"  className={` ${dir === 'ltr' ? 'jakR' : 'cairoR'}`} fontSize='1.2em' colorScheme={"teal"}>{t(`listing.categories.${post.categoryType}`)}</Badge>
                            </HStack>
                            
                        </VStack>
                    
                    }
 


                        <SimpleGrid mb={10} columns={[2, 4]} spacing={5} className="div_Cardetails">
                            <Box overflow="hidden" position={'relative'} p={dir === "ltr" ? "1.5rem 0 1rem 0.75rem" : "1.5rem 0.75rem 1rem 0 " }  textAlign={dir === "ltr" ? "left" : "right"} bgColor={bgCarDetails}>
                                <Icon as={BsFuelPumpDiesel} boxSize={6} color="#6415FF" />
                                <Icon as={BsFuelPumpDiesel}   color={'yellow.400'} boxSize={24} position={'absolute'} top={'-4'} right={dir === "ltr" ? '-10' : ''}  left={dir === "ltr" ?  '' : '-10'} opacity={'0.1'} />
                                <Text fontWeight="bold" color={'white'} mt={2} className={`${dir === 'ltr' ? 'MontserratM' : 'cairoB'}`}>
                                    {t('car.fuelConsumption')}
                                </Text>
                                <Text fontSize={'small'} color={'white'} className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{t('card.fuelConsumption', { count: post.lettersPer90Kms })}</Text>
                            </Box>
                            <Box overflow="hidden" position={'relative'} p={dir === "ltr" ? "1.5rem 0 1rem 0.75rem" : "1.5rem 0.75rem 1rem 0 " } textAlign={dir === "ltr" ? "left" : "right"}  bgColor={bgCarDetails}>
                                <Icon as={TbUsers} boxSize={6} color="#6415FF" />
                                <Icon as={TbUsers}   color={'yellow.400'} boxSize={24} position={'absolute'} top={'-4'} right={dir === "ltr" ? '-10' : ''}  left={dir === "ltr" ?  '' : '-10'} opacity={'0.1'} />
                                <Text fontWeight="bold" color={'white'} mt={2} className={`${dir === 'ltr' ? 'MontserratM' : 'cairoB'}`}>
                                {t('listing.capacityText')}
                                </Text>
                                <Text fontSize={'small'} color={'white'} className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}> {post.placesNumber} {t('listing.seater')}</Text>
                            </Box>
                            <Box overflow="hidden" position={'relative'} p={dir === "ltr" ? "1.5rem 0 1rem 0.75rem" : "1.5rem 0.75rem 1rem 0 " } textAlign={dir === "ltr" ? "left" : "right"}  bgColor={bgCarDetails}>
                                <Icon as={TbManualGearbox} boxSize={6} color="#6415FF" />
                                <Icon as={TbManualGearbox} color={'yellow.400'} boxSize={24} position={'absolute'} top={'-4'} right={dir === "ltr" ? '-10' : ''}  left={dir === "ltr" ?  '' : '-10'} opacity={'0.1'} />
                                <Text fontWeight="bold" color={'white'} mt={2} className={`${dir === 'ltr' ? 'MontserratM' : 'cairoB'}`}>
                                    {t('car.transmission')}
                                </Text>
                                <Text fontSize={'small'} color={'white'} className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{post.transmission}</Text>
                            </Box>
                            <Box overflow="hidden" position={'relative'} p={dir === "ltr" ? "1.5rem 0 1rem 0.75rem" : "1.5rem 0.75rem 1rem 0 " } textAlign={dir === "ltr" ? "left" : "right"}  bgColor={bgCarDetails}>
                                <Icon as={GiCarDoor} boxSize={6} color="#6415FF" />
                                <Icon as={GiCarDoor} color={'yellow.400'} boxSize={24} position={'absolute'} top={'-4'} right={dir === "ltr" ? '-10' : ''}  left={dir === "ltr" ?  '' : '-10'} opacity={'0.1'} />
                                <Text fontWeight="bold" color={'white'} mt={2} className={`${dir === 'ltr' ? 'MontserratM' : 'cairoB'}`}>
                                    {t('car.door')}
                                </Text>
                                <Text fontSize={'small'} color={'white'} className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{t('car.doorNumbers', { count: post.doorsNumber })}</Text>
                            </Box>
                        </SimpleGrid>
                        {/* <hr className='mb-4' /> */}
                        <h5  className={` my-3 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{fontSize: '22px', fontWeight: '600' }}>{t('car_details.description')} :</h5>
                        <p className={`mb-5 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{fontSize: '16px', fontWeight: '500', textAlign: 'justify' }}>
                            {getDescriptionByLanguage()}
                        </p>
                        
                        {
                            functionalities.filter(f => post.functionalities.includes(f))!==0  &&
                            <div className="included__wrapper"
                                style={{ alignContent: 'center', backgroundColor: colorMode === "dark" ? colors.buttonBgColor : '#e1e1e1', borderRadius: '20px' }}>
                                <h5 className={`Included ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} >{t('car_details.included')} :</h5>
                                <motion.ul 
                                    variants={container}
                                    initial="hidden"
                                    animate="visible"
                                    className={` mt-4 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "1rem", backgroundColor:'', paddingLeft: dir === 'ltr' ? '2rem' : '0', paddingRight: dir === 'ltr' ? '0' : '2rem',  }}>
                                    
                                    {functionalities.filter(f => post.functionalities.includes(f)).map(f => (
                                        
                                        <motion.li key={uuid()}  variants={item} style={{
                                            display: 'flex',
                                            alignItems: 'center', 
                                          }}>
                                            <FaCheckCircle color={colors.greenSecondary} style={{  fontSize:'18px', marginRight: dir === 'ltr' ? '5px' : '0px', marginLeft: dir === 'ltr' ? '0px' : '5px'}}/>
                                            {t(`car_details.functionalities.${f}`)}
                                        </motion.li>
                                        
                                        ))}
                                </motion.ul>
                            </div>
                        }

                        {
                            functionalities.filter(f => !post.functionalities.includes(f)).length!==0 &&
                            <div className="included__wrapper"
                            style={{ alignContent: 'center', backgroundColor: colorMode === "dark" ? colors.buttonBgColor : '#e1e1e1', borderRadius: '20px' }}>
                                <h5 className={`Excluded ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} >{t('car_details.excluded')} :</h5>
                                <motion.ul 
                                    variants={container}
                                    initial="hidden"
                                    animate="visible"
                                    className={` mt-4 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "1rem", paddingLeft: dir === 'ltr' ? '2rem' : '0', paddingRight: dir === 'ltr' ? '0' : '2rem', }}>

                                    {functionalities.filter(f => !post.functionalities.includes(f)).map(f => (
                                    <motion.li key={uuid()}  variants={item}  style={{
                                        display: 'flex',
                                        alignItems: 'center', 
                                      }}>
                                        <FaTimesCircle color={colors.accent} style={{ fontSize:'18px', marginRight: dir === 'ltr' ? '5px' : '0px', marginLeft: dir === 'ltr' ? '0px' : '5px' }}/>
                                        {t(`car_details.functionalities.${f}`)}
                                    </motion.li>
                                    ))}
                                </motion.ul>
                            </div>
                        }
                    </div>
                    <div className="DivDroite col-md-4" style={{ backgroundColor: '' }}>
                        <div className="form_Rent_This_Car mb-5">
                        <form >
                                <div className="card border-0 shadow p-3" style={{ borderRadius: '30px', backgroundColor: colors.buttonBgColor, color: '#fff' }}>
                                    <div className="">
                                    <h4 className={` card-title text-center ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}>{t('form.rentThisCar')}</h4>
                                    <p className={` card-title mb-3 text-center ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{t('form.completeForm')}</p>
                                    <div className={`card-text p-2  ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>
                                        <div className="form-group">
                                        <input value={demand.name} onChange={e => handleDemand({ name: e.target.value })} type='text' className='form-control border-0 mb-4 py-3'
                                            placeholder={t('form.yourName')} style={{ borderRadius: '15px', backgroundColor: '#eee' }} />
                                        </div>
                                        <div className="form-group">
                                        <input value={demand.phoneNumber} onChange={e => handleDemand({ phoneNumber: e.target.value })} type='text' className='form-control border-0 mb-4 py-3'
                                            placeholder={t('form.phoneNumber')} style={{ borderRadius: '15px', backgroundColor: '#eee' }} />
                                        </div>
                                        <div className='row d-flex' style={{ justifyContent: 'space-between' }}>
                                            <div className="form-group col-md-6 mb-4">
                                                <label style={{ marginBottom: '5px' }}>{t('form.startDate')}</label>
                                                <StartDateModal onStartDateSelect={handleStartDateSelect} dir={dir}/>
                                            </div>
                                            <div className="form-group col-md-6 mb-4">
                                                <label style={{ marginBottom: '5px' }}>{t('form.startTime')}</label>
                                                <StartTime onStartTimeSelect={handleStartTimeSelect}/>
                                            </div>
                                        </div>
                                        
                                        <div className='row d-flex' style={{ justifyContent: 'space-between' }}>
                                            <div className="form-group col-md-6 mb-4">
                                                <label style={{ marginBottom: '5px' }}>{t('form.returnDate')}</label>
                                                <EndDateModal onEndDateSelect={handleEndDateSelect} dir={dir}/>
                                            </div>
                                            <div className="form-group col-md-6 mb-4">
                                                <label style={{ marginBottom: '5px' }}>{t('form.returnTime')}</label>
                                                <ReturnTime onEndTimeSelect={handleEndTimeSelect}/>
                                            </div>
                                        </div>
                                        <hr />
                                        <ConfirmBookingModal demand={demand} mainImage={post.mainImage} onClickHandleBook={handleSubmit} isLoading={loading} dir={dir}/>
                                       
                                    </div>
                                    </div>
                                </div>
                              
                        </form>
                        </div>


                        <Box mt={14} className="More_Car_List card mb-5 border-0  shadow " style={{ backgroundColor: colors.buttonBgColor, height: '400px', borderRadius: '20px' }}>
                            <div className="card-body ">
                                <div className='py-2 mt-4' style={{ backgroundColor: '#6415FF', borderRadius: '20px' }}>
                                    <h4  className={` card-title text-center ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} style={{ color: '#fff', margin:'0px' }}>{t('car_details.moreCarList')}</h4>
                                </div>

                                <div className='px-3 pt-5' style={{ color: '#fff', backgroundColor: '', justifyContent: `${dir === 'ltr' ? 'left' : 'right'}`, alignItems: 'center'  }}>
                                    <ul className='Montserrat' style={{ fontSize: '20px' }}>
                                        {fetchingMore ? <Loader absolute={false} /> :
                                            moreCars?.filter(car => car.id !== id)?.slice(0, 4).map(c =>
                                            (
                                            <li className='more__cars mb-2' style={{ cursor: "pointer", display: 'flex', alignItems: 'center',color: '#fff' }} key={uuid()} onClick={() => navigate(`/car-details/${c.id}`)}>
                                                <FaCircle style={{ color: '#fff', fontSize:'15px', marginRight:'15px'}}/>                                               
                                                {c.name} {c.model}
                                              </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Box>

                    </div>

                </Box>
            </Stack>
            <Ftr />
        </>
    )
}

export default CarDetails
