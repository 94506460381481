import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReturnTime = ({ onEndTimeSelect }) => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const [endTime, setEndTime] = useState(null);

  const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hours = String(Math.floor(index / 2)).padStart(2, "0");
    const minutes = index % 2 === 0 ? "00" : "30";
    const time = `${hours}:${minutes}`;

    return {
      value: time,
      label: time,
    };
  });

  const bgColorPanel = useColorModeValue('#fff', 'gray.700');
  const bgColorInput = useColorModeValue('#fff', '#fff');

  // Handler for when a time is selected
  const handleTimeChange = (selectedOption) => {
    setEndTime(selectedOption.value); // Update local state
    onEndTimeSelect(selectedOption.value); // Call the parent callback
  };

  return (
    <>
      <Select
        placeholder="--:--"
        size={'lg'}
        options={timeOptions}
        onChange={handleTimeChange} // Add onChange handler here
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: bgColorInput,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "gray",
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: bgColorPanel,
            color: colorMode === "light" ? "black" : "white",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? colorMode === "light" ? "gray.200" : "gray.600"
              : "transparent",
            color: state.isSelected
              ? colorMode === "light" ? "black" : "white"
              : colorMode === "light" ? "black" : "white",
            _hover: {
              backgroundColor: colorMode === "light" ? "gray.100" : "gray.500",
            },
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "gray.900",
            backgroundColor: "gray.500"
          }),
        }}
      />
    </>
  );
};

export default ReturnTime;




// import { useColorMode, useColorModeValue } from '@chakra-ui/react'
// import { Select } from 'chakra-react-select'
// import React from 'react'
// import { useTranslation } from 'react-i18next';

// const ReturnTime = ({onEndTimeSelect}) => {

//   const { colorMode } = useColorMode();
//   const { t } = useTranslation();

//   const [endTime, setEndTime] = useState(null);

//   const timeOptions = Array.from({ length: 48 }, (_, index) => {
//     const hours = String(Math.floor(index / 2)).padStart(2, "0");
//     const minutes = index % 2 === 0 ? "00" : "30";
//     const time = `${hours}:${minutes}`;

//     return {
//       value: time,
//       label: time,
//     };
//   });

//   const bgColorPanel = useColorModeValue('#fff', 'gray.700"');
//   const bgColorInput = useColorModeValue('#fff', '#fff');

//   return (
//     <>
//       <Select
//         placeholder="--:--"
//         size={'lg'}
//         options={timeOptions}
//         chakraStyles={{
//           control: (provided) => ({
//             ...provided,
//             backgroundColor: bgColorInput,
//           }),
//           placeholder: (provided) => ({
//             ...provided,
//             color: "gray",
//           }),
//           menu: (provided) => ({
//             ...provided,
//             backgroundColor: bgColorPanel,
//             color: colorMode === "light" ? "black" : "white", 
//           }),
//           option: (provided, state) => ({
//             ...provided,
//             backgroundColor: state.isSelected
//               ? colorMode === "light" ? "gray.200" : "gray.600"
//               : "transparent",
//             color: state.isSelected
//               ? colorMode === "light" ? "black" : "white"
//               : colorMode === "light" ? "black" : "white",
//             _hover: {
//               backgroundColor: colorMode === "light" ? "gray.100" : "gray.500",
//             },
//           }),
//           dropdownIndicator: (provided) => ({
//             ...provided,
//             color: "gray.900",
//             backgroundColor: "gray.500"
//           }),
//         }}
//       />
//     </>
//   );
// }


// export default ReturnTime
