import { Box, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { SlControlPlay, SlControlPause } from 'react-icons/sl'; // Import the icons

export default function Carroussel(props) {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });
  const isDesktop = useBreakpointValue({ base: false, lg: true });


  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);
  const [index, setIndex] = useState(0); // To track the current slide index
  const [isAutoplayed, setIsAutoplayed] = useState(false); // Autoplay state
  const autoPlayRef = useRef();

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  useEffect(() => {
    clearInterval(autoPlayRef.current);
    if (isAutoplayed) {
      autoPlayRef.current = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % cards.length); // Loop through slides
      }, 3000); // Adjust autoplay interval here
    }
    return () => clearInterval(autoPlayRef.current);
  }, [isAutoplayed, cards.length]);

  // Handle slide index changes
  useEffect(() => {
    setGoToSlide(index);
  }, [index]);

  return (
    <Box
      width={props.width}
      height={props.height}
      margin={props.margin}
      position="relative"
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius} 
        showNavigation={false} // Disable default arrows
        animationConfig={config.gentle}
      />
      {props.showArrows && (
        <IconButton
          icon={isAutoplayed ? <SlControlPause /> : <SlControlPlay />}
          aria-label="Autoplay Toggle"
          onClick={() => setIsAutoplayed((prev) => !prev)}
          rounded="full"
          position="absolute"
          top={"90px"}
          left="36px"
          zIndex="6" 
          // size={'lg'}
          height={'4rem'}
        />
       )} 
    </Box>
  );
}

