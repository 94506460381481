// src/colors.js
const colors = {
    yellowPrimary: "#6415FF",
    yellowSecondary: "#d1bf00",
    yellowDark: "#74580f",
    yellowLight :"#fbffe7",
    greenPrimary: "#06837f",

    greenSecondary: "#43e5a0",
    greenDark: "#11563a",
    greenLight: "#dafeef",
    accent: "#e74c3c",
    background: "#ecf0f1",
    text: "#2c3e50",


    sidebarColor : "#f6f6f6",
    buttonBgColor : "#343a40",
    selectedBtnText : "#fff"
  };
  
  export default colors;
  