import { Box, Heading, Text, useColorMode } from '@chakra-ui/react';
import React from 'react';
import colors from '../../../utils/colors';

const DateCard = () => {

  const { colorMode } = useColorMode();

  const currentDate = new Date();
  const daysOfWeek = ['DIMANCHE', 'LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI'];
  const months = [
    'JANVIER',
    'FEVRIER',
    'MARS',
    'AVRIL',
    'MAI',
    'JUIN',
    'JUILLET',
    'AOUT',
    'SEPTEMBRE',
    'OCTOBRE',
    'NOVEMBRE',
    'DECEMBRE',
  ];

  const dayOfWeek = daysOfWeek[currentDate.getDay()];
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];

  return (
    <Box as="section" width={'178px'} height={'163px'}>
      <Box align={'center'} bgColor={ colorMode === "dark" ? 'white'  : colors.buttonBgColor} py={2} px={{ base: '6', md: '8' }} color="white" borderRadius={'13px 13px 0px 0px'}>
        <Text className={'jakB'} fontSize='sm'  color={ colorMode === "dark" ? '#10396D' : colors.selectedBtnText  } fontWeight='bold' mb={0}>
          {dayOfWeek}
        </Text>
      </Box>
      <Box align={'center'} bgColor={ colorMode === "dark" ? '#4A4D4F'  : 'white'}  px={{ base: '6', md: '8' }} color={colors.secondary} borderRadius={'0px 0px 13px 13px '} pb={3}>
        <Heading color={colorMode === "dark" ? 'white' : colors.buttonBgColor} fontSize="6xl" fontWeight="extrabold" className={'jakB'} mb={0}>
          {day}
        </Heading>
        <Text className={'jakM'} fontSize='sm' color={colorMode === "dark" ? 'white' : colors.buttonBgColor} fontWeight='bold' mb={0}>
          {month}
        </Text>
      </Box>
    </Box>
  );
};

export default DateCard;

