import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";
import colors from "../../utils/colors";

export default function ReviewBox({ text, author }) {
  return (
    <Wrapper className="darkBg  flexNullCenter flexColumn" style={{backgroundColor: '#6415FF', borderRadius:"20px"}}>
      <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper>
      <p className="cairoR" style={{ paddingBottom: "30px", color:"#fff",fontSize:"13px"}}>
        {text}
      </p>
      <p className="MontserratM" style={{alignSelf: 'flex-end',fontSize:"13px",color:"#ffc107"}}>
        <em>{author}</em>
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
`;
const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;




// import React from "react";
// import styled from "styled-components";
// import QuoteIcon from "../../assets/svg/Quotes";
// import glowBottom from "../../assets/svg/glow-bottom.svg";

// const divStyle = {
//   backgroundColor: '#181c2e',
//   borderRadius: '0px 0px 10px 10px',
//   overflowX: 'hidden',
// };

// export default function ReviewBox({ text, author }) {
//   return (
//     <StyledWrapper className="darkBg  flexNullCenter flexColumn" style={{ borderRadius: "20px" }}>
//       <QuoteWrapper>
//         <QuoteIcon />
//       </QuoteWrapper>
//       <RotatedGlowBottom />
//       <p className="Montserrat" style={{ paddingBottom: "30px", color: "#fff", fontSize: "13px" }}>
//         {text}
//       </p>
//       <p className="MontserratM" style={{ alignSelf: 'flex-end', fontSize: "13px", color: "#7620ff" }}>
//         <em>{author}</em>
//       </p>
//     </StyledWrapper>
//   );
// }

// const StyledWrapper = styled.div`
//   width: 100%;
//   padding: 20px 30px;
//   margin-top: 30px;
//   background-color: #181c2e;
//   border-radius: 20px;
//   position: relative; /* Needed for absolute positioning of pseudo-element */
// `;

// const QuoteWrapper = styled.div`
//   position: relative;
//   top: -40px;
// `;

// const RotatedGlowBottom = styled.div`
//   background-image: url(${glowBottom});
//   background-size: cover;
//   background-repeat: no-repeat;
//   border-radius: 20px;
//   width: 100%; /* Set the width to 100% */
//   height: 100%; /* Set the height to 100% */
//   position: absolute; /* Position absolutely within the parent */
//   top: 0;
//   left: 0;
//   transform: rotateY(180deg); /* Rotate the image in the Y-axis */
// `;
