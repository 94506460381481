// redux/carsSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  initialCarsList: [],
};

// Define the slice
const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    setInitialCarsList(state, action) {
      state.initialCarsList = action.payload;
    },
    setFilteredCarsList(state, action) {
      state.initialCarsList = action.payload;
    },
  },
});

export const { setInitialCarsList, setFilteredCarsList } = carsSlice.actions;
export default carsSlice.reducer;
