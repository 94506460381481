import React, { useState } from 'react';
import { Checkbox } from '@chakra-ui/react';  // Import Chakra UI Checkbox
import './search-filter.css';
import 'font-awesome/css/font-awesome.min.css';

const SearchCars = ({ cb }) => {
  const [selectedCar, setSelectedCar] = useState('All Cars'); // Initialize with "All Cars"
  const [open, setOpen] = useState(false);

  const carList = ['All Cars', 'Alfa Romeo','Alsvin', 'Aston Martin', 'Audi', 'Bentley', 'BMW', 'Bugatti', 'Chevrolet',
    'Chrysler', 'Citroën', 'Dacia', 'Ferrari', 'Fiat', 'Ford', 'Honda', 'Hyundai', 'Jaguar', 'Jeep', 'Kia', 'Lamborghini',
    'Land Rover', 'Lexus', 'Maserati', 'Mazda', 'McLaren', 'Mercedes', 'Mitsubishi', 'Nissan','Opel', 'Peugeot', 'Porsche',
    'Range Rover', 'Renault', 'Rolls-Royce', 'Saab', 'Subaru', 'Suzuki', 'Tesla', 'Toyota', 'Volkswagen', 'Volvo'];

  const handleSelectCar = (carName) => {
    if (carName !== selectedCar) {
      setSelectedCar(carName);
      if (carName === 'All Cars') {
        cb(""); // If "All Cars" is selected, pass an empty string
      } else {
        cb(carName);
      }
    }
    setOpen(false); // Close the list when an item is selected
  };

  return (
    <>
      <div className="containerX">
        <div
          className={`select-btn ${open ? 'open' : ''}`}
          onClick={() => setOpen(!open)}
        >
          <span className="btn-text MontserratM">
            {selectedCar ? selectedCar : 'All Cars'}
          </span>
          <span className="arrow-dwn">
            <i className="fa fa-solid fa-chevron-down" style={{ color: '#fff', backgroundColor: '#6415ff' }}></i>
          </span>
        </div>

        <ul className={`list-items MontserratM ${open ? 'open' : ''}`} style={{ maxHeight: '300px', overflowY: 'auto', paddingTop:'16px', paddingBottom:'16px' }}> 
          {/* Limit height and add scroll */}
          {carList.map((carName, index) => (
            <li
              key={index}
              onClick={() => handleSelectCar(carName)}
              style={{color:'#000'}}
            >
              <Checkbox
                isChecked={selectedCar === carName}
                onChange={() => handleSelectCar(carName)}
                size="md"
                colorScheme="purple"
                className="checkbox"
                mb={3}
              >
                {carName}
              </Checkbox>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SearchCars;




// import React, { useState } from 'react';
// import './search-filter.css';
// import 'font-awesome/css/font-awesome.min.css';

// const SearchCars = ({ cb }) => {
//   const [selectedCar, setSelectedCar] = useState('All Cars'); // Initialize with "All Cars"
//   const [open, setOpen] = useState(false);

//   const handleSelectCar = (carName) => {
//     if (carName !== selectedCar) {
//       setSelectedCar(carName);
//       if (carName === 'All Cars') {
//         cb("");
//       } else {
//         cb(carName);
//       }
//     }
//     setOpen(false); // Close the list when an item is selected
//   };

//   return (
//     <>
//       <div className="containerX">
//         <div
//           className={`select-btn ${open ? 'open' : ''}`}
//           onClick={() => setOpen(!open)}
//         >
//           <span className="btn-text MontserratM">
//             {selectedCar ? selectedCar : 'All Cars'}
//           </span>
//           <span className="arrow-dwn">
//             <i className="fa fa-solid fa-chevron-down" style={{ color: '#fff', backgroundColor: '#6415ff' }}></i>
//           </span>
//         </div>

//         <ul className={`list-items Montserrat ${open ? 'open' : ''}`}>

//         {['All Cars', 'Audi', 'Bmw', 'Citroën', 'Cupra', 'Dacia', 'Fiat', 'Hyundai', 'Jaguar', 'Maserati', 
//             'Mercedes', 'Range Rover', 'Renault', 'Volkswagen'].map((carName, index) => (
//             <li
//               key={index}
//               className={`item ${selectedCar === carName ? 'checked' : ''}`}
//               onClick={() => handleSelectCar(carName)}
//             >
//               <span className="checkbox">
//                 <i className="fa fa-solid fa-check check-icon"></i>
//               </span>
//               <span className="item-text">{carName}</span>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </>
//   );
// };

// export default SearchCars;
