import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE, 
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { encryptTransform } from 'redux-persist-transform-encrypt';

// dotenv.config();

import tokenSlice from './slices/tokenSlice';
import toggleReducer from './slices/toggleCompteur';
import userReducer from './slices/userSlice';
import memberReducer from './slices/memberSlice';
import isLoggedInReducer from './slices/isLoggedInSlice';
import passwordResetReducer from './slices/passwordResetSlice';
import tabReducer from './slices/tabSlice';
import paginationReducer from './slices/paginationSlice'
import carsReducer from './slices/carsSlice'

const secretKey = 'ixVFBCP7ddmz6p3PEPcB';

// Create the persist configuration
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: secretKey, // Use the secret key from environment variables
      onError: function (error) {
        // Handle the error.
        console.error('Encryption Error:', error);
      },
    }),
  ],
};

// Create the persisted reducer
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    toggle: toggleReducer,
    user: userReducer,
    token: tokenSlice,
    isLoggedIn: isLoggedInReducer,
    member: memberReducer,
    passwordReset: passwordResetReducer,
    tab: tabReducer,
    pagination: paginationReducer,
    cars: carsReducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Persist the store
const persistor = persistStore(store);

export { store, persistor };






// import { configureStore } from '@reduxjs/toolkit';
// import tokenSlice from './slices/tokenSlice';

// import toggleReducer from './slices/toggleCompteur';
// import userReducer from './slices/userSlice'; 
// import memberReducer from './slices/memberSlice'; 
// import isLoggedInReducer from './slices/isLoggedInSlice';
// import passwordResetReducer from './slices/passwordResetSlice'
// import demandeCongeReducer from './slices/demandeCongeSlice'
// import tabReducer from './slices/tabSlice'
// import buttonReducer from './slices/buttonSlice'
// import geolocationReducer from './slices/geolocationSlice';

// // Load state from local storage
// const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem('reduxState');
//     if (serializedState === null) {
//       return undefined;
//     }
//     return JSON.parse(serializedState);
//   } catch (err) {
//     return undefined;
//   }
// };

// // Save state to local storage
// const saveState = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem('reduxState', serializedState);
//   } catch (err) {
//     // Handle errors
//   }
// };

// // // Save state to local storage
// // const saveState = (state) => {
// //   try {
// //     const { isLoggedIn, ...stateWithoutIsLoggedIn } = state; // Exclude isLoggedIn from state
// //     const serializedState = JSON.stringify(stateWithoutIsLoggedIn);
// //     localStorage.setItem('reduxState', serializedState);
// //   } catch (err) {
// //     // Handle errors
// //   }
// // };

// const store = configureStore({
//   reducer: {
//     toggle: toggleReducer, 
//     user: userReducer,
//     token: tokenSlice,
//     isLoggedIn: isLoggedInReducer,
//     member: memberReducer,
//     passwordReset: passwordResetReducer,
//     demandeConge: demandeCongeReducer,
//     tab: tabReducer,
//     buttons: buttonReducer,
//     geolocation: geolocationReducer,
//   },
//   preloadedState: loadState(), // Load initial state from local storage
// });

// // Subscribe to store changes and save state to local storage
// store.subscribe(() => {
//   saveState(store.getState());
// });

// export default store;
