import React from "react";
import styled from "./Facilities.module.css";
import boxing from "../videoCar.mp4";
import Heading from "../helpers/heading/Heading";
import Container from "../helpers/wrapper/Container";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";




const Facilities = ({dir}) => {
  const { t } = useTranslation();

  const heading = (
    <>
      {t('facilities.headingPart1')},<span>{t('facilities.headingPart2')}</span>, {t('facilities.headingPart3')}
      
    </>
  );

  return (
    <section className={styled.facility} id="facilities">
      <video src={boxing} autoPlay loop muted />
      <div className={styled.facility__overlay}>
        <Container>
          <article className={styled.facility__content}>
            {dir === 'ltr' && (
                <Heading className=" MontserratB" heading={heading} style={{color:'#fff'}}>
                {t('facilities.heading')}
              </Heading>
            )}

          {dir === 'rtl' && (
            <Text className={'cairoB'} fontSize='4xl' style={{color:'#fff'}}>
              {heading}
            </Text>
            )}
            <p  className={`${dir === 'ltr' ? 'Lato' : 'cairoR'}`} style={{color:'#fff'}}>
            {t('facilities.paragraph')}
            </p>
          </article>
        </Container>
      </div>
    </section>
  );
};

export default Facilities;
