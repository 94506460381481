









import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link , NavLink} from 'react-router-dom';
import Sidebar from "./Sidebar";
import Backdrop from "../Elements/Backdrop";

import LogoIcon from "../../assets/svg/sirycar.svg";
import BurgerIcon from "../../assets/svg/BurgerIcon";

import '../../styles/index.css';
import { HamburgerIcon, Icon } from "@chakra-ui/icons";
import { useColorModeValue } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import LanguageSwitcher from '../Elements/languageSwitcher/LanguageSwitcher'
import { useTranslation } from "react-i18next";
import { baseUrlNet } from "../../utils/useRequest";

const TopNavbar = ({ dir, handleLanguageChange }) => {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { t } = useTranslation();

  return (
    <>
      <Sidebar dir ={dir} sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} handleLanguageChange={handleLanguageChange} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate //whiteBg"style={y > 100 ? { height: "80px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
            <Link className="navbar-brand" to="/">
                        <img src={`${baseUrlNet}logo/sirycar.svg`} alt="logo" width="109"  className="d-inline-block align-text-top"/>
            </Link>
          <BurderWrapper  className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
          <Icon as={HamburgerIcon} boxSize={8} />
           
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <NavLink activeclass="active" className={` cssNavLink ${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`} style={{ padding: "10px 15px" ,color:""}} to="/" spy={true}  offset={-80}>
                 {t('home')}
              </NavLink>
            </li>
            <li className="semiBold font15 pointer">
              <NavLink activeclass="active" className={` cssNavLink ${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`} style={{ padding: "10px 15px" ,color:""}} to="/about" spy={true}  offset={-80}>
              {t('aboutNav')}
              </NavLink>
            </li>
            <li className="semiBold font15 pointer">
              <NavLink activeclass="active" className={` cssNavLink ${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`} style={{ padding: "10px 15px" ,color:""}} to="/reviews" spy={true}  offset={-80}>
              {t('reviews')}
              </NavLink>
            </li>
            <li className="semiBold font15 pointer">
              <NavLink activeclass="active" className={` cssNavLink ${dir === 'ltr' ? 'MontserratR' : 'cairoR'}`} style={{ padding: "10px 15px" ,color:""}} to="/contact" spy={true}  offset={-80}>
              {t('contact')}
              </NavLink>
            </li>
           
          </UlWrapper>


            <UlWrapperRight className="flexNullCenter">
                <LanguageSwitcher onChangeDir={handleLanguageChange} dir={dir} />
            </UlWrapperRight>

        </NavInner>
      </Wrapper>
    </>
  );
}



const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #e9e5ff;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


export default TopNavbar;

