import React, { useEffect, useState } from 'react'
import { baseURL } from '../../../utils/useRequest';
import { v4 as uuid } from "uuid";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import './form.css'
import { useSelector } from 'react-redux';
import { Box, Button, Stack, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react';
import CarImageUploader from '../addCarForm/CarImageUploader';
import CarImageUpdate from './CarImageUpdate';
import colors from '../../../utils/colors';
 
const UpdateCar = () => { 
    const { id }=useParams();
    const [loading, setLoading]=useState(false);
    const [loadingImage, setLoadingImage]=useState(false);
    const [error, setError]=useState("");
    const [type, setType]=useState("success");
    const colorMode = useColorMode();

    const [uploadedImageId, setUploadedImageId] = useState(""); 
    const toast = useToast();
    const navigate = useNavigate();

    const styleCarBox = {
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'block',
        overflow: 'clip',
      }
    
      

    const [data, setData]=useState({
        name: "",
        model: "",
        year:"",
        totalCar:1,
        pricePerDay: 0,
        carburantType: "",
        categoryType: "",
        transmission: "",
        descriptionFr: "",
        descriptionEn: "",
        descriptionAr: "",
        placesNumber: 0,
        hasHeater: false,
        isLuxuryCar: false,
        lettersPer90Kms: 0,
        doorsNumber: 0,
        functionalities: {"All Wheel Drive": false, "Audio Input": false, "Air Conditioning": false, "Heated Seats": false, "FM Radio": false, "Bluetooth": false, "GPS Navigation": false, "Sunroof": false, "Android Auto": false, "Camera": false, "Parking Sensors": false, "Ventilated Seats": false},
        mainImage: "",
        otherImages: []
    });

    const handleData=(attrValue)=>{
        return()=>{
          setData((p)=>({...p, ...attrValue}));
        }
    }

    

    const token = useSelector(state => state.token.token.access_token);


    
    const submit = (e) => {
        e.preventDefault();
        setLoading(true);    
        const formData = new FormData(); 
        
        const { functionalities, mainImage, otherImages, ...dataX } = data; // Destructure and exclude mainImage and otherImages
    
        const requestData = {
            ...dataX,
            functionalities: Object.keys(functionalities).filter(k => functionalities[k]),
        };
    
        formData.append('car', JSON.stringify(requestData)); // Add the cleaned data to formData
        // console.log('requestData', requestData);
    
        // formData.forEach((value, key) => {
        //     console.log(key, value);
        // });
    
        axios.put(`${baseURL}/cars/updateCar/${id}`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
        })
        .then(res => {
          setLoading(false);  
          toast({
            description: "Car has been updated successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/dash/cars-list");
        })
        .catch(e => {
          console.log("Error:\n" + e);
          setLoading(false); 
          toast({
            description: "Please try later",
            status: "error",
            duration: 5000,
            isClosable: true,
          }); 
        });
    };
    



    const handleImageChange = async (e) => {
        const file = e.target.files[0];
      
        
        // Check if the selected file is an image
        const allowedTypes = ["image/jpeg", "image/png", "image/gif"]; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
          toast({
            description: " Veuillez télécharger une image (JPEG, PNG, GIF)",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      
        // Check if the file size exceeds the maximum allowed size (5MB)
        const maxSize = 3 * 1024 * 1024; // 5 MB in bytes
        if (file.size > maxSize) {
          toast({
            title: "Error",
            description: "La taille de l'image dépasse la taille maximale autorisée (3 Mo)",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      
    
        // Proceed with uploading the file if it passes validation
        const formData = new FormData();
        formData.append("file", file);
      
        try {
          const response = await axios.post(`${baseURL}/file/updateCarImage`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            params: {
                id: id 
            }
          });
      

          const uploadedImageId = response.data.id;
            setData(prevData => ({
                ...prevData,
                image: uploadedImageId
            }));
    
        //   console.log('response', uploadedImageId);
      
          // const updatedUserInfo = {
          //   ...userInfo,
          //   imageFileId: uploadedImageId,
          // };
      
          // dispatch(setUserInfo(updatedUserInfo));
      
          // Display a success message if needed
          toast({
            // title: "Success",
            description: "La photo de voiture a été modifiee avec succès",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          console.error("Error uploading file:", error);
          // Handle the error, such as displaying an error message to the user
        }
      };


    useEffect(()=>{
        setLoading(true);
        axios.get(`${baseURL}/cars/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        .then(({data})=>{
            // console.log(data);
            let functionalities={"All Wheel Drive": false, "Audio Input": false, "Air Conditioning": false, "Heated Seats": false, "FM Radio": false, "Bluetooth": false, "GPS Navigation": false, "Sunroof": false, "Android Auto": false, "Camera": false, "Parking Sensors": false, "Ventilated Seats": false};
            data.functionalities.map(f=>functionalities[f.trim()]=true);
            setData(_=>({
                name: data.name,
                model: data.model,
                year: data.year,
                totalCar: data.totalCar,
                pricePerDay: data.pricePerDay,
                carburantType: data.carburantType,
                categoryType: data.categoryType,
                transmission: data.transmission,
                descriptionFr: data.descriptionFr,
                descriptionEn: data.descriptionEn,
                descriptionAr: data.descriptionAr,
                placesNumber: data.placesNumber,
                hasHeater: data.hasHeater,
                isLuxuryCar: data.isLuxuryCar,
                lettersPer90Kms: data.lettersPer90Kms,
                doorsNumber: data.doorsNumber,
                functionalities,
                mainImage: data.mainImage,
                otherImages: data.otherImages
            }));
            // console.log('update data', data)
            setLoading(false);
        }).catch(e=>{
            setError("Could not fetch car details, plz make you are connected");
            setType("error");
            setLoading(false);
        })
    }, [id]);

   

    const bgColor = useColorModeValue('#eee', '#4c4e4f');
    const colorText = useColorModeValue('#000', '#fff');

    const bgColorCard = useColorModeValue(colors.sidebarColor, '#242526');
    
      
    const functionalitiesTranslationsAr = {
        "All Wheel Drive": "نظام الدفع الرباعي",
        "Audio Input": "مدخل صوتي",
        "Air Conditioning": "مكيف الهواء",
        "Heated Seats": "مقاعد مدفأة",
        "FM Radio": "راديو FM",
        "Bluetooth": "بلوتوث",
        "GPS Navigation": "نظام تحديد المواقع",
        "Sunroof": "فتحة سقف",
        "Android Auto": "أندرويد أوتو",
        "Camera": "كاميرا",
        "Parking Sensors": "حساسات ركن السيارة",
        "Ventilated Seats": "مقاعد مهواة"
      };


    return (
    <>
         <Stack maxW={"7xl"} mx={"auto"}>

         <div className="divBar p-3  mb-3 " style={{backgroundColor: bgColorCard ,borderRadius:'26px'}}>
            <div  className="  "style={{backgroundColor:'',color:'',height:'',}} >

                <div className="row d-flex justify-content-between" style={{alignItems:'center'}}>

                    <div className=" col-md-4">

                            <div className="" style={{backgroundColor:''}}>

                            <div><h3 className='MontserratB' >Modifier Une voiture :</h3></div>

                            </div>
                    </div>   
                   
                </div>
            </div>
        </div>

        <Box  className="demandeListScroll Admin" py={6} style={{ backgroundColor:bgColorCard , height: '80vh', borderRadius: '26px 0px 0px 26px' }}>
            
           <Stack maxW={"6xl"} mx={"auto"} mt={6} bgColor={''}>
                <div className="container p-0">
                <form style={{color:'#fff',height:'' }} >
                    <div style={{ borderRadius: '20px', backgroundColor: '' }}>
                        <CarImageUpdate mainImage={data.mainImage} otherImages={data.otherImages} carId={id}/>

                            <div className="card-body">
                                       
                                        <div className="card-text py-2" >


                                    {/* <div className="row py-2" >
                                        
                                        <div className="col-md-12 p-0">
                                            

                                            <p className="text-center MontserratM" style={{color:'#000'}}>Is this a luxury car?</p>
                                            <div className=" row mb-5 " style={{justifyContent:'center'}}>
                                            <input id='isLuxuryCar'
                                                type="checkbox"
                                                checked={data.isLuxuryCar}
                                                onChange={(e)=>handleData({isLuxuryCar: e.target.checked })()}
                                                style={{fontSize:'12px'}}/>
                                            </div>

                                        </div>
                                    </div> */}

                                            <div className=" " style={{backgroundColor: "", alignItems: 'center', justifyContent:'space-between'}} >
                                                <div className=" row g-2 mb-4 ">
                                                    {/* <div className="form-floating col-md-3  ">
                                                        <input type='text' className='form-control' onChange={(e)=>handleData({name: e.target.value})()} value={data.name} 
                                                        placeholder='Marque de la voiture'  style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}/>
                                                        <label className='Montserrat' style={{color: colorText }}>Marque de la voiture</label>
                                                    </div> */}

                                                    <div className="form-floating col-md-3">
                                                        <select
                                                            onChange={(e)=>handleData({name: e.target.value})()}
                                                            value={data.name}
                                                            className="form-select"
                                                            style={{ borderRadius: '20px', backgroundColor: bgColor, color: colorText }}
                                                        >
                                                            <option value="Alfa Romeo">Alfa Romeo</option>
                                                            <option value="Alsvin">Alsvin</option>
                                                            <option value="Aston Martin">Aston Martin</option>
                                                            <option value="Audi">Audi</option>
                                                            <option value="Bentley">Bentley</option>
                                                            <option value="BMW">BMW</option>
                                                            <option value="Bugatti">Bugatti</option>
                                                            <option value="Chevrolet">Chevrolet</option>
                                                            <option value="Chrysler">Chrysler</option>
                                                            <option value="Citroën">Citroën</option>
                                                            <option value="Dacia">Dacia</option>
                                                            <option value="Ferrari">Ferrari</option>
                                                            <option value="Fiat">Fiat</option>
                                                            <option value="Ford">Ford</option>
                                                            <option value="Honda">Honda</option>
                                                            <option value="Hyundai">Hyundai</option>
                                                            <option value="Jaguar">Jaguar</option>
                                                            <option value="Jeep">Jeep</option>
                                                            <option value="Kia">Kia</option>
                                                            <option value="Lamborghini">Lamborghini</option>
                                                            <option value="Land Rover">Land Rover</option>
                                                            <option value="Lexus">Lexus</option>
                                                            <option value="Maserati">Maserati</option>
                                                            <option value="Mazda">Mazda</option>
                                                            <option value="McLaren">McLaren</option>
                                                            <option value="Mercedes">Mercedes</option>
                                                            <option value="Mitsubishi">Mitsubishi</option>
                                                            <option value="Nissan">Nissan</option>
                                                            <option value="Opel">Opel</option>
                                                            <option value="Peugeot">Peugeot</option>
                                                            <option value="Porsche">Porsche</option>
                                                            <option value="Range Rover">Range Rover</option>
                                                            <option value="Renault">Renault</option>
                                                            <option value="Rolls-Royce">Rolls-Royce</option>
                                                            <option value="Saab">Saab</option>
                                                            <option value="Subaru">Subaru</option>
                                                            <option value="Suzuki">Suzuki</option>
                                                            <option value="Tesla">Tesla</option>
                                                            <option value="Toyota">Toyota</option>
                                                            <option value="Volkswagen">Volkswagen</option>
                                                            <option value="Volvo">Volvo</option>
                                                        </select>
                                                        <label className='Montserrat' style={{color: colorText }}>Marque de la voiture</label>
                                                    </div>



                                                    
                            
                                                    <div className="form-floating col-md-3">
                                                        <input type='text' className='form-control' onChange={(e)=>handleData({model: e.target.value})()} value={data.model}
                                                        placeholder='Modèle de la voiture'  style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}/>
                                                        <label className='Montserrat' style={{color: colorText }}>Modèle de la voiture</label>
                                                    </div>

                                                    <div className="form-floating col-md-2">
                                                        <input
                                                            onChange={(e) => handleData({ year: e.target.value })()}
                                                            value={data.year}
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Modèle de la voiture'
                                                             style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}
                                                        />
                                                        <label className='Montserrat' style={{color: colorText }}>Year</label>
                                                    </div>

                                                    <div className="form-floating col-md-2">
                                                        <input
                                                            onChange={(e) => handleData({ totalCar: e.target.value })()}
                                                            value={data.totalCar}
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Nombre de voitures'
                                                             style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}
                                                        />
                                                        <label className='Montserrat' style={{color: colorText }}>Nombre de voitures</label>
                                                    </div>
                                                    
                                                    <div className="form-floating col-md-2">
                                                        <input  type='number' className='form-control' onChange={(e)=>handleData({pricePerDay: parseInt(e.target.value)})()} value={data.pricePerDay}
                                                        placeholder='Prix par jour (DH)' style={{borderRadius:'20px', backgroundColor:'rgba(246, 224, 94, 0.6)', fontWeight:'bold'}}/>
                                                        <label className='Montserrat'>Prix par jour (DH)</label>
                                                    </div>
                                                </div>

                                                <div className="row g-2 mb-4">
                                                   
                                                    <div className="form-floating col-md-3">
                                                        <select
                                                            value={data.carburantType}
                                                            onChange={(e) => handleData({ carburantType: e.target.value })()}
                                                            className="form-select"
                                                             style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}
                                                        >
                                                            <option value="Petrol">Petrol</option>
                                                            <option value="Diesel">Diesel</option>
                                                            <option value="Electric">Electric</option>
                                                            <option value="hybrid">hybrid</option>
                                                        </select>
                                                        <label className='Montserrat' style={{color: colorText }}>Type de carburant</label>
                                                        </div>

                                                        <div className="form-floating col-md-3">
                                                        <select
                                                            value={data.transmission}
                                                            onChange={(e) => handleData({ transmission: e.target.value })()}
                                                            className="form-select"
                                                             style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}
                                                        >
                                                            <option value="Automatic">Automatic</option>
                                                            <option value="Semi Automatic">Semi Automatic</option>
                                                            <option value="Manual">Manual</option>
                                                        </select>
                                                        <label className='Montserrat' style={{color: colorText }}>Transmission</label>
                                                        </div>

                                                        
                                                        <div className="form-floating col-md-3">
                                                        <select
                                                            value={data.categoryType}
                                                            onChange={(e) => handleData({ categoryType: e.target.value })()}
                                                            className="form-select"
                                                             style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}
                                                        >
                                                            <option value="Convertible">Convertible</option>
                                                            <option value="Coupe">Coupe</option>
                                                            <option value="Sedan">Sedan</option>
                                                            <option value="EV">EV</option>
                                                            <option value="Hatchback">Hatchback</option>
                                                            <option value="Luxury">Luxury</option>
                                                            <option value="SUV">SUV</option>
                                                            <option value="Wagon">Wagon</option>
                                                        </select>
                                                        <label className='Montserrat' style={{color: colorText }}>Car Category</label>
                                                        </div>


                                                        <div className="form-floating col-md-3">
                                                            <select
                                                            value={data.isLuxuryCar}
                                                            onChange={(e) => handleData({ isLuxuryCar: e.target.value })()}
                                                            className="form-select"
                                                             style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}
                                                            >
                                                            <option value={true}>Oui</option>
                                                            <option value={false}>Non</option>
                                                            </select>
                                                            <label className='Montserrat' style={{color: colorText }}>Is this a luxury car?</label>
                                                        </div>
                                                </div>

                                                <div className="form-floating mb-3 ">
                                                    <textarea onChange={(e)=>handleData({descriptionFr: e.target.value})()} value={data.descriptionFr} className="form-control border-0" placeholder='Description de la voiture' 
                                                            style={{ backgroundColor:bgColor,borderRadius:'20px',height: '140px', color: colorText}}>                             
                                                        </textarea>
                                                        <label className='Montserrat' style={{color: colorText }}>Description : Francais</label>
                                                </div>

                                                <div className="form-floating mb-3 ">
                                                    <textarea onChange={(e)=>handleData({descriptionEn: e.target.value})()} value={data.descriptionEn} className="form-control border-0" placeholder='Description de la voiture' 
                                                            style={{ backgroundColor:bgColor,borderRadius:'20px',height: '140px', color: colorText}}>                             
                                                        </textarea>
                                                        <label className='Montserrat' style={{color: colorText }}>Description : Anglais</label>
                                                </div>

                                                <div className="form-floating mb-3 ">
                                                    <textarea onChange={(e)=>handleData({descriptionAr: e.target.value})()} value={data.descriptionAr} className="form-control border-0" placeholder='Description de la voiture' 
                                                            style={{ backgroundColor:bgColor,borderRadius:'20px',height: '140px', color: colorText}}>                             
                                                        </textarea>
                                                        <label className='Montserrat' style={{color: colorText }}>Description : Arabe</label>
                                                </div>

                                            </div>

                                            
                                <div className="row g-2 mb-4">
                                    <div className="form-floating col-md-3  ">
                                        <input  type='number' className='form-control' onChange={(e)=>handleData({placesNumber: parseInt(e.target.value)})()} value={data.placesNumber}  
                                        placeholder='Nombre de places'  style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}/>
                                        <label className='Montserrat' style={{color: colorText }}>Nombre de places..</label>
                                    </div>

                                    <div className="form-floating col-md-3">
                                        <select value={data.hasHeater} onChange={(e)=>handleData({hasHeater: e.target.value })()} className="form-select" aria-label="Default select example" 
                                             style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}>
                                            <option value={true}>Oui</option>
                                            <option value={false}>Non</option>
                                        </select>
                                        <label className='Montserrat' style={{color: colorText }}>AC / Heater</label>
                                    </div>
                                    
                                    <div className="form-floating col-md-3">
                                        <input type='number' className='form-control' onChange={(e)=>handleData({lettersPer90Kms: parseFloat(e.target.value)})()} value={data.lettersPer90Kms}
                                        placeholder='Combien de L / 90km'  style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}/>
                                        <label className='Montserrat' style={{color: colorText }}>Combien de L / 90km</label>
                                    </div>

                                    <div className="form-floating col-md-3">
                                        <input type='number' className='form-control'  onChange={(e)=>handleData({doorsNumber: parseInt(e.target.value)})()} value={data.doorsNumber}
                                        placeholder='Nombre de portes'  style={{ borderRadius: '20px', backgroundColor: bgColor , color: colorText}}/>
                                        <label className='Montserrat' style={{color: colorText }}>Nombre de portes</label>
                                    </div>
                                </div>     

                                <div className=" d-flex col-md-12 justify-content-around text-center  py-3 mb-5 mt-1"  
                                    // style={{alignContent:'center',backgroundColor:'#e1e1e1',color:'#000',borderRadius: '20px'}}
                                    style={{ backgroundColor: bgColor, borderRadius: '20px' }}>
                                    <div className=" MontserratM col-md-3 d-flex" style={{width:'30%',backgroundColor:'',justifyContent:'center', alignItems:'center'}}>Fonctionnalités de la voiture</div>
                                        <div className="FonctionnalitésVoiture  row d-flex justify-content-around col-md-9" style={{width:'70%',backgroundColor:''}}>
                                            <div className="div1 col-md-4">
                                                {
                                                    Object.keys(data.functionalities).slice(0, 4).map(key=>(
                                                        <div className="form-check form-switch" key={uuid()}>
                                                            <input checked={data.functionalities[key]} onChange={(e)=>handleData({functionalities: { ...data.functionalities, [key]: e.target.checked }})()} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                                            <label className="form-check-label cairoR" htmlFor="flexSwitchCheckDefault">{functionalitiesTranslationsAr[key]}</label>
                                                        </div>
                                                    ))
                                                }   
                                            </div>

                                            <div className="div2 col-md-4">
                                                {
                                                    Object.keys(data.functionalities).slice(4, 8).map(key=>(
                                                        <div className="form-check form-switch" key={uuid()}>
                                                            <input checked={data.functionalities[key]} onChange={(e)=>handleData({functionalities: { ...data.functionalities, [key]: !data.functionalities[key] }})()} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                                            <label  className="form-check-label cairoR" htmlFor="flexSwitchCheckDefault">{functionalitiesTranslationsAr[key]}</label>
                                                        </div>
                                                    ))
                                                }   
                                            </div>

                                            <div className="div3 col-md-4">
                                                {
                                                    Object.keys(data.functionalities).slice(8, 12).map(key=>(
                                                        <div className="form-check form-switch" key={uuid()}>
                                                            <input checked={data.functionalities[key]} onChange={(e)=>handleData({functionalities: { ...data.functionalities, [key]: !data.functionalities[key] }})()} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                                            <label className="form-check-label  cairoR" htmlFor="flexSwitchCheckDefault">{functionalitiesTranslationsAr[key]}</label>
                                                        </div>
                                                    ))
                                                }   
                                            </div>
                                    </div>
                                </div>

                                    

                            </div>

                            <Button className={''} size={'lg'} colorScheme="teal" width={'100%'} borderRadius={'20px'} onClick={submit}>
                                UPDATE
                            </Button>
                                {/* <div className='form-group botton text-center col-md-12 '>
                                        <button type='submit' className="form-control border-0 py-3 mb-3 fw-bold" 
                                            style={{backgroundColor:'#6415FF',color:'#fff',borderRadius:'20px',cursor:'pointer'}} onClick={submit}>UPDATE</button>
                                </div> */}
                            </div>
                        </div>
                </form>
                </div>
            </Stack>
        </Box>
        </Stack>
    </>
  )
}

export default  UpdateCar 
