import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  isLoggedIn: false,
};

// Create a slice
const isLoggedInSlice = createSlice({
  name: 'isLoggedIn',
  initialState,
  reducers: {
    // Reducer for setting isLoggedIn to true
    login: (state) => {
      state.isLoggedIn = true;
    },
    // Reducer for setting isLoggedIn to false
    logout: (state) => {
      state.isLoggedIn = false;
    },
  },
});

// Export actions
export const { login, logout } = isLoggedInSlice.actions;

// Export reducer
export default isLoggedInSlice.reducer;
