import React, { useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GrLanguage } from 'react-icons/gr';
import { AllemendFlag, FranceFlag, ItalyFlag, MarocFlag, SpainFlag, UsaFlag } from '../../Icons/Icons';

const LanguageSwitcherMobile = ({ onChangeDir, dir }) => {
  const { i18n, t } = useTranslation();
  
  // State to hold the currently selected language
  const [selectedLanguage, setSelectedLanguage] = useState(t('Langue')); // Initialize with "Langue"

  const handleLanguageChange = (lang, label) => {
    i18n.changeLanguage(lang);
    const newDir = lang === 'ar' ? 'rtl' : 'ltr';
    onChangeDir(newDir);
    setSelectedLanguage(label);
  };

  return (
    <Box className="d-flex" color={'#red'}>
      <Menu >
        <MenuButton
          as={Button}
          leftIcon={<GrLanguage fontSize={24} />} // Keep GrLanguage icon
          variant="outline-primary"
          className={dir === 'ltr' ? 'jakR' : 'cairoR'}
        >
          {selectedLanguage} 
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<UsaFlag fontSize={24} />}
            onClick={() => handleLanguageChange('en', 'English')}
          >
            English
          </MenuItem>
          <MenuItem
            icon={<FranceFlag fontSize={24} />}
            onClick={() => handleLanguageChange('fr', 'Français')}
          >
            Français
          </MenuItem>
          <MenuItem
            icon={<MarocFlag fontSize={24} />}
            onClick={() => handleLanguageChange('ar', 'العربية')}
          >
            العربية
          </MenuItem>
         
        </MenuList>
      </Menu>
    </Box>
  );
};

export default LanguageSwitcherMobile;