import React, { useState } from 'react'
import './footer.css'
import LogoIcon from "./sirycar.svg";
import { useNavigate } from 'react-router-dom'
import { Image, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { baseUrlNet } from '../../utils/useRequest';
 const Ftr = ({dir}) => {
  const navigate=useNavigate();
  const toast = useToast();
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const validateEmail = (email) => {
    // Regex for email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubscribe = () => {
    if (email) {
      if (validateEmail(email)) {
        toast({
          title: t('footer.subscribed'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setEmail('');
      } else {
        toast({
          title: t('footer.validEmail'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: t('footer.enterEmail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <>
    <div className="container-fluid" style={{backgroundColor:'#181c2e'}}>
      <div className="container py-5" >
          <div className="row py-4">
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <Image src={`${baseUrlNet}logo/sirycarWhite.svg`}  alt=""  height="45" className="mb-3"/>
              <p  className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{ color: '#fff' }}>
                {t('footer.description')}
              </p>
              {/* <ul className="list-inline mt-4">
                <li className="list-inline-item"><span target="_blank" title="facebook"><i className="fa fa-facebook" style={{color:'#673de6'}}></i></span></li>
                <li className="list-inline-item"><span target="_blank" title="instagram"><i className="fa fa-instagram" style={{color:'#673de6'}}></i></span></li>
                <li className="list-inline-item"><span target="_blank" title="whatsapp"><i className="fa fa-whatsapp" style={{color:'#673de6'}}></i></span></li>
              </ul> */}
            </div>
            <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
            <h6 className={`mb-4 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}  style={{ color: '#fff' }}>
                {t('footer.usefulLinks')}
              </h6>
              <ul className={`list-unstyled mb-0 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}>
                <li className="mb-2">
                  <span onClick={() => navigate("/contact")} className="spanFtr">
                    {t('footer.contactUs')}
                  </span>
                </li>
                <li className="mb-2">
                  <span onClick={() => navigate("/")} className="spanFtr">
                    {t('footer.faqs')}
                  </span>
                </li>
                <li className="mb-2">
                  <span onClick={() => navigate("/")} className="spanFtr">
                    {t('footer.others')}
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
            <h6 className={`mb-4 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{ color: '#fff' }}>
                {t('footer.services')}
              </h6>
              <ul className={`list-unstyled mb-0 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}>
                <li className="mb-2" >
                  <span onClick={() => navigate("/")} className="spanFtr">
                    {t('footer.latestCars')}
                  </span>
                </li>
                <li className="mb-2">
                  <span onClick={() => navigate("/reviews")} className="spanFtr">
                    {t('footer.reviews')}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="spanFtr">
                    {t('footer.termsConditions')}
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 mb-lg-0">
              <h6 className={`mb-4 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{color:'#fff'}}>{t('footer.subscribe')}</h6>
              <p  className={` mb-4 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{color:'#fff'}}>{t('footer.newsletterMessage')}</p>
              <div className="p-1 rounded border">
                <div className="input-group Montserrat">
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('footer.enterEmail')} aria-describedby="button-addon1" className={`form-control border-0 shadow-0 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} />
                  <div className="input-group-append">
                    <button onClick={handleSubscribe} id="button-addon1" type="submit" className="btn btn-link"><i className="fa fa-paper-plane" style={{color:'#673de6'}}></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>
    

   
    </>
  )
}

export default Ftr