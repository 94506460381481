import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Divider,
  Stack,
  HStack,
  useToast,
  FormControl,
  useColorMode,
  InputGroup,
  InputRightElement,
  Input,
  Circle,
  Box,
  SkeletonText,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import "./reactCalendar.css";
import { FaArrowRightLong } from "react-icons/fa6";


const getLocalDateString = (date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

const DateRangeModal = ({ onDateRangeSelect }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [endDateRange, setEndDateRange] = useState(null);
  const [startDateRange, setStartDateRange] = useState(null);
  const todayDate = new Date();

  const handleConfirmDateRange = () => {
    const formattedStartDate = startDateRange
      ? getLocalDateString(startDateRange)
      : null;
    const formattedEndDate = endDateRange
      ? getLocalDateString(endDateRange)
      : null;

    // console.log("formattedStartDate", formattedStartDate);
    // console.log("formattedEndDate", formattedEndDate);

    onDateRangeSelect({
      startDateRange: formattedStartDate,
      endDateRange: formattedEndDate,
    });
    onClose();
  };

  return (
    <>
      <InputGroup onClick={onOpen} size={"sm"}>
        <InputRightElement pointerEvents="none">
          <CalendarIcon color="gray.300" />
        </InputRightElement>
        <Input
          type="text"
          borderRadius={"md"}
          bgColor={colorMode === "dark" ? "whiteAlpha.300" : "white"}
          focusBorderColor="whiteAlpha"
          placeholder="Choisissez un intervalle de dates"
          value={
            endDateRange
              ? `${startDateRange.toLocaleDateString(
                  "fr-FR"
                )} - ${endDateRange.toLocaleDateString("fr-FR")}`
              : ""
          }
          readOnly
          className={"jakR"}
        />
      </InputGroup>
      <Modal
        size={{ base: "sm", md: "4xl"}}
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        blockScrollOnMount={false}
        borderRadius="3xl"
        borderLeft="16px"
        borderColor="teal.400"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={"jakB"}>
            Choisissez une plage de dates
          </ModalHeader>
          <HStack bgColor={''} width={'full'} justifyContent={'space-between'}>
            <Stack w={'50%'}>
              <ModalCloseButton />
              <ModalBody>
                <Stack spacing={-4} >
                  <DatePicker
                    selected={startDateRange}
                    onChange={setStartDateRange}
                    className={"jakR"}
                    name="startDateRange"
                    dateFormat="dd/MM/yyyy"
                    calendarClassName={
                      colorMode === "dark"
                        ? "react-datepicker__calendar--dark"
                        : "react-datepicker__calendar--light"
                    }
                    popperClassName={
                      colorMode === "dark"
                        ? "react-datepicker-popper--dark"
                        : ""
                    }
                    locale={fr}
                    shouldCloseOnSelect={false}
                    maxDate={todayDate}
                    inline
                  />
                </Stack>
                <Divider mt={6} />
              </ModalBody>
            </Stack>
            <Stack w={'50%'}>
              <ModalBody>
                <Stack spacing={-4}>
                  <DatePicker
                    selected={endDateRange}
                    onChange={setEndDateRange}
                    className={"jakR"}
                    name="endDateRange"
                    dateFormat="dd/MM/yyyy"
                    calendarClassName={
                      colorMode === "dark"
                        ? "react-datepicker__calendar--dark"
                        : "react-datepicker__calendar--light"
                    }
                    popperClassName={
                      colorMode === "dark"
                        ? "react-datepicker-popper--dark"
                        : ""
                    }
                    locale={fr}
                    shouldCloseOnSelect={false}
                    maxDate={todayDate}
                    inline
                  />
                </Stack>
                <Divider mt={6} />
              </ModalBody>
            </Stack>
          </HStack>
          <HStack justifyContent={"center"} width={"full"} mt={3}>
            {startDateRange ? (
              <Text p={4} className={"jakM"} textAlign={"center"}>
                {startDateRange.toLocaleDateString("fr-FR")}
              </Text>
            ) : (
              <Box
                mr={4}
                bg={colorMode === "dark" ? "" : "white"}
                width={"100px"}
              >
                <SkeletonText noOfLines={1} skeletonHeight="6" />
              </Box>
            )}
            <Circle
              size="10"
              bg={useColorModeValue("maroon", "whiteAlpha.50")}
            >
               <Icon as={FaArrowRightLong} color="white" />
            </Circle>
            {endDateRange ? (
              <Text p={4} className={"jakM"} textAlign={"center"}>
                {endDateRange.toLocaleDateString("fr-FR")}
              </Text>
            ) : (
              <Box
                ml={4}
                bg={colorMode === "dark" ? "" : "white"}
                width={"100px"}
              >
                <SkeletonText noOfLines={1} skeletonHeight="6" />
              </Box>
            )}
          </HStack>
          <ModalFooter>
            <Button className={"jakR"} variant={"ghost"} mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button
              className={"jakR"}
              colorScheme="orange"
              onClick={handleConfirmDateRange}
            >
              Confirmer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DateRangeModal;
