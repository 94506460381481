import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import colors from '../../../utils/colors';
import { useTranslation } from 'react-i18next';

const CustomAccordion = ({dir}) => {
  const { t } = useTranslation();
  const bgColorCard = useColorModeValue('#fff', colors.buttonBgColor);

  return (
    <Accordion defaultIndex={[0]} bgColor={bgColorCard} borderRadius={'20px'} allowToggle>
      <AccordionItem border="none">
        <h2>
          <AccordionButton
            className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
            _expanded={{ bg: colors.buttonBgColor,  color :'#fff'}}
            _hover={{ bg: colors.buttonBgColor, color: '#fff' }}
            padding="16px"
            borderRadius={'20px'}
          >
            <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px"  >
              {t('faq.questions.0.question')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} fontSize="16px" bgColor={bgColorCard} className={` ${dir === 'ltr' ? '' : 'cairoR'}`}>
          {t('faq.questions.0.answer')}
        </AccordionPanel> 
      </AccordionItem>

      <Divider mb={0} />
      <AccordionItem border="none">
        <h2>
          <AccordionButton
            className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
            expanded={{ bg: colors.buttonBgColor,  color :'#fff'}}
            _hover={{ bg: colors.buttonBgColor, color: '#fff' }}
            padding="16px"
            borderRadius={'20px'}
          >
            <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px">
              {t('faq.questions.1.question')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} fontSize="16px" bgColor={bgColorCard} className={` ${dir === 'ltr' ? '' : 'cairoR'}`}>
          {t('faq.questions.1.answer')}
        </AccordionPanel>
      </AccordionItem>

      <Divider mb={0} />
      <AccordionItem border="none">
        <h2>
          <AccordionButton
            className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
            expanded={{ bg: colors.buttonBgColor,  color :'#fff'}}
            _hover={{ bg: colors.buttonBgColor, color: '#fff' }}
            padding="16px"
            borderRadius={'20px'}
          >
            <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px">
              {t('faq.questions.2.question')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} fontSize="16px" bgColor={bgColorCard} className={` ${dir === 'ltr' ? '' : 'cairoR'}`}>
          {t('faq.questions.2.answer')}
        </AccordionPanel>
      </AccordionItem>

      <Divider mb={0} />
      <AccordionItem border="none">
        <h2>
          <AccordionButton
            className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
            expanded={{ bg: colors.buttonBgColor,  color :'#fff'}}
            _hover={{ bg: colors.buttonBgColor, color: '#fff' }}
            padding="16px"
            borderRadius={'20px'}
          >
            <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px">
              {t('faq.questions.3.question')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} fontSize="16px" bgColor={bgColorCard} className={` ${dir === 'ltr' ? '' : 'cairoR'}`}>
          {t('faq.questions.3.answer')}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CustomAccordion;



// import React from 'react';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionButton,
//   AccordionPanel,
//   AccordionIcon,
//   Box,
//   Divider,
//   useColorModeValue,
// } from '@chakra-ui/react';
// import colors from '../../../utils/colors';

// const CustomAccordion = () => {


//   const bgColorCard = useColorModeValue('#fff', colors.buttonBgColor);
//   return (
//     <Accordion defaultIndex={[0]} bgColor={bgColorCard} borderRadius={'20px'} allowToggle>
//       <AccordionItem border="none">
//         <h2>
//           <AccordionButton className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
//             _expanded={{ bg: colors.yellowPrimary, color: colors.greenDark }}
//             _hover={{ bg: '#eabf0c' }}
//             padding="16px"
//             borderRadius={'20px'}
//           >
//             <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px">
//               What are the necessary documents to rent a car?
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2>
//         <AccordionPanel pb={4} fontSize="16px"   bgColor={bgColorCard}>
//           The necessary documents are usually an identification document (ID card, passport, or driver's license) and a recent proof of address. Please refer to our rental agency's terms and conditions for more information.
//         </AccordionPanel>
//       </AccordionItem>

//       <Divider mb={0} />
//       <AccordionItem border="none">
//         <h2>
//           <AccordionButton className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
//             _expanded={{ bg: colors.yellowPrimary, color: colors.greenDark }}
//             _hover={{ bg: '#eabf0c' }}
//             padding="16px"
//             borderRadius={'20px'}
//           >
//             <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px">
//               What payment methods are accepted?
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2>
//         <AccordionPanel pb={4} fontSize="16px"  bgColor={bgColorCard}>
//           We accept payments by credit card (Visa, Mastercard, American Express) as well as cash payments. Please note that we do not accept checks.
//         </AccordionPanel>
//       </AccordionItem>
      
//       <Divider mb={0} />
//       <AccordionItem border="none">
//         <h2>
//         <AccordionButton className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
//             _expanded={{ bg: colors.yellowPrimary, color: colors.greenDark }}
//             _hover={{ bg: '#eabf0c' }}
//             padding="16px"
//             borderRadius={'20px'}
//           >
//             <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px">
//               Does car rental include insurance?
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2> 
//         <AccordionPanel pb={4} fontSize="16px"  bgColor={bgColorCard}>
//           Yes, all of our rental cars include basic insurance. This covers damages caused to a third party in case of an accident, as well as medical expenses in case of injury. However, please note that insurance coverage may vary depending on the car category you rent and the destination.
//         </AccordionPanel>
//       </AccordionItem>

//       <Divider mb={0} />
//       <AccordionItem border="none">
//         <h2>
//         <AccordionButton className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
//             _expanded={{ bg: colors.yellowPrimary, color: colors.greenDark }}
//             _hover={{ bg: '#eabf0c' }}
//             padding="16px"
//             borderRadius={'20px'}
//           >
//             <Box flex="1" textAlign={dir === 'ltr' ? 'left' : 'right'} fontWeight="bold" fontSize="18px">
//               Is it possible to rent a car in one city and return it in another city?
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2>
//         <AccordionPanel pb={4} fontSize="16px"  bgColor={bgColorCard} borderRadius={'20px'}>
//           Yes, we offer the option to rent a car in one city and return it in another city, also known as a one-way rental. However, additional fees may apply depending on the distance between the two cities and the rental agency.
//         </AccordionPanel>
//       </AccordionItem>
//     </Accordion>
//   );
// };

// export default CustomAccordion;

