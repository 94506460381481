import {
  Button,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  useBreakpointValue,
  Box,
  VStack,
  useColorMode,
  Image,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { useState } from 'react';
import { MdAdd } from "react-icons/md";
import { Link } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import addMembreCover from '../../assets/img/members/addMembreCover.svg'
import addMembreCoverDark from '../../assets/img/members/addMembreCoverDark.svg'
import { baseURL, baseUrlNet } from '../../utils/useRequest';
import axios from 'axios';

const AddChefProjet = () => {

  const [rotationSpeed, setRotationSpeed] = useState(1); 
  const [formattedDate, setFormattedDate] = useState('');
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [loading, setLoading] = useState(false);


  const toast = useToast();


  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    cin: '',
    phone: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };




  


  const bgCard = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const borderColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );

  

const bgStyle = isDarkMode
    ? {
      bg: bgCard,
      boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.02)',
      border: '2px solid',
      backdropFilter: 'saturate(200%) blur(50px)',
      borderColor: borderColor,
    }
    : {bg:'linear-gradient(to right, rgba(239, 97, 16, 0.7), rgba(81, 49, 56, 0.7))' };

 


    
    

    const generatePassword = () => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let newPassword = '';
      for (let i = 0; i < 8; i++) {
        newPassword += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      setGeneratedPassword(newPassword);
      return newPassword; 
    };


    const useChefId = () => {
      const token = useSelector(state => state.token.token.access_token);
      const decodedToken = jwtDecode(token);
      return decodedToken.id;
    };
    const chefId = useChefId();


    


    
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
  
    
      if (!formData.firstname || !formData.lastname || !formData.email || !formData.cin || !formData.phone) {
        toast({
          title: "Champs obligatoires non remplis",
          description: "Veuillez remplir tous les champs obligatoires.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
    
      try {
        const newPassword = generatePassword();
    
        const transformedData = {
          ...formData,
          lastname: formData.lastname.toUpperCase(),
          firstname: formData.firstname.charAt(0).toUpperCase() + formData.firstname.slice(1),
          cin: formData.cin.toUpperCase(),
          password: newPassword,
          chefId: chefId,
          role: 'CPI',
        };
        
        const response = await axios.post(`${baseURL}/auth/register`, transformedData);
        // console.log(transformedData); 
    
        toast({
          title: "Membre enregistré avec succès !",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          cin: '',
          phone: '',
        });
    
    
    
      } catch (error) {
        console.error('Error registering member:', error); 
    
        toast({
          title: "Erreur lors de l'enregistrement du membre.",
          description: "Une erreur s'est produite lors de l'enregistrement du membre. Veuillez réessayer.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    
      } finally {
        setLoading(false);
      }
    };


  return (
    <>

<Stack
        mt={20}
        maxW={'6xl'}
        bg={colorMode === "dark" ? '#242526'  : 'white'}
        borderRadius='20px'
        boxShadow={'lg'}
        p={6}
        mx={'auto'}
        mb={{base: '10'}}
        position={'relative'}
        >
          <Link  to="/dash/liste-membre" aria-label="Home">
                <IconButton position={'absolute'} left={10} top={12} zIndex={3} aria-label="Go back" icon={<ArrowBackIcon boxSize="8"  />} borderRadius="full" boxSize="12" variant={'solid'} />
          </Link>   
          <Stack m={0}  bgColor={colorMode === "dark" ? '#FFBE3F'  : "gray.300"}  zIndex={2} translateY={'-20px'} borderRadius="22px" >
                <Image p={0} mb={-7} src={colorMode === "dark" ? `${baseUrlNet}assets/addMembreCoverDark.svg`  : `${baseUrlNet}assets/addMembreCover.svg`} borderRadius="22px" />
          </Stack> 

        <Box zIndex={1} px="4" pt="7" pb="4" borderRadius='0px 0px 20px 20px' backdropFilter="blur(8px)" boxShadow="lg" mb={6} {...bgStyle}>
        <Stack spacing="4" direction={{ base: 'row', lg: 'row' }} justify="space-between" align={{ base: 'start', lg: 'center' }}>

            <Stack spacing="1" >
                  <Text fontSize={useBreakpointValue({ base: 'xl', lg: '2xl' })} color={"white"} className={'jakB'}>
                      Ajouter directeur d'agence
                  </Text>
                </Stack>
                <IconButton
                  icon={loading ? <Spinner size="sm" /> : <MdAdd fontSize="1.50rem" />}
                  colorScheme={colorMode === 'dark' ? 'orange' : 'whiteAlpha'}
                  variant={"solid"}
                  aria-label="Générer"
                  onClick={handleSubmit}
                  isDisabled={loading}
                />
            </Stack>
        </Box>
 
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <FormControl id="firstname" isRequired>
           <FormLabel className={'jakM'} fontSize={{base:'sm'}}>Prénom</FormLabel>
            <Input className={'jakR'}
                placeholder="Prénom..."
                _placeholder={{ color: 'gray.300' }}
                type="text"
                name="firstname"
                textTransform={'capitalize'}
                value={formData.firstname}
                onChange={handleInputChange}
            />
            </FormControl>

            <FormControl id="lastname" isRequired>
           <FormLabel className={'jakM'} fontSize={{base:'sm'}}>Nom</FormLabel>
            <Input className={'jakR'}
                 placeholder="Nom..."
                _placeholder={{ color: 'gray.300', textTransform:'capitalize' }}
                textTransform={'uppercase'}
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
            />
            </FormControl>

            <FormControl id="cin" isRequired>
           <FormLabel className={'jakM'} fontSize={{base:'sm'}}>N° CNIE</FormLabel>
            <Input className={'jakR'}
                placeholder="N° CNIE..."
                _placeholder={{ color: 'gray.300' }}
                type="text"
                name="cin"
                textTransform={'capitalize'}
                value={formData.cin}
                onChange={handleInputChange}
            />
        </FormControl>

        </Stack>

        <Stack direction={{ base: 'column', lg: 'row' }}>

            <FormControl id="phone" isRequired>
           <FormLabel className={'jakM'} fontSize={{base:'sm'}}>Télephone</FormLabel>
            <Input className={'jakR'}
                placeholder="0612345678"
                _placeholder={{ color: 'gray.300' }}
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
            />
            </FormControl>

            <FormControl id="email" isRequired>
           <FormLabel className={'jakM'} fontSize={{base:'sm'}}>Email</FormLabel>
            <Input className={'jakR'}
                placeholder="example@example.com"
                _placeholder={{ color: 'gray.300' }}
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
            />
            </FormControl>


         

        </Stack>
        
        
            
      </Stack>
    </>
  )
}

export default  AddChefProjet