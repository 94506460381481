import React, { useState, useEffect } from 'react';
import { Flex, Grid, Image, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Header from "./Header";
import ProfileBgImage from "./ProfileBgImage.jpg";
import darkProfileBgImage from "./darkProfileBgImage.jpg";
import { FaCube, FaPenFancy } from 'react-icons/fa';

import { jwtDecode } from 'jwt-decode';
import { BsLockFill, BsMap } from 'react-icons/bs';

import ChangePwd from './ChangePwd';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../redux/slices/userSlice';


 

const AdminProfil = () => {
 
  const userInfo = useSelector(selectUserInfo);
  const textColor = useColorModeValue("gray.700", "white");
  const { colorMode } = useColorMode();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
 
  const [activeTab, setActiveTab] = useState('MOT DE PASSE');

  const [userData, setUserData] = useState(userInfo);

  useEffect(() => {
    // Update userData when userInfo changes
    setUserData(userInfo);
  }, [userInfo]);


  const useRole = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.role.toString();
  };

  const role = useRole();

 

  return (
    <Flex direction='column' mb={{base:'10' ,md:'0'}}>
      <Header
        backgroundHeader={colorMode === "dark" ? darkProfileBgImage : ProfileBgImage}
        backgroundProfile={bgProfile}
        name={`${userData.firstname} ${userData.lastname}`}
        email={userData.email}
        tabs={[
          {
            name: "MOT DE PASSE",
            icon: <BsLockFill w='100%' h='100%' />,
          }
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />


 
       
      {activeTab === 'MOT DE PASSE' && (
      <Grid templateColumns={{ sm: "1fr", xl: "1fr" }} gap='' mt={6} bgColor={''} 
      w={{ sm: "90%", xl: "90%" }} mx={'auto'}>


        <ChangePwd
          title={"Changer de mot de passe"}
          subtitle1={"Votre mot de passe doit contenir au moins 6 caractères ainsi qu’une combinaison de chiffres, de lettres et de caractères spéciaux ( !$@%)."}
        />
      </Grid>)}
      
      
    </Flex>
  );
};

export default AdminProfil;

