import React, { useEffect, useState } from 'react'


import './Dash.css'

import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { Button, Container, Flex, HStack, Icon, Text, useBreakpointValue, useColorMode } from '@chakra-ui/react';

import { Sidebar } from './bar/Sidebar';
import { Navbar } from './bar/Navbar';
import ToggleMode from '../../components/Elements/toggleMode/ToggleMode';







 const Dash = () => {
    const navigate=useNavigate();
  const { colorMode } = useColorMode();

    

    const dispatch = useDispatch();



    const useRole = () => {
        const token = useSelector(state => state.token.token.access_token);
        const decodedToken = jwtDecode(token);
        return decodedToken.role.toString();
      };
    
      const role = useRole();
    
    
      const token = useSelector(state => state.token.token.access_token);

   
   

    



    const goTo=(route)=>{
      return ()=>navigate(route)
       }
  
  
  
    const isDesktop = useBreakpointValue({
      base: false,
      lg: true,
    }) 
  
    const location = useLocation();
    const isProfilePath = location.pathname === '/dash/settings' || location.pathname.startsWith('/dash/project-details') 
    || location.pathname === '/dash/reporting' || location.pathname === '/dash/demands'  || location.pathname === '/dash/booking';
  
    
    

    

      
      
  return (
    <>
        <ToggleMode distFromBottom={'10px'}/>
       <Flex as="section" direction={{ base: 'column', lg: 'row', }} maxH="100vh"  bgColor={colorMode === "dark" ? '#18191A'  : "#fff"} m={0} >
          {/* {isDesktop ? <Sidebar cb={goTo}/> : <Navbar cb={goTo}/>} */}
          {isDesktop ? <Sidebar cb={goTo}/> : <Navbar cb={goTo}/>}
          
          <Container position={'relative'} maxW="7xl"  pt={isProfilePath ? "0" : '8'} flex="1" bgColor={colorMode === "dark" ? '#18191A'  :  "#fff"}  height="100vh" overflowY="auto">
            {/* <Image position={'absolute'} top={0} left={0} src={shapeTop} h={'38px'} w={'38px'} /> */}
            <Outlet></Outlet>
          </Container>

        </Flex>
    </>
  )
}

export default Dash

