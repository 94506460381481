import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import ReviewBox from "./ReviewBox";
import axios from "axios";
import { baseURL } from "../../utils/useRequest";
import { v4 as uuid } from "uuid";
import Loader from "../../components/Elements/loaders/Loader";

export default function ReviewSlider() {
  const [setReviews]=useState(null);

  // useEffect(()=>{
  //   axios.get(`${baseURL}/open/reviews`).then(({data})=>{
  //     setReviews(data);
  //     console.log('review data', data);

  //   }).catch(e=>{
  //     console.log(e)
  //   })
  // }, [setReviews]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [carsList, setCarsList]=useState([]);
  const [isLoading, setLoading]=useState(false);
  const [errorMsg, setErrorMsg]=useState("");

  useEffect(()=>{
    setLoading(true);
    axios.get(`${baseURL}/open/reviews`)
    .then(({data})=>{
      setCarsList(_=>data);
      setLoading(false);
      setErrorMsg("");
      // console.log('review data', data);
    }).catch(e=>{
      console.log(e);
      setErrorMsg(e.response.data.message+", try later")
      setLoading(false);
    })
  }, []);
  return (
    <div>
      <Slider {...settings}>
        { carsList.map(r=>(
          <LogoWrapper className="flexCenter" key={uuid()}>
            <ReviewBox text={r.description} author={r.name}/>
          </LogoWrapper>
        )) }
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
