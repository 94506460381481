import { Box, Button, CircularProgress, CircularProgressLabel, Flex, HStack, Icon, Text, useColorMode, useColorModeValue, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import Card from "../../../../components/Card/Card"
import { useLocation } from "react-router-dom"
import { ThreeLineicon } from "../../../../components/Icons/Icons" 
import colors from "../../../../utils/colors"
import { baseURL } from "../../../../utils/useRequest"
import { useSelector } from "react-redux"
import axios from "axios"


const StatsCongeInDemand = ({selectedDemand}) => {
    const { colorMode } = useColorMode();
    const location = useLocation();
    const isCongePath = location.pathname === '/dash/conge';
    const [percentage, setPercentage] = useState(0);
    const token = useSelector(state => state.token.token.access_token);
    const toast = useToast();


    const isDarkMode = colorMode === 'dark';

    const bgCard = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );

      
  const borderColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );

    

const bgStyle = isDarkMode
    ? {
      bg: bgCard,
      boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.02)',
      border: '2px solid',
      backdropFilter: 'saturate(200%) blur(50px)',
      borderColor: borderColor,
    }
    : { bg: `linear-gradient(126.97deg, ${colors.greenPrimary} 28.26%, rgba(4, 12, 48, 0.5) 91.2%)` };

    const calculateDays = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const differenceInTime = endDate.getTime() - startDate.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      return differenceInDays-1;;
    };
    
    const daysBetween = calculateDays(selectedDemand.startingDate, selectedDemand.endingDate);
    

    const handleApprove = async () => {
      try {
        const response = await axios.put(`${baseURL}/demands/${selectedDemand.id}/approve`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log('response', response);
  
        toast({
          title: "Demande approuvée.",
          description: "La demande a été approuvée avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
  
      } catch (error) {
        console.log('response error', error);
        toast({
          title: "Erreur lors de l'approbation.",
          description: "Il y a eu une erreur lors de l'approbation de la demande.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
  
        console.error("Error approving demand:", error);
      }
    };



    const handleRefuser = async () => {
      try {
        const response = await axios.put(`${baseURL}/demands/${selectedDemand.id}/refuser`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        toast({
          title: "Demande refusée.",
          description: "La demande a été refusée avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
    
      } catch (error) {
        toast({
          title: "Erreur lors du refus.",
          description: "Il y a eu une erreur lors du refus de la demande.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    
        console.error("Error refusing demand:", error);
      }
    };
    

    

    return(
        <>
        {/* Stats */}
        <Card  
          bg={ colorMode === "dark" ?  '#242526' :  '' }
          mt={5}
          display={'relative'}
            >
          <Flex direction='column'>
            
            <Flex direction={{ sm: 'column', md: 'row' }}  justifyContent={'space-between'} >
              <Flex direction='column'  me={{ md: '6px', lg: '52px' }} mb={{ sm: '16px', md: '0px' }} bgColor={''}>
                <Flex
                    
                  direction='column'
                  p='16px'
                  pe={{ sm: '16px', md: '8px', lg: '16px' }}
                  minW={{ sm: '180px', md: '120px', lg: '180px' }}
                  borderRadius='16px'
                  mb='16px'
                  {...bgStyle}>
                  <Text color='gray.400' fontSize='xs' mb='4px' className={'jakR'}>
                    Voiture
                  </Text>
                  <Text color='white' fontSize='sm' fontWeight='bold' className={'jakB'}>
                    {selectedDemand.carDetails.name} {selectedDemand.carDetails.model} {selectedDemand.carDetails.year}
                  </Text>
                </Flex>
                <Flex
                  direction='column'
                  p='16px'
                  pe={{ sm: '16px', md: '8px', lg: '16px' }}
                  minW={{ sm: '140px', md: '120px', lg: '140px' }}
                  borderRadius='16px'
                  {...bgStyle}>
                  <Text color='gray.400' fontSize='xs' mb='4px' className={'jakR'}>
                    Prix du jour
                  </Text>
                  <Text color='white' fontSize='sm' fontWeight='bold' className={'jakB'}>
                    {selectedDemand.carDetails.pricePerDay}
                   
                  </Text>
                </Flex>
              </Flex>
              <Box mx={{ sm: 'auto', md: '0px' }}>
                <CircularProgress
                  size={window.innerWidth >= 1024 ? 160 : window.innerWidth >= 768 ? 140 : 160}
                  value={percentage}
                  thickness={5}
                  color={colorMode === "dark" ? '#929293'  : '#513138'}
                  variant='vision'>
                  <CircularProgressLabel>
                    <Flex direction='column' justify='center' align='center'>
                      <Text
                        className={'jakB'}
                        fontSize={{ md: '34px', lg: '42px' }}
                        fontWeight='bold'
                        mb='1px'>
                        {daysBetween+1}                        
                      </Text>
                      <Text color='gray.400' fontSize='md' className={'jakR'}>
                        Jours
                      </Text>
                    </Flex>
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
            </Flex>

            <HStack  mt={8}>
            <Button w={'100%'} className={'jakR'} colorScheme="red" onClick={handleRefuser}>Refuser</Button>
            <Button w={'100%'} className={'jakR'} colorScheme="yellow" onClick={handleApprove}>Confirmer</Button>
            </HStack>
          </Flex>
        </Card>
        </>
    )
}
export default StatsCongeInDemand
