import React, { useState, useEffect } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Text,
  useColorMode,
  TabIndicator,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
  useToast,
  Stack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import device from './device.png';
import deviceDark from './deviceDark.png';
import DemandsResult from "./demandsResult/DemandsResult";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from '../../../redux/slices/tabSlice'
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { baseURL, baseUrlNet } from "../../../utils/useRequest";
import colors from "../../../utils/colors";


const SortMenu = () => (
  <Menu>
    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} h={"32px"}>
      Les plus recents
    </MenuButton>
    <MenuList>
      <MenuItem>Les plus recents</MenuItem>
      <MenuItem>Les plus pertinents</MenuItem>
    </MenuList>
  </Menu>
);

const DemandsCars = () => {
  const { colorMode } = useColorMode();
  const [selectedTabb, setSelectedTabb] = useState(0);
  const [demands, setDemands] = useState([]);
  const toast = useToast();
  const dispatch = useDispatch();

  const useRole = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.role.toString();
  };

  const role = useRole();

  const useUserId = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.id;
  };

  const userId = useUserId();

  const token = useSelector(state => state.token.token.access_token);

  const etatValues = ["EN_ATTENTE", "APPROUVE", "TERMINE", "REFUSE" ];

  const fetchDemands = async (selectedTabIndex) => {
    const etat = etatValues[selectedTabIndex]; // Map selected tab index to etat value
    try {
      let response; 
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
  
      if (role === "ADMIN" || role === "CPI" || role === "EMP") {
        response = await axios.post(
          `${baseURL}/demands/filter-by-etat`,
          { etat },
          config
        );
      }
  
      // if (role === "CPI" || role === "EMP") {
      //   response = await axios.post(
      //     `/demands/filter-by-etat-and-user`,
      //     { etat, userId }
      //   );
      //}
  
      if (response) {
        setDemands(response.data); 
        // console.log('demand', response.data);

      }
    } catch (error) {
      console.error('Error fetching demands:', error);
    }
  };
  



  useEffect(() => {
    fetchDemands(selectedTabb);
  }, [selectedTabb]);


  
  const handleTabClick = (index) => {
    setSelectedTabb(index);
    dispatch(setSelectedTab(index));
   
    // console.log('index',index);

  };

   

  return (
    <VStack
      mt={4}
      align={"flex-start"}
      spacing={4}
      h={"92vh"}
      mb={6}
      bgColor={''}
    >
      <Stack direction={{base:'column', md:'row'}}
        bgColor={colorMode === "dark" ?  '#202C33'  : colors.sidebarColor}
        p={"20px"}
        borderRadius={"10px"}
        width={"full"}
        border={colorMode === "dark" ? '' : "1px solid #E2E8F0"}
        alignItems={'center'}
      >
          <Text className={"jakR"}>Filtrer par:</Text>
          <Tabs
            size={{base:'sm', md:'md'}}
            position="relative"
            variant="unstyled"
            bgColor={colorMode === 'dark' ? '#242526' : "#F4F8FA"}
            borderRadius={"md"}
            border={colorMode === "dark" ? "1px solid #000" : "1px solid #E2E8F0"}
          >
            <TabList mx={1}>
              <Tab
                className={"jakM"}
                zIndex={2}
                color={colorMode === 'dark' ? "#A0AEC0"  : "#718096"}
                _selected={{ color: colorMode === 'dark' ? "#E2E8F0"  : '#1A202C' }}
                onClick={() => handleTabClick(0)}
              >
                En attente
              </Tab>
              <Tab
                className={"jakM"}
                zIndex={2}
                color={colorMode === 'dark' ? "#A0AEC0"  : "#718096"}
                _selected={{ color: colorMode === 'dark' ? "#E2E8F0"  : '#1A202C' }}
                onClick={() => handleTabClick(1)}
              >
                Approuvé
              </Tab>
              <Tab
                className={"jakM"}
                zIndex={2}
                color={colorMode === 'dark' ? "#A0AEC0"  : "#718096"}
                _selected={{ color: colorMode === 'dark' ? "#E2E8F0"  : '#1A202C' }}
                onClick={() => handleTabClick(2)}
              >
                Terminé
              </Tab>
              <Tab
                className={"jakM"}
                zIndex={2}
                color={colorMode === 'dark' ? "#A0AEC0"  : "#718096"}
                _selected={{ color: colorMode === 'dark' ? "#E2E8F0"  : '#1A202C' }}
                onClick={() => handleTabClick(3)}
              >
                Refusé
              </Tab>
            </TabList>
            <TabIndicator
              zIndex={1}
              mt="-35px"
              height="30px"
              bg={ colorMode === 'dark' ? '#2D3748' : "#FFFFFF" }
              borderRadius="4px"
              boxShadow="0px 4px 5px rgba(0, 0, 0, 0.1)"
            />
          </Tabs>

        {/* <HStack>
          <Text className={"jakR"}>Trier par:</Text>
          <SortMenu />
        </HStack> */}
      </Stack>
      {demands.length === 0 ? (
      <Box 
      border={colorMode === 'dark' ? "#929293 3px dashed" : "#FFDEA4 3px dashed"}
      borderRadius={"10px"}
      bgColor={colorMode === 'dark' ? "" : colors.sidebarColor}
      width={"full"}
        flex={1} 
        display={'grid'}
        placeItems={'center'}
        px={8}
        h={"70vh"}
    >
      
        <Image src={colorMode === 'dark' ? `${baseUrlNet}assets/deviceDark.png` : `${baseUrlNet}assets/device.png`} width={'350px'} />
     
    </Box>

    ) : (
    <Box 
      border={colorMode === 'dark' ? "#929293 3px dashed" : "#FFDEA4 3px dashed"}
      borderRadius={"10px"}
      // bgColor={role === "ADMIN" ? (colorMode === 'dark' ? "" : '') : (colorMode === 'dark' ? "" : '#fff')}

      width={"full"}
      p={0}
    >
     
        <Tabs index={selectedTabb} >
          <TabPanels>
            <TabPanel>
              <DemandsResult demands={demands} tab={selectedTabb} />
            </TabPanel>
            <TabPanel>
              <DemandsResult demands={demands} tab={selectedTabb}/>
            </TabPanel>
            <TabPanel>
              <DemandsResult demands={demands} tab={selectedTabb}/>
            </TabPanel>
            <TabPanel>
              <DemandsResult demands={demands} tab={selectedTabb}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      
    </Box>
    )}

    </VStack>
  );
};

export default DemandsCars;

