import { EditIcon } from "@chakra-ui/icons";
import { Button, Divider, Flex, FormControl, FormLabel, HStack, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useBreakpointValue, useColorMode, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { baseURL } from "../../utils/useRequest";
import axios from "axios";
import Loader from "../../components/Elements/loaders/Loader";
import colors from "../../utils/colors";
import { useTranslation } from "react-i18next";


const ReviewFormModal = ({dir}) => {

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();


    const [data, setData] = useState({
      name: "",
      email: "",
      description: ""
    });
  
    const [loading, setLoading] = useState(false);
  
    const handleMessage = (e) => {
      e.preventDefault();
      if (!data.name || !data.email || !data.description) {
        toast({
          description: t("reviewsPage.modal.toast.warning"),
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      setLoading(true); 
      axios.post(`${baseURL}/open/reviews`, data)
        .then(res => {
          setLoading(false); 
          setData({
            name: "",
            email: "",
            description: ""
          });
          toast({
            description: t("reviewsPage.modal.toast.success"),
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(e => {
          setLoading(false); 
          toast({
            description: t("reviewsPage.modal.toast.error"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  
    const onChange = (attr) => {
      setData(p => ({ ...p, ...attr }));
    };

   
    

    return (
        <>
          <div className={`text-center ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>
            <Button mt={isMobile ? '20px' : ""} onClick={onOpen} className={`btn btn-block py-2 bottonLogin`}
              style={{backgroundColor:'#212529', color: '#6415FF', width: '100%', borderRadius:'12.5px'}} >
              {t("reviewsPage.addFeedbackButton")}
            </Button>
          </div>

            <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} >
                <ModalOverlay bg={`linear-gradient(-45deg, ${colors.buttonBgColor} 0%, rgba(0,0,0,0) 86%)`} backdropFilter='blur(2px) '/>
                <ModalContent>
                    <ModalHeader className={`${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>{t("reviewsPage.modal.title")}</ModalHeader>
                    <ModalCloseButton />
                    <Divider mt={0}/>
                    <ModalBody>
                        

                    
                    <FormControl>
                        <FormLabel  className={`${dir === 'ltr' ? 'jakM' : 'cairoM'}`}>{t("reviewsPage.modal.nameLabel")}</FormLabel>
                        <Input onChange={(e) => setData({ ...data, name: e.target.value })} value={data.name} type='text' 
                         className={`form-control mb-4 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}  
                         placeholder={t("reviewsPage.modal.nameLabel")} style={{ borderRadius: '6px' }} />
                    </FormControl>
                    <FormControl>
                        <FormLabel className={`${dir === 'ltr' ? 'jakM' : 'cairoM'}`}>{t("reviewsPage.modal.emailLabel")}</FormLabel>
                        <Input onChange={(e) => setData({ ...data, email: e.target.value })} value={data.email} type='text' 
                         className={`form-control mb-4 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}   
                         placeholder={t("reviewsPage.modal.emailLabel")} style={{ borderRadius: '6px' }} />
                    </FormControl>
                    <FormControl mt={4}>
                    <FormLabel className={`${dir === 'ltr' ? 'jakM' : 'cairoM'}`}>{t("reviewsPage.modal.reviewLabel")}</FormLabel>
                    <Textarea onChange={(e) => setData({ ...data, description: e.target.value })} value={data.description} 
                       className={`form-control ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}  
                       placeholder={t("reviewsPage.modal.reviewLabel")} style={{ borderRadius: '6px', height: '80px' }}>
                    </Textarea>
                    </FormControl>
             
                        
                    </ModalBody>
                    <Divider/>
                    <ModalFooter>
                    <Button  mr={3} className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`} variant={'ghost'} onClick={onClose}>{t("reviewsPage.modal.cancelButton")}</Button>
                        <Button colorScheme='purple' className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`} onClick={handleMessage}>{t("reviewsPage.modal.addButton")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ReviewFormModal;

