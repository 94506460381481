import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Divider,
    Stack,
    Flex,
    Icon,
    useColorMode,
    Code,
    IconButton,
} from '@chakra-ui/react';
import {IoIosInformationCircleOutline } from 'react-icons/io';
import { FaFacebookMessenger } from 'react-icons/fa6';


const MessageModal = ({ message }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode } = useColorMode();

    
    
    return (
        <>
            <IconButton icon={<FaFacebookMessenger fontSize="1.25rem" />} colorScheme='blue' aria-label="message" onClick={onOpen}/>
            <Modal
                size={{ base: 'sm', md: '3xl', sm: 'sm' }}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                blockScrollOnMount={false}
                borderRadius="3xl"
                borderLeft="16px"
                borderColor="teal.400"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="jakB" textAlign={'center'}>Message</ModalHeader>
                    <ModalCloseButton />
                    <Divider my={0}/>

                    <ModalBody>
                        <Stack mb={5}  spacing={0}>
                            <Code children={message} />
                        </Stack>
                        
                    </ModalBody>

                   
                </ModalContent>
            </Modal>
        </>
    );
};

export default MessageModal;
