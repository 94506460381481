import React, { useState } from 'react';
import Page1 from './Page1';
import { Box, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import colors from '../../../utils/colors';

export default function Form() {
  const colorMode = useColorMode();
  const initialData={
      name: "",
      model: "",
      pricePerDay: 0,
      carburantType: "Essence",
      descriptionFr: "",
      descriptionEn: "",
      descriptionAr: "",
      placesNumber: 0,
      hasHeater: true,
      isLuxuryCar: false,
      lettersPer90Kms: 0,
      doorsNumber: 0,
      functionalities: {
        "All Wheel Drive": false,
        "Audio Input": false,
        "Air Conditioning": false,
        "Heated Seats": false,
        "FM Radio": false,
        "Bluetooth": false,
        "GPS Navigation": false,
        "Sunroof": false,
        "Android Auto": false,
        "Camera": false,
        "Parking Sensors": false,
        "Ventilated Seats": false
      }
  };
  const [pageIndex, setPageIndex]=useState(0);
  const [data, setData]=useState(initialData);

  const handleData=(attrValue)=>{
    return()=>{
      setData((p)=>({...p, ...attrValue}));
    }
  }

  const handleNextPage=(flag)=>{
    return ()=>{
      if(flag<0){
        if(pageIndex>=1)setPageIndex(p=>p-1);
      }else{
        if(pageIndex<=2)setPageIndex(p=>p+1);
      }
    }
  }
  const resetData=()=>{
    setData(initialData);
    // setPageIndex(0);
  }


  const bgColor = useColorModeValue(colors.sidebarColor, '#242526');
  

  return (
        
       <Stack maxW={"7xl"} mx={"auto"}>
           <div className="divBar p-3  mb-3 " style={{backgroundColor: bgColor ,borderRadius:'26px'}}>

           <div  className="  "style={{backgroundColor:'',color:'',height:'',}} >

               <div className="row d-flex justify-content-between" style={{alignItems:'center'}}>

                   <div className=" col-md-4">

                           <div className="" style={{backgroundColor:''}}>

                             <div><h3 className='MontserratB' >Ajouter Une voiture :</h3></div>

                           </div>
                   </div>   
                   
               </div>
           </div>

           </div>

           <Box  className="demandeListScroll Admin" py={6} style={{ backgroundColor: bgColor , height: '80vh', borderRadius: '26px 0px 0px 26px' }}>
            

           <Stack maxW={"6xl"} mx={"auto"} mt={6} bgColor={''}>
            {/* {
              pageIndex === 2 ? <Page2 cb={handleNextPage} handleData={handleData} data={data}/>:
              (pageIndex===3 ? <Page3 cb={handleNextPage} handleData={handleData} data={data} resetData={resetData}/>: 
              <Page1 cb={handleNextPage} handleData={handleData} data={data} resetData={resetData} />)
            } */}

            <Page1 cb={handleNextPage} handleData={handleData} data={data} resetData={resetData} />
            </Stack>
          </Box>
         </Stack>
  )
}
