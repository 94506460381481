

import { Badge, Box, Button, Flex, HStack, Icon, Square, Stack, Text, Tooltip, VStack, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronRight, FaClosedCaptioning, FaInfo, FaRegCircleCheck } from "react-icons/fa6";
import ApprouverConge from "./ApprouverConge";
import RefuserConge from "./RefuserConge";
import AnnulerConge from "./AnnulerConge";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import IconBox from "../../../../components/Icons/IconBox";
import { CarAvIcon, CheckIcon, CloseIcon, HourGlass, TrashIcon } from "../../../../components/Icons/Icons";
import InfoRefusCongeModal from "./InfoRefusCongeModal";
import colors from "../../../../utils/colors";
import axios from "axios";
import { baseURL, baseUrlNet } from "../../../../utils/useRequest";
import InfosModal from "./retourCar/InfosModal";
import InfosTerminerModal from "./terminerCar/InfosTerminerModal";
import InfosRefuserModal from "./refuserCar/InfosRefuserModal";
import { MdOutlineCarRental } from "react-icons/md";


const DemandCongeCard = ({ demand, onDemandClick, isSelected, tab }) => {

  const { colorMode } = useColorMode();
  const token = useSelector(state => state.token.token.access_token);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });


  const handlePlusClick = async () => {
    try {
      const response = await axios.get(`${baseURL}/cars/${demand.carId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
      const carDetails = response.data;
      // console.log('carDetails', carDetails);

      const updatedDemand = {
        ...demand,
        carDetails,
      };
      // console.log('updatedDemand', updatedDemand);
      onDemandClick(updatedDemand);
      setSelectedCardId(demand.id);
    } catch (error) {
      console.error("Error fetching car details:", error);
    }
  };


  useEffect(() => {
    // Call handlePlusClick when demand or token changes
    if (tab === 1) {
      handlePlusClick();
    }
  }, []);

  const useRole = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.role.toString();
  };

  const role = useRole();

  const selectedTab = useSelector(state => state.tab.selectedTab);





  const getIconForTab = (tab) => {
    switch (tab) {
      case 0:
        return <HourGlass h={'24px'} w={'24px'} />;
      case 1:
        return <CarAvIcon h={'24px'} w={'24px'} />;
      case 2:
        return <CheckIcon h={'24px'} w={'24px'}/>;
      case 3:
        return <CloseIcon h={'24px'} w={'24px'}/>;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };


  const formatCreatedAt = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    
    return `Fait le ${day}/${month}/${year} à ${hours}:${minutes}`;
  };


  const style = {
    // borderRight: selectedTab !== 0 && selectedTab !== 2 && (role === "CPI" || role === "EMP") ? "8px" : undefined,
    borderColor: selectedTab !== 0 &&  selectedTab !== 2 && (role === "CPI" || role === "EMP")
      ? colorMode === "dark"
        ? "#3E4C60"
        : "#dc671a"
      : undefined,
  };



  // useEffect(() =>{
  //   console.log('demand', demand);
  // }, [])



  const styleText = {color: isSelected ? '#fff' : (colorMode === "dark" ? 'gray.400' : '#753519')}


  const styleCarBox = {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    overflow: 'clip',
  }
  return ( 
    <Box
      p='24px'
      bg={colorMode === 'dark' ? (isSelected ? '#000' :'#2D3748') : (isSelected ? '#2D3748' : '#f7d616')}
      my='22px'
      borderRadius='10px' 
      position={"relative"}
      {...style}
    >
      <Stack justify='space-between' w='100%' align='flex-start'>
        <Stack direction={{base:'column', lg:'row'}} align='flex-start'>
          <Box bgColor={''} mr={4}>
            <IconBox as='box' h={'45px'} w={'45px'} bg={isSelected ?  (colorMode === "dark" ? colors.greenPrimary : '#f7d616') : (colorMode === "dark" ? '#18191A'  : colors.greenPrimary) }>
              {getIconForTab(selectedTab)}
            </IconBox>
          </Box> 

          <Stack spacing={0} bgColor={''}> 
            <HStack> 
              <Text fontSize='sm' mb='10px' className='jakB' color={isSelected ? '#fff' :   colorMode === "dark" ? '' : '#3f190b'}>
                {demand.name}
              </Text>
              <Badge className='jakB' colorScheme={isSelected ? 'yellow' :   colorMode === "dark" ? '' : 'gray'} >Total: {demand.totalPrice} MAD</Badge>
            </HStack>
            <Text mb={0} color={isSelected ? '#fff' : (colorMode === "dark" ? 'gray.400' : '#753519')} fontSize='xs' className='jakM'>
              Début de location: {window.innerWidth < 768 ? <br /> : null}
              <Text as='span' color={isSelected ? '#fff' : (colorMode === "dark" ? 'gray.500' :'#913f1b')} className='jakR'>
                {formatDate(demand.startingDate)} - {demand.startingTime}
              </Text>
            </Text>
            <Text mb={0} color={isSelected ? '#fff' : (colorMode === "dark" ? 'gray.400' : '#753519')} fontSize='xs' className='jakM'>
              Fin de location:{" "}
              <Text as='span' color={isSelected ? '#fff' : (colorMode === "dark" ? 'gray.500' :'#913f1b')} className='jakR'>
                {formatDate(demand.endingDate)} - {demand.endingTime}
              </Text>
            </Text>
            <Text mb={0} color={isSelected ? '#fff' : (colorMode === "dark" ? 'gray.400' : '#753519')} fontSize='xs' className='jakM'>
               Téléphone:{" "}
              <Text as='span' color={isSelected ? '#fff' : (colorMode === "dark" ? 'gray.500' :'#913f1b')} className='jakR'>
                {demand.phoneNumber}
              </Text>
            </Text>
          </Stack>

         

        </Stack>
        
      </Stack> 


      {/* <Box mb={20} className=' d-flex' style={{styleCarBox, backgroundColor:'',height:'60px',justifyContent:'center', alignItems:'center'}} 
              zIndex={100} mt={'30px'} bgColor={''} >
                           <img src={`${baseUrlNet}${demand.carDetails.mainImage}`} alt="Car" className="img-fluid mb-3" style={{ display: 'inline-block' }} />
              </Box> */}


      <Badge 
        position={'absolute'}
        bottom={3}  textTransform="none" 
        right={14} className='jakB' colorScheme={isSelected ? (colorMode === "dark" ? 'teal' : 'gray') :   colorMode === "dark" ? '' : 'gray'} 
        >
            {formatCreatedAt(demand.createdAt)}
        </Badge>
 

      <Button
        p='0px'
        variant='no-hover'
        // bgColor={colorMode === "dark" ? '#3E4C60' : colors.greenPrimary}
        bg={isSelected ?  (colorMode === "dark" ? colors.greenPrimary : '#f7d616') : (colorMode === "dark" ? '#18191A'  : colors.greenPrimary) }
        onClick={handlePlusClick} 
        position={'absolute'}
        top={0}
        right={0}
        height={'full'}
        borderRadius={'0px 10px 10px 0px'}
      >
        <Flex color='#fff' cursor='pointer' align='center' p='12px'>
          <Icon as={isDesktop ? FaChevronRight : FaChevronDown} color='white' ml='4px' w='14px' h='14px' />
        </Flex>
      </Button>

      {tab === 2  && (
      <InfosTerminerModal isSelected={isSelected} demand={demand} tab={tab}/>
      )}

    {tab === 3  && (
      <InfosRefuserModal isSelected={isSelected} demand={demand} tab={tab}/>
      )}
    </Box>
  );
}

export default DemandCongeCard;

