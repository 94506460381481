import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Flex,
    Icon,
    useColorMode,
    Box,
    useToast,
    useColorModeValue,
    CircularProgressLabel,
    CircularProgress,
    VStack,
} from '@chakra-ui/react';
import {IoIosInformationCircleOutline } from 'react-icons/io';
import { FaArrowRight, FaFacebookMessenger } from 'react-icons/fa6';
import colors from '../../../../../utils/colors';
import { baseURL, baseUrlNet } from '../../../../../utils/useRequest';
import Card from '../../../../../components/Card/Card';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';


    const InfosModal = ({ demand, isSelected, imageMain}) => {
        const [imgCar, setImgCar] = useState(imageMain);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode } = useColorMode();
    const location = useLocation();
    const isCongePath = location.pathname === '/dash/conge';
    const [percentage, setPercentage] = useState(0);
    const token = useSelector(state => state.token.token.access_token);
    const toast = useToast();

    


    const isDarkMode = colorMode === 'dark';

    const bgCard = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );

      
  const borderColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );
 
    

const bgStyle = isDarkMode
    ? {
      bg: bgCard,
      boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.02)',
      border: '2px solid',
      backdropFilter: 'saturate(200%) blur(50px)',
      borderColor: borderColor,
    }
    : { bg: `linear-gradient(126.97deg, ${colors.greenPrimary} 28.26%, rgba(4, 12, 48, 0.5) 91.2%)` };

    const calculateDays = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const differenceInTime = endDate.getTime() - startDate.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      return differenceInDays;
    };
    
    const daysBetween = calculateDays(demand.startingDate, demand.endingDate);
    

    const handleRetour = async () => {
    
      try {
        const response = await axios.put(`${baseURL}/demands/${demand.id}/retour`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        toast({
          title: "Demande terminée.",
          description: "La demande a été marquée comme terminée avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
    
      } catch (error) {
        toast({
          title: "Erreur lors du traitement.",
          description: "Il y a eu une erreur lors du traitement de la demande.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    
        console.error("Error returning demand:", error);
      }
    };


    const formatDate = (dateString) => {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('fr-FR', options);
    };


    
    
    return (
        <>
            <Button
                p='0px'
                variant='no-hover'
                // bgColor={colorMode === "dark" ? '#3E4C60' : colors.greenPrimary}
                bg={isSelected ?  (colorMode === "dark" ? colors.greenPrimary : '#f7d616') : (colorMode === "dark" ? '#18191A'  : colors.greenPrimary) }
                onClick={onOpen}
                position={'absolute'}
                top={0}
                right={0}
                height={'full'}
                borderRadius={'0px 10px 10px 0px'}
            >
                <Flex color='#fff' cursor='pointer' align='center' p='12px'>
                <Icon as={IoIosInformationCircleOutline} color='white' ml='4px' w='24px' h='24px' />
                </Flex>
            </Button>
            <Modal
                size={{ base: 'sm', md: 'xl', }}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                blockScrollOnMount={false}
                borderRadius="4xl"
                borderLeft="16px"
                borderColor="teal.400"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="jakB" textAlign={'center'}></ModalHeader>
                    <ModalCloseButton />
                    {/* <Divider my={0}/> */}
 
                    <ModalBody>
                   
                            <Card mb={4}   bgColor={colorMode === 'dark' ? '#242526' : colors.sidebarColor}  borderRadius={"24px"}>
                                    <Flex direction="column" alignItems="center" rounded="md" px="4" py={5}
                                            position="relative" 
                                            bg={colorMode === "dark" ? ''  : colors.sidebarColor}
                                            borderRadius='20px'>
                                        {imgCar != null && (
                                            <>
                                            <Box position="absolute" inset="0" height="32" bgColor={colorMode === "dark" ?  '#929293'  : colors.greenPrimary} roundedTop="inherit" />
                                            <Box mb={20} className=' d-flex' style={{ backgroundColor:'',height:'100px',justifyContent:'center', alignItems:'center'}} 
                                            zIndex={100} mt={'30px'} bgColor={''} >
                                                        <img src={`${baseUrlNet}${imageMain}`} alt="Car" className="img-fluid " style={{ display: 'inline-block' }} />
                                            </Box>
                                            </>
                                        )}
                                     


                                    <Card  
                                    bg={ colorMode === "dark" ?  '#242526' :  '' }
                                    mt={5}
                                    display={'relative'}
                                        >
                                    <Flex direction='column'>
                                        <Flex direction={{ base: 'column', md: 'row' }} bgColor={''} alignItems={''} justifyContent={{base: 'center', md:'space-between'}} >
                                        <Flex direction='column'  w={{base:"270px", md:"240px"}} me={{ md: '6px', lg: '52px' }} mb={{ base: '16px', md: '0px' }} bgColor={''}>
                                            <Flex
                                                
                                            direction='column'
                                            p='16px'
                                            pe={{ sm: '16px', md: '8px', lg: '16px' }}
                                            minW={{ sm: '180px', md: '120px', lg: '180px' }}
                                            borderRadius='16px'
                                            mb='16px'
                                            {...bgStyle}>
                                            <Text color='gray.400' fontSize='xs' mb='4px' className={'jakR'}>
                                                Le nom complet
                                            </Text>
                                            <Text color='white' fontSize='sm' fontWeight='bold' className={'jakB'}>
                                                {demand.name}
                                            </Text>
                                            </Flex>

                                            <Flex
                                            direction='column'
                                            p='16px'
                                            pe={{ sm: '16px', md: '8px', lg: '16px' }}
                                            minW={{ sm: '180px', md: '120px', lg: '180px' }}
                                            borderRadius='16px'
                                            mb='16px'
                                            {...bgStyle}
                                            >
                                            <Text color='gray.400' fontSize='xs' mb='4px' className={'jakR'}>
                                                Période
                                            </Text>

                                            {/* Flex container for the dates and arrow */}
                                            <Flex alignItems='center' justifyContent='space-between'>
                                                <VStack spacing={0} alignItems={'flex-start'}>
                                                    <Text color='white' fontSize='sm' fontWeight='bold' className={'jakB'}>
                                                        {formatDate(demand.startingDate)}
                                                    </Text>
                                                    <Text color='white' fontSize='xs' className={'jakR'}>
                                                        {demand.startingTime}
                                                    </Text>
                                                </VStack>   

                                                {/* Arrow Icon */}
                                                <Icon as={FaArrowRight} color='white' mx={2} />

                                                <VStack spacing={0} alignItems={'flex-end'}>
                                                    <Text color='white' fontSize='sm' fontWeight='bold' className={'jakB'}>
                                                        {formatDate(demand.endingDate)}
                                                    </Text>
                                                    <Text color='white' fontSize='xs'  className={'jakR'}>
                                                        {demand.endingTime}
                                                    </Text>
                                                </VStack>
                                            </Flex>
                                            </Flex>

                                            <Flex
                                            direction='column'
                                            p='16px'
                                            pe={{ sm: '16px', md: '8px', lg: '16px' }}
                                            minW={{ sm: '140px', md: '120px', lg: '140px' }}
                                            borderRadius='16px'
                                            {...bgStyle}>
                                            <Text color='gray.400' fontSize='xs' mb='4px' className={'jakR'}>
                                                Montant
                                            </Text>
                                            <Text color='white' fontSize='sm' fontWeight='bold' className={'jakB'}>
                                                {demand.totalPrice}
                                            
                                            </Text>
                                            </Flex>

                                        </Flex>
                                        <Box mx={{ base: 'auto', md: '0px' }}>
                                            <CircularProgress
                                            size={window.innerWidth >= 1024 ? 160 : window.innerWidth >= 768 ? 140 : 160}
                                            value={percentage}
                                            thickness={5}
                                            color={colorMode === "dark" ? '#929293'  : '#513138'}
                                            variant='vision'>
                                            <CircularProgressLabel>
                                                <Flex direction='column' justify='center' align='center'>
                                                <Text
                                                    className={'jakB'}
                                                    fontSize={{ md: '34px', lg: '42px' }}
                                                    fontWeight='bold'
                                                    mb='1px'>
                                                    {daysBetween+1}                        
                                                </Text>
                                                <Text color='gray.400' fontSize='md' className={'jakR'}>
                                                    Jours
                                                </Text>
                                                </Flex>
                                            </CircularProgressLabel>
                                            </CircularProgress>
                                        </Box>
                                        </Flex>

                                        <Button mt={8} className={'jakR'} colorScheme="yellow" onClick={handleRetour}>Retour</Button>
                                    </Flex>
                                    </Card>

                                    </Flex>
                                </Card>


                       
                        
                    </ModalBody>


                    {/* <ModalFooter>
                        <Button className={'jakR'} width={'10em'} variant={'solid'} size='sm' mr={3} onClick={onClose}>
                            Annuler
                        </Button>
                        <Button className={'jakR'} colorScheme="red" width={'10em'} size='sm' >
                            Supprimer
                        </Button>
                    </ModalFooter> */}

                   
                </ModalContent>
            </Modal>
        </>
    );
};

export default InfosModal;


