import { Button, Image, Stack, useColorMode } from "@chakra-ui/react"

import circle from './circle.svg'
import moon from './moon.svg'
import sun from './sun.svg'
import colors from "../../../utils/colors"
import { baseUrlNet } from "../../../utils/useRequest"

const ToggleMode = ({distFromBottom}) => {

  const { colorMode, toggleColorMode } = useColorMode();

    return (

        <Stack position='fixed' bottom={distFromBottom}  right='20px' zIndex={8}>
        <Stack width={'52px'} position={'relative'}  borderRadius={'full'}>
        <Button  bgColor={colorMode === 'light' ? '#2d3748' : colors.buttonBgColor}
          onClick={() => toggleColorMode()} 
          display={'inline-flex'} 
          appearance={'none'} 
          alignItems={'center'} 
          justifyContent={'center'} 
          // userSelect={'none'} 
          position={'relative'} 
          whiteSpace={'nowrap'} 
          verticalAlign={'middle'} 
          // outline={'transparent solid 2px'} 
          outlineOffset={'2px'} 
          lineHeight={1.2} 
          borderRadius={'full'}
          transition={'background-color 0.2s, border-color 0.2s, color 0.2s, fill 0.2s, stroke 0.2s, opacity 0.2s, box-shadow 0.2s, transform 0.2s'} // Ajout de la transition ici
          height={'28px'} 
          
        >
          <Image 
            src={colorMode === 'light' ? `${baseUrlNet}assets/moon.svg` : `${baseUrlNet}assets/sun.svg`} // Condition pour afficher soit la lune, soit le soleil en fonction du mode de couleur actuel
            position={'absolute'} 
            top={'5px'} 
            left={colorMode === 'light' ? '6px' : 'unset'} // Position de la lune à gauche et du soleil à droite lorsque vous cliquez sur le bouton
            right={colorMode === 'light' ? 'unset' : '6px'} // Position du soleil à droite lorsque vous cliquez sur le bouton
            zIndex={555} 
            width={'18px'} 
            height={'18px'} 
            pointerEvents={'none'}
          />
          <Image 
            src={`${baseUrlNet}assets/circle.svg`} 
            position={'absolute'} 
            top={'4px'} 
            right={colorMode === 'light' ? '6px' : 'unset'} // Position du cercle à droite lorsque vous cliquez sur le bouton
            left={colorMode === 'light' ? 'unset' : '6px'} // Position du cercle à gauche lorsque vous cliquez sur le bouton
            zIndex={1} 
            width={'18px'} 
            height={'18px'} 
            pointerEvents={'none'}
          />
        </Button>
      </Stack>
    </Stack>

    )
}

export default ToggleMode