import { Box, Text, useColorMode } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import colors from '../../../utils/colors';

const Clock = () => {
  const { colorMode } = useColorMode();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const hours = currentTime.getHours().toString().padStart(2, '0');
  const minutes = currentTime.getMinutes().toString().padStart(2, '0');
  
  return (
    <Box as="section" width={'178px'} height={'163px'} bgColor={''}>
      <Box align={'center'} bgColor={ colorMode === "dark" ? 'white'  : colors.buttonBgColor} py={2} px={{ base: '6', md: '8' }} color="white" borderRadius={'13px 13px 0px 0px'}>
        <Text className={'jakB'} fontSize='sm' color={ colorMode === "dark" ? '#10396D' : colors.selectedBtnText  } fontWeight='bold' mb={0}>
          Heure officielle
        </Text>
      </Box>
      <Box align={'center'} bgColor={ colorMode === "dark" ? '#4A4D4F'  : 'white'} px={{ base: '6', md: '2' }} color="white" borderRadius={'0px 0px 13px 13px '} py={4}>
        <Text className={'jakR'} fontSize='5xl' color={ colorMode === "dark" ? 'white'  : colors.buttonBgColor}  mb={5}>
          {`${hours}:${minutes}`}
        </Text>
      </Box>
    </Box>
  );
};

export default Clock;

