import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Icon, SmallCloseIcon } from "@chakra-ui/icons";
import { BiHomeAlt } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";
import { GrPhone } from "react-icons/gr";
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "./sirycar.svg";
import '../../styles/index.css';
import { Box, Button, IconButton } from "@chakra-ui/react";
import LanguageSwitcherMobile from "../Elements/languageSwitcher/LanguageSwitcherMobile";
import { useTranslation } from "react-i18next";
import { baseUrlNet } from "../../utils/useRequest";

export default function Sidebar({ sidebarOpen, toggleSidebar, handleLanguageChange, dir }) {
  const { t } = useTranslation();

  return (
    <SidebarContainer sidebarOpen={sidebarOpen}>
      <Wrapper className="animate" sidebarOpen={sidebarOpen} style={{ backgroundColor: '#6200EE' }}>
        <SidebarHeader className="flexSpaceCenter" style={{ display: 'flex', alignItems: 'center' }}>
          <Link className="navbar-brand" to="/">
            <img src={`${baseUrlNet}logo/sirycarWhite.svg`} alt="logo" width="109" className="d-inline-block align-text-top" />
          </Link>
          <IconButton icon={<SmallCloseIcon fontSize="2rem" />} colorScheme="whiteAlpha" className="animate pointer"   onClick={() => toggleSidebar(!sidebarOpen)}/>
        </SidebarHeader>

        <Box className="d-flex">
          <Link to={`/`} onClick={() => toggleSidebar(!sidebarOpen)}>
            <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'} leftIcon={<BiHomeAlt fontSize={24} />} variant="outline-primary" color={'#fff'}> {t('home')}</Button>
          </Link>
        </Box>

        <Box className="d-flex" >
          <Link to={`/about`} onClick={() => toggleSidebar(!sidebarOpen)}>
            <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'}  leftIcon={<AiOutlineQuestionCircle fontSize={24} />} variant="outline-primary" color={'#fff'}>{t('aboutNav')}</Button>
          </Link>
        </Box>

        <Box className="d-flex" >
          <Link to={`/reviews`} onClick={() => toggleSidebar(!sidebarOpen)}>
            <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'}  leftIcon={<VscFeedback fontSize={24} />} variant="outline-primary" color={'#fff'}>{t('reviews')}</Button>
          </Link>
        </Box>

        <Box className="d-flex">
          <Link to={`/contact`} onClick={() => toggleSidebar(!sidebarOpen)}>
            <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'}  leftIcon={<GrPhone fontSize={24} />} variant="outline-primary" color={'#fff'}>{t('contact')}</Button>
          </Link>
        </Box>

        <LanguageSwitcherMobile onChangeDir={handleLanguageChange} dir={dir} />
      </Wrapper>

      {/* Remaining 30vh with red background */}
      <RemainingSection sidebarOpen={sidebarOpen} />
    </SidebarContainer>
  );
}
 
const SidebarContainer = styled.div`
  width: 400px;
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  height: 100vh; /* Full height */
  z-index: 9999;
  transition: right 0.3s ease-in-out;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 70vh;
  padding: 0 20px;
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

const RemainingSection = styled.div`
  width: 100%;
  height: 30vh;
  background-color: #181c2e;
  transition: right 0.3s ease-in-out;
`;





// import React from "react";
// import styled from "styled-components";
// import { Link, NavLink } from 'react-router-dom';
// import { Icon } from "@chakra-ui/icons";
// import { BiHomeAlt } from "react-icons/bi";
// import { AiOutlineQuestionCircle } from "react-icons/ai";
// import { VscFeedback } from "react-icons/vsc";
// import { GrPhone } from "react-icons/gr";
// import CloseIcon from "../../assets/svg/CloseIcon";
// import LogoIcon from "./sirycar.svg";
// import '../../styles/index.css';
// import { Box, Button } from "@chakra-ui/react";
// import LanguageSwitcherMobile from "../Elements/languageSwitcher/LanguageSwitcherMobile";
// import { useTranslation } from "react-i18next";

// export default function Sidebar({ sidebarOpen, toggleSidebar, handleLanguageChange, dir }) {

//   const { t } = useTranslation();


  
//   return (
//     <>
//     <Wrapper className="animate" sidebarOpen={sidebarOpen} style={{ backgroundColor: '#6200EE' }}>
//       <SidebarHeader className="flexSpaceCenter" style={{ display: 'flex', alignItems: 'center' }}>
//         <Link className="navbar-brand" to="/">
//           <img src={LogoIcon} alt="logo" width="109" className="d-inline-block align-text-top" />
//         </Link>
//         <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//           <Icon as={CloseIcon} boxSize={10} color={'white'} />
//         </CloseBtn>
//       </SidebarHeader>


// <Box className="d-flex">
//         <Link to={`/`} onClick={() => toggleSidebar(!sidebarOpen)}>
//           <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'} leftIcon={<BiHomeAlt fontSize={24} />} variant="outline-primary" color={'#fff'}> {t('home')}</Button>
//         </Link>
//       </Box>

//       <Box className="d-flex" >
//         <Link to={`/about`} onClick={() => toggleSidebar(!sidebarOpen)}>
//           <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'}  leftIcon={<AiOutlineQuestionCircle fontSize={24} />} variant="outline-primary" color={'#fff'}>{t('aboutNav')}</Button>
//         </Link>
//       </Box>

//       <Box className="d-flex" >
//         <Link to={`/reviews`} onClick={() => toggleSidebar(!sidebarOpen)}>
//           <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'}  leftIcon={<VscFeedback fontSize={24} />} variant="outline-primary" color={'#fff'}>{t('reviews')}</Button>
//         </Link>
//       </Box>
      

//       <Box className="d-flex">
//         <Link to={`/contact`} onClick={() => toggleSidebar(!sidebarOpen)}>
//           <Button className={dir === 'ltr' ? 'jakR' : 'cairoR'}  leftIcon={<GrPhone fontSize={24} />} variant="outline-primary" color={'#fff'}>{t('contact')}</Button>
//         </Link>
//       </Box>

//         <LanguageSwitcherMobile onChangeDir={handleLanguageChange} dir={dir} />

//     </Wrapper>
//     </>
//   );
// }

// const Wrapper = styled.nav`
//   width: 400px;
//   height: 70vh;
//   position: fixed;
//   top: 0;
//   padding: 0 30px;
//   right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
//   z-index: 9999;
//   @media (max-width: 400px) {
//     width: 100%;
//   }
// `;
// const SidebarHeader = styled.div`
//   padding: 20px 0;
// `;
// const CloseBtn = styled.button`
//   border: 0px;
//   outline: none;
//   background-color: transparent;
//   padding: 10px;
// `;
// const UlStyle = styled.ul`
//   li {
//     margin: 20px 0;
//   }
// `;



