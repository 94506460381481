import "./styles.css";
import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carroussel from "./Carroussel";
import { useBreakpointValue } from "@chakra-ui/react";

export default function Swiper({ products }) {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <div className="carousel">
      <Carroussel
        cards={products.map(p => (
          {
            key: uuidv4(),
            content: (
              <Card
                highDemand={p.highDemand}
                outOfStock={p.outOfStock}
                mainImage={p.mainImage}
                id={p.id}
                hasHeater={p.hasHeater}
                name={p.name}
                lettersPer90Kms={p.lettersPer90Kms}
                pricePerDay={p.pricePerDay}
                placesNumber={p.placesNumber}
                model={p.model}
              />
            )
          }
        ))}
        height="450px"
        width="90%"
        margin="auto"
        offset={2}
        showArrows={isMobile}
      />
    </div>
  );
}

