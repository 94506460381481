import React from 'react'
import NoCrashIcon from '@mui/icons-material/NoCrash';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { MdCalendarMonth, MdOutlineCarRental, MdOutlineNoCrash } from 'react-icons/md';
import IconBox from '../../components/Icons/IconBox';

const Steps = ({scrollToCarsListCallback, dir}) => {
    const { t } = useTranslation();
    const isDesktop = useBreakpointValue({ base: false, lg: true });


  return (
    <>

    <div className="container" style={{paddingTop:'20px'}}>
    <div className="div col-md-12 text-center my-5">
          <h1 className={`font20 Bold ${dir === 'ltr' ? '' : 'cairoM'}`} style={{ color: '#6415FF' }}>
            {t('steps.howItWorks')}
          </h1>
          <h1 className={`${dir === 'ltr' ? 'MontserratK' : 'cairoB'}` } >{t('steps.easySteps')}</h1>
          {isDesktop &&
            <p className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>
              {t('steps.descriptionPart1')}<br />{t('steps.descriptionPart2')}
            </p>
            }
        </div>


        <div className="container m-0 row d-flex justify-content-between  text-center " style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", gap: "1rem"}}  >
        <div className="card col-md-4 mb-4 border-0 shadow" style={{margin: "auto", width: '22rem', height:'20rem',borderRadius: '10px'}}>
            <div className="card-body text-center" style={{paddingTop:'20px',}}>

               

            <Flex justifyContent="center" alignItems="center" mb={4}>
              <IconBox as="box" p={3} rounded="full" h="50px" w="50px" bgColor={'#6415FF'}>
                <MdOutlineNoCrash color={'#fff'}  fontSize={40} />
              </IconBox>
            </Flex>
                
              

                
                <h4 className={`card-title mb-4 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>{t('steps.chooseCarTitle')}</h4>
                <h6 className={`card-text mb-5 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{t('steps.chooseCarDescription')}</h6>
                <h6
                onClick={scrollToCarsListCallback}
                className={`card-text ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}
                style={{ color: '#6415FF', cursor: 'pointer' }}
                >
                {t('steps.carList')}
                </h6>
            </div>
        </div>


        <div className="card col-md-4 mb-4 border-0 shadow" style={{margin: "auto", color:'white',width: '22rem', height:'20rem',borderRadius: '10px',backgroundColor:'#6415FF'}}>
            <div className="card-body text-center" style={{paddingTop:'20px',}}>
            

                <Flex justifyContent="center" alignItems="center" mb={4}>
                <IconBox as="box" p={3} rounded="full" h="50px" w="50px" bgColor={'#fff'}>
                    <MdCalendarMonth color={'#6415FF'}  fontSize={40} />
                </IconBox>
                </Flex>

                

                <h4 className={`card-title mb-4 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{ color: '#fff' }}>
                {t('steps.bookDateTitle')}
                </h4>
                <h6 className={`card-text mb-5 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{ color: '#fff' }}>
                {t('steps.bookDateDescription')}
                </h6>
                <h6 className={`card-text ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} style={{ color: '#fff' }}>
                {t('steps.selectDate')}
                </h6>
                    

                
            </div>
        </div>


        <div className="card col-md-4 mb-4 border-0 shadow" style={{margin: "auto", width: '22rem', height:'20rem',borderRadius: '10px'}}>
            <div className="card-body text-center" style={{paddingTop:'20px',}}>
                
            <Flex justifyContent="center" alignItems="center" mb={4}>
              <IconBox as="box" p={3} rounded="full" h="50px" w="50px" bgColor={'#6415FF'}>
                <MdOutlineCarRental color={'#fff'} fontSize={40} />
              </IconBox>
            </Flex>

             

                <h4 className={`card-title mb-4 ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`}>{t('steps.enjoyCarTitle')}</h4>
                <h6 className={`card-text mb-5 ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>{t('steps.enjoyCarDescription')}</h6>
                <Link to="/reviews">
                <h6 className={`card-text ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`} style={{ color: '#6415FF' }}>
                    {t('steps.reviewUs')}
                </h6>
                </Link>
                
            </div>
        </div>
    </div>

    </div>


    </>
  )
}

Steps.defaultProps={
    scrollToCarsListCallback: ()=>{}
}
export default Steps

