import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import '../Dash.css'
import CardAdmin from './CardAdmin';
import { v4 as uuid } from "uuid";
import { baseURL } from '../../../utils/useRequest';
import axios from 'axios';
import './listCar.css'
import { useSelector } from 'react-redux';
import { Box, Input, InputGroup, InputRightElement, SimpleGrid, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import colors from '../../../utils/colors';
import { Search2Icon } from '@chakra-ui/icons';




 const ListCar = () => {

    const myStyle = {
        // transform: "scale(0.90)",
    };

    const [carsList, setCarsList]=useState([]);
    // const [isLoading, setLoading]=useState(false);
    const [errorMsg, setErrorMsg]=useState("");
    const [filteredList, setFiltredList]=useState([]);
    const [searchTerm, setSearchTerm]=useState("");
    const [type, setType]=useState("success");
    const { colorMode } = useColorMode();

    const token = useSelector(state => state.token.token.access_token);


    useEffect(()=>{
      setFiltredList(carsList.filter(c=>(
        c.name.toLowerCase().includes(searchTerm) || c.model.toLowerCase().includes(searchTerm)
      )))
    }, [searchTerm, carsList]);
    
    const deleteCarCallback=(id)=>{
      setCarsList(_=>_.filter(c=>c.id!==id));
    }

    useEffect(()=>{
      // setLoading(true);
      axios.get(`${baseURL}/cars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({data})=>{
        setFiltredList(_=>data);
        setCarsList(_=>data);
        // console.log('car list', data);
        // setLoading(false);
        setErrorMsg("");
      }).catch(e=>{
        console.log(e);
        setErrorMsg(e.response.data.message+", try later")
        // setLoading(false);
        setType("error");
      })
    }, []);

    
    const bgColorInput = useColorModeValue('#eee', '#3a3b3c');

    return (
      <>
            <Stack maxW={"7xl"} mx={"auto"}>
                <div className="divBar p-3  mb-3 " style={{backgroundColor: colorMode === "dark" ? '#242526' : colors.sidebarColor,borderRadius:'26px'}}>

                <div  className="  "style={{backgroundColor:'',color:'',height:'',}} >

                    <div className="row d-flex justify-content-between" style={{alignItems:'center'}}>

                        <div className=" col-md-4">

                                <div className="" style={{backgroundColor:''}}>

                                  <div><h3 className='MontserratB' style={{color: colorMode === "dark" ? '#fff' :  '#181c2e'}}>La Liste des voitures :</h3></div>

                                </div>
                        </div>   
                        <div className="col-md-4">
                            <div className="" style={{backgroundColor:''}}>
                              <InputGroup p={0}>
                                    <InputRightElement pointerEvents='none'>
                                        <Search2Icon color='gray.300' />
                                    </InputRightElement>
                                    <Input onChange={(e)=>setSearchTerm(e.target.value)} type='text' bgColor={bgColorInput} placeholder="Search Car" />
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                </div>

                </div>


                <Box  className="demandeListScroll Admin" py={6} style={{ backgroundColor: colorMode === "dark" ? '#242526' : colors.sidebarColor , height: '80vh', borderRadius: '26px 0px 0px 26px' }}>
                    <SimpleGrid columns={[1, 2, 3]} spacing={5} gap={8} mx={'auto'} bgColor={''} justifyItems="center" alignItems="center">
                        {filteredList.map(c => (
                            <Box key={uuid()} style={myStyle}>
                                <CardAdmin
                                    highDemand={c.highDemand}
                                    outOfStock={c.outOfStock}
                                    mainImage={c.mainImage}
                                    hasHeater={c.hasHeater}
                                    name={c.name}
                                    placesNumber={c.placesNumber}
                                    pricePerDay={c.pricePerDay}
                                    id={c.id}
                                    lettersPer90Kms={c.lettersPer90Kms}
                                    model={c.model}
                                    totalCar={c.totalCar}
                                    deleteCarCallback={deleteCarCallback}
                                />
                            </Box>
                        ))}
                    </SimpleGrid>
                </Box>
                
              </Stack>
            
          
      </>
  )
}


export default ListCar


