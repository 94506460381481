import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { baseURL, baseUrlNet } from '../../../utils/useRequest'; 
import axios from 'axios';
import DeleteCar from './DeleteCar';
import { Box, Button, Heading, IconButton, Spinner, Text, Tooltip, useBreakpointValue, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react';

import IconBox from "../../../components/Icons/IconBox";

  import { SiFireship } from "react-icons/si";
  import { GiCheckMark } from "react-icons/gi";
import { useSelector } from 'react-redux';
import colors from '../../../utils/colors';
import { TbHomeCheck, TbHomeX } from 'react-icons/tb';

const CardAdmin = ({id, pricePerDay,totalCar, lettersPer90Kms, name, placesNumber, hasHeater, mainImage, model,outOfStock, highDemand, deleteCarCallback}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [type, setType]=useState("success");
  const [toggleStock, setToggleStock]=useState(outOfStock);
  const [toggleHighDemand, setToggleHighDemand]=useState(highDemand);
  const [loading, setLoading]=useState(true);
  const toast = useToast();
  const colorMode = useColorMode();


  const [deletingData, setDeletingData]=useState({
    errorMsg: "",
    loading: false,
  });

  const styleCarBox = {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    overflow: 'clip',
  }
  
const token = useSelector(state => state.token.token.access_token);



  const handleToggleOutOfStock = (id) => {
    setToggleStock(!toggleStock); // Change here
    axios.put(`${baseURL}/cars/${id}/toggleOutOfStock`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
        toast({
            description: response.data,
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    })
    .catch((error) => {
        toast({
            description: "Une erreur s'est produite, veuillez réessayer plus tard.",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    });
  };

  const handleToggleHighDemand = (id) => {
    setToggleHighDemand(!toggleHighDemand); // Change here
    axios.put(`${baseURL}/cars/${id}/toggleHighDemand`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
        toast({
            description: response.data,
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    })
    .catch((error) => {
        toast({
            description: "Une erreur s'est produite, veuillez réessayer plus tard.",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    });
  };
  
  

  const bgColorCard = useColorModeValue('#fff', '#3a3b3c');


  return (
    <>
          <Box  className=' text-center shadow' style={{position:'relative', height: '490px', backgroundColor: bgColorCard, width: isMobile ? "80vw": "320px",borderRadius:'25px', marginBottom: "1rem"}}>
          

              <IconBox pointer as='box' p={3} cursor="pointer"  variant={'solid'} rounded={'full'} h={"55px"} w={"55px"} colorScheme={'red'} bg={colors.yellowDark} style={{position:'absolute' , top:'-15px',right:'-15px'}}   onClick={() =>  handleToggleHighDemand(id)}>
                {toggleHighDemand ? (
                    <SiFireship h={"16px"} w={"16px"} fontSize={'25px'} color={'orange'} />
                  
                ) : (
                  <GiCheckMark h={"26px"} w={"26px"} fontSize={'25px'} color={'white'}/>
                )}     
                </IconBox>  


                
            <IconBox pointer as='box' p={3} cursor="pointer"  variant={'solid'} rounded={'full'} h={"55px"} w={"55px"} style={{position:'absolute' , top:'50px',right:'-15px'}} bg={colors.yellowSecondary}   onClick={() =>  handleToggleOutOfStock(id)}>
                {toggleStock ? (
                    <TbHomeX h={"16px"} w={"16px"} fontSize={'30px'} color={'white'} />
                  
                ) : (
                  <TbHomeCheck h={"26px"} w={"26px"} fontSize={'30px'} color={'white'}/>
                )}
              </IconBox>
 

             
          <div className="d-flex " style={{alignItems: 'center', justifyContent: 'center'}}>
            <h2 className='mt-4 ' style={{color:colors.greenPrimary ,marginRight:'10px',fontFamily:'Montserrat',fontSize:'30px',fontWeight:'600'}}>{Math.floor(pricePerDay)} Dhs</h2>
            <h6 className='mt-4' style={{color:'#A7A7A7'}}> / day</h6>
          </div>

          

          <Tooltip  hasArrow className='Montserrat' placement='bottom' label={`${name}`} zIndex={89} colorScheme='yellow'>
            <Heading  as={'h3'} noOfLines={1} className='mb-5' style={{fontFamily:'Montserrat',fontSize:'28px',fontWeight:'600', }}>{`${model}`}</Heading>
          </Tooltip>

                  <div className='mb-4 d-flex' style={{styleCarBox, backgroundColor:'',height:'190px',justifyContent:'center', alignItems:'center'}} >
                           <img src={`${baseUrlNet}${mainImage}`} alt="Car" className="img-fluid mb-3" style={{ display: 'inline-block' }} />
                  </div>

          <div className="row " >
            <ul className='d-flex col-md-12 mt-1 mb-0 justify-content-around' style={{fontFamily:'MontserratM',fontSize:'13px',fontWeight:'500'}}>
                <li className='' >Seats : {placesNumber} </li>
                <li className='jakB' color={colors.yellowLight} >x{totalCar} (voiture) </li>
                {/* <li  >AC / {hasHeater ? "Heater": "No Heater"}</li> */}
                <li>{lettersPer90Kms} L / 90km</li>

            </ul>
          </div>

          <hr className=""/>
                  
                  <div className="d-flex" style={{alignItems: 'center', justifyContent:'space-around', flexWrap: "wrap", gap: "1rem"}}>
                      <div className='botton text-center'>
                        <Link to={`/dash/update-car/${id}`}>
                          <button className="btn btn-block p-2" 
                            style={{backgroundColor: colors.greenPrimary,color:'#fff',borderRadius:'20px',width:'8rem',fontSize:'13px',fontFamily:'Montserrat'}}>UPDATE</button>
                        </Link>
                      </div>

                      <DeleteCar  name={name} model={model} id={id} deleteCarCallback={deleteCarCallback}/>
                      
                  </div>
        </Box>
   
     </>
  )
}



export default CardAdmin