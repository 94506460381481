import {
  AspectRatio,
  Box,
  Image,
  Skeleton,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  IoChevronBackOutline,
  IoChevronDownOutline,
  IoChevronForwardOutline,
  IoChevronUpOutline,
} from 'react-icons/io5';
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from './Carousel';
import colors from '../../../utils/colors';

export const Gallery = (props) => {
  const { images, dir, aspectRatio = 4 / 3, totalImages, rootProps } = props;
  const [index, setIndex] = React.useState(0);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slidesPerView = useBreakpointValue({
    base: 3,
    md: totalImages >= 4 ? 4 : 2,
  });
  const isVertical = useBreakpointValue({
    base: false,
    md: true,
  });
  const [ref, slider] = useCarousel({
    vertical: isVertical,
    slides: {
      perView: slidesPerView,
      spacing: useBreakpointValue({
        base: 16,
        md: 24,
      }),
    },
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
  });

  const bgColorImg = useColorModeValue('#eee', colors.buttonBgColor);

  return (
    // Force the direction to be ltr for this component
    <Box dir="ltr">
      <Stack
        spacing={{
          base: '4',
          md: '6',
        }}
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
        {...rootProps}
      >
        <Stack
          spacing={{
            base: '4',
            md: '6',
          }}
          direction={{
            base: 'row',
            md: 'column',
          }}
          minW="24"
          maxH="md"
        >
          <CarouselIconButton
            onClick={() => slider.current?.prev()}
            icon={isVertical ? <IoChevronUpOutline /> : <IoChevronBackOutline />}
            aria-label="Previous slide"
            disabled={currentSlide === 0}
          />

          <Carousel
            ref={ref}
            direction={{
              base: 'row',
              md: 'column',
            }}
            width="full"
            alignItems="center"
          >
            {images.map((image, i) => (
              <CarouselSlide key={i} onClick={() => setIndex(i)} cursor="pointer">
                <AspectRatio
                  ratio={aspectRatio}
                  transition="all 200ms"
                  opacity={index === i ? 1 : 0.4}
                  _hover={{
                    opacity: 1,
                  }}
                >
                  <Image src={image.src} objectFit="cover" alt={image.alt} fallback={<Skeleton />} />
                </AspectRatio>
              </CarouselSlide>
            ))}
          </Carousel>

          <CarouselIconButton
            onClick={() => slider.current?.next()}
            icon={isVertical ? <IoChevronDownOutline /> : <IoChevronForwardOutline />}
            aria-label="Next slide"
            disabled={currentSlide + Number(slidesPerView) === images.length}
          />
        </Stack>

        <Box
          className="divImage d-flex text-center card border-0 p-3"
          height={{ base: '250px', lg: '450px' }}
          overflow={'hidden'}
          style={{
            width: '100%',
            backgroundColor: bgColorImg,
            borderRadius: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {images && (
            <Image
              src={images[index].src}
              className="img-fluid"
              style={{ display: 'inline-block' }}
              alt={images[index].alt}
              fallback={<Skeleton />}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};




// import { AspectRatio, Box, Image, Skeleton, Stack, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
// import * as React from 'react'
// import {
//   IoChevronBackOutline,
//   IoChevronDownOutline,
//   IoChevronForwardOutline,
//   IoChevronUpOutline,
// } from 'react-icons/io5'
// import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from './Carousel'
// import colors from '../../../utils/colors'

// export const Gallery = (props) => {
//   const { images, dir, aspectRatio = 4 / 3, totalImages, rootProps } = props
//   const [index, setIndex] = React.useState(0)
//   const [currentSlide, setCurrentSlide] = React.useState(0)
//   const slidesPerView = useBreakpointValue({
//     base: 3,
//     md: totalImages >= 4 ? 4 : 2,
//     // md: 4,
//     // if nmb of images > 4 => md : 5 else md : 2
//   })
//   const isVertical = useBreakpointValue({
//     base: false,
//     md: true,
//   })
//   const [ref, slider] = useCarousel({
//     vertical: isVertical,
//     slides: {
//       perView: slidesPerView,  
//       spacing: useBreakpointValue({
//         base: 16,
//         md: 24,
//       }),
//     },
//     slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
//   })


//   const bgColorImg = useColorModeValue('#eee', colors.buttonBgColor);
//   return (
//     <Stack
//       spacing={{
//         base: '4',
//         md: '6',
//       }}
//       direction={{
//         base: 'column-reverse',
//         md: 'row',
//       }}
//       {...rootProps}
//     >
//       <Stack
//         spacing={{
//           base: '4',
//           md: '6',
//         }}
//         direction={{
//           base: 'row',
//           md: 'column',
//         }}
//         minW="24"
//         maxH="md"
     
//       >
//         <CarouselIconButton
//           onClick={() => slider.current?.prev()}
//           icon={isVertical ? <IoChevronUpOutline /> : <IoChevronBackOutline />}
//           aria-label="Previous slide"
//           disabled={currentSlide === 0}
//         />

//         <Carousel
//           ref={ref}
//           direction={{
//             base: 'row',
//             md: 'column',
//           }}
//           width="full"
//           alignItems="center"
//         >
//           {images.map((image, i) => (
//             <CarouselSlide key={i} onClick={() => setIndex(i)} cursor="pointer">
//               <AspectRatio
//                 ratio={aspectRatio}
//                 transition="all 200ms"
//                 opacity={index === i ? 1 : 0.4}
//                 _hover={{
//                   opacity: 1,
//                 }}
//               >
//                 <Image src={image.src} objectFit="cover" alt={image.alt} fallback={<Skeleton />} />
//               </AspectRatio>
//             </CarouselSlide>
//           ))}
//         </Carousel>

//         <CarouselIconButton
//           onClick={() => slider.current?.next()}
//           icon={isVertical ? <IoChevronDownOutline /> : <IoChevronForwardOutline />}
//           aria-label="Next slide"
//           disabled={currentSlide + Number(slidesPerView) === images.length}
//         />
//       </Stack>
     
//        {/* <AspectRatio ratio={aspectRatio}  flex={1} borderRadius="2rem" p={8} bgColor="">
//       {images &&  (
//         <Image
//           src={images[index].src}
//           objectFit="contain"
//           alt={images[index].alt}
//           fallback={<Skeleton />}
//         />
//       )}
//     </AspectRatio> */}

//     <Box className="divImage d-flex text-center card border-0 p-3 " height={{base:'250px', lg:'450px'}} overflow={'hidden'}
//      style={{ width: '100%', backgroundColor: bgColorImg, borderRadius: '20px',justifyContent: 'center', alignItems: 'center' }}>
//         {images &&  (
//            <Image src={images[index].src} className="img-fluid" style={{ display: 'inline-block' }}
//               alt={images[index].alt}
//               fallback={<Skeleton />} />
//         )}
//     </Box>
//     </Stack>
//   )
// }
