

import { Badge, Box, Button, Flex, HStack, Icon, Image, Square, Stack, Text, Tooltip, VStack, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import IconBox from "../../../../../components/Icons/IconBox";
import { CheckIcon, CloseIcon, HourGlass, TrashIcon } from "../../../../../components/Icons/Icons";
import colors from "../../../../../utils/colors";
import axios from "axios";
import { baseURL, baseUrlNet } from "../../../../../utils/useRequest";
import { IoIosInformationCircleOutline } from "react-icons/io";
import InfosModal from "./InfosModal";


const RetourCarCard = ({ demand, onDemandClick, isSelected, tab }) => {

  const { colorMode } = useColorMode();
  const token = useSelector(state => state.token.token.access_token);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [imageMain, setImageMain] = useState('');


  const handlePlusClick = async () => {
    try {
      const response = await axios.get(`${baseURL}/cars/${demand.carId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
      const carDetails = response.data;
      // console.log('carDetails', carDetails);
      setImageMain(carDetails.mainImage);

      const updatedDemand = {
        ...demand,
        carDetails,
      };
      // console.log('updatedDemand', updatedDemand);
      onDemandClick(updatedDemand);
      setSelectedCardId(demand.id);
    } catch (error) {
      console.error("Error fetching car details:", error);
    }
  };


  useEffect(() => {
    // Call handlePlusClick when demand or token changes
    if (tab === 1) {
      handlePlusClick();
    }
  }, []);

  const useRole = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.role.toString();
  };

  const role = useRole();

  const selectedTab = useSelector(state => state.tab.selectedTab);


  const getIconForTab = (tab) => {
    switch (tab) {
      case 0:
        return <HourGlass h={'24px'} w={'24px'} />;
      case 1:
        return <CheckIcon h={'24px'} w={'24px'} />;
      case 2:
        return <CloseIcon h={'24px'} w={'24px'}/>;
      case 3:
        return <TrashIcon h={'24px'} w={'24px'}/>;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };


  const formatCreatedAt = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    
    return `Retour le ${day}/${month}/${year} - ${demand.endingTime}`;
  };


  const style = {
    // borderRight: selectedTab !== 0 && selectedTab !== 2 && (role === "CPI" || role === "EMP") ? "8px" : undefined,
    borderColor: selectedTab !== 0 &&  selectedTab !== 2 && (role === "CPI" || role === "EMP")
      ? colorMode === "dark"
        ? "#3E4C60"
        : "#dc671a"
      : undefined,
  };



  // useEffect(() =>{
  //   console.log('demand', demand);
  // }, [])



  const styleText = {color: isSelected ? '#fff' : (colorMode === "dark" ? 'gray.400' : '#753519')}


  const styleCarBox = {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    overflow: 'clip',
  }
  return ( 
    <Box
      p='24px'
      bg={colorMode === 'dark' ? (isSelected ? '#000' :'#2D3748') : (isSelected ? '#2D3748' : '#f7d616')}
      my='22px'
      borderRadius='10px' 
      position={"relative"}
      {...style}
    >
      <Flex justify='space-between' w='100%' align='flex-start'>
        <Flex direction='row' align='flex-start'>
          <Box bgColor={''} mr={4}>
            <IconBox as='box' h={'45px'} w={'45px'} bg={isSelected ?  (colorMode === "dark" ? colors.greenPrimary : '#f7d616') : (colorMode === "dark" ? '#18191A'  : colors.greenPrimary) }>
              {getIconForTab(selectedTab)}
            </IconBox>
          </Box> 

        </Flex>
        
      </Flex>  

    {imageMain && (
      <Box className='d-flex' style={{styleCarBox, backgroundColor:'',height:'60px',justifyContent:'center', alignItems:'center'}} 
              zIndex={100} bgColor={''} >
                           <Image src={`${baseUrlNet}${imageMain}`} alt="Car" className=" mb-3" p={16} style={{ display: 'inline-block' }} />
              </Box>
    )}


      <Badge 
        position={'absolute'}
        bottom={3}  textTransform="none" 
        right={14} className='jakB' colorScheme={isSelected ? (colorMode === "dark" ? 'teal' : 'gray') :   colorMode === "dark" ? '' : 'gray'} 
        >
            {formatCreatedAt(demand.endingDate)}
        </Badge>

      <InfosModal isSelected={isSelected} demand={demand} imageMain={imageMain}/>
    </Box>
  );
}

export default RetourCarCard;

