import { extendTheme } from "@chakra-ui/react";

const theme = {
  config: {
    intialColorMode: "light",
    useSystemColorMode: false,
  },
  
  
};

export default extendTheme(theme);

