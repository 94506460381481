import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Flex,
    Icon,
    useColorMode,
    Box,
    useToast,
    useColorModeValue,
    CircularProgressLabel,
    CircularProgress,
    Card,
    ModalFooter,
    HStack,
    Divider,
    VStack,
} from '@chakra-ui/react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { BsCheck2Circle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import colors from '../../utils/colors';
import { baseUrlNet } from '../../utils/useRequest';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ConfirmBookingModal = ({ demand, mainImage, onClickHandleBook, isLoading, dir }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode } = useColorMode();
    const location = useLocation();
    const [percentage, setPercentage] = useState(0);
    const toast = useToast();
    const { t } = useTranslation();

    const isDarkMode = colorMode === 'dark';

 
    const bgCard = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
    
      const borderColor = useColorModeValue(
        "white",
        "rgba(255, 255, 255, 0.31)"
      );
    
      const bgStyle = isDarkMode
        ? {
            bg: bgCard,
            boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.02)',
            border: '2px solid',
            backdropFilter: 'saturate(200%) blur(50px)',
            borderColor: borderColor,
          }
        : { bg: 'linear-gradient(126.97deg, #343a40  28.26%, rgba(4, 12, 48, 0.5) 91.2%)' };

    const calculateDays = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays-1;
    };

    const daysBetween = calculateDays(demand.startingDate, demand.endingDate);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    };

    const colorSchemeBookNow = useColorModeValue('yellow', 'yellow');

    const handleOpenModal = () => {
        // console.log('demand new', demand);
        // Validate required fields
        if (!demand.name || !demand.phoneNumber || !demand.startingDate || !demand.startingTime || !demand.endingDate || !demand.endingTime) {
            toast({
                description: t('confirmBooking.warningAllFields'),
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: 'bottom-left'
            });
            return;
        }

        // Validate dates
        if (new Date(demand.startingDate) > new Date(demand.endingDate)) {
            toast({
                description: t('confirmBooking.errorDateRange'),
                status: "error",
                duration: 5000,
                isClosable: true,
                position: 'bottom-left'
            });
            return; 
        }
        onOpen();
    };

    const handleBookNow = () => {
        onClickHandleBook();
    };

    const direction = document.body.dir;

    return (
        <>
            <Button leftIcon={<BsCheck2Circle />}  className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} fontSize={"md"} w={'100%'} colorScheme={'purple'} onClick={handleOpenModal} size='lg'>
                {t('car_details.book_now')}
            </Button>
            <Modal
                size={{ base: 'md', md: 'xl' }}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                blockScrollOnMount={false}
                borderRadius="4xl"
                borderLeft="16px"
                borderColor="teal.400"
                isCentered
            >
                <ModalOverlay />
                <ModalContent bg="transparent" boxShadow={'none'}>
                    <ModalHeader className="jakB" textAlign={'center'}></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Card mb={4} bgColor={colorMode === 'dark' ? '#242526' : '#242526'} borderRadius={"24px"}>
                            <Flex direction="column" alignItems="center" rounded="md" px="4" py={5}
                                position="relative"
                                bg={colorMode === "dark" ? '' : '#242526'}
                                borderRadius='20px'>
                                <Box position="absolute" inset="0" height="32" bgColor={colorMode === "dark" ? '#929293' : '#6415FF'} roundedTop="inherit" />
                                <Box mb={20} className='d-flex' style={{ backgroundColor: '', height: '100px', justifyContent: 'center', alignItems: 'center' }}
                                    zIndex={100} mt={'30px'} bgColor={''}>
                                    <img src={`${baseUrlNet}${mainImage}`} alt="Car" className="img-fluid " style={{ display: 'inline-block' }} />
                                </Box>

                                <Card
                                    bg={colorMode === "dark" ? '#242526' : '#242526'}
                                    boxShadow={'none'}
                                    mt={5}
                                    display={'relative'}
                                >
                                    <Flex direction='column'>
                                        <Flex direction={{ base: 'column', md: 'row' }} bgColor={''} alignItems={''} justifyContent={{ base: 'center', md: 'space-between' }}>
                                            <Flex direction='column' w={{ base: "270px", md: "240px" }} me={{ md: '6px', lg: '52px' }} mb={{ base: '16px', md: '0px' }} bgColor={''}>
                                                <Flex
                                                    direction='column'
                                                    p='16px'
                                                    pe={{ sm: '16px', md: '8px', lg: '16px' }}
                                                    minW={{ sm: '180px', md: '120px', lg: '180px' }}
                                                    borderRadius='16px'
                                                    mb='16px'
                                                    {...bgStyle}>
                                                    <Text color='gray.400' fontSize='xs' mb='4px' className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`}>
                                                        {t('confirmBooking.name')}
                                                    </Text>
                                                    <Text color='white' fontSize='sm' fontWeight='bold' className={`${dir === 'ltr' ? 'jakB' : 'cairoB'}`}>
                                                        {demand.name}
                                                    </Text>
                                                </Flex>

                                                <Flex
                                                    direction='column'
                                                    p='16px'
                                                    pe={{ sm: '16px', md: '8px', lg: '16px' }}
                                                    minW={{ sm: '180px', md: '120px', lg: '180px' }}
                                                    borderRadius='16px'
                                                    mb='16px'
                                                    {...bgStyle}  
                                                >
                                                    <Text color='gray.400' fontSize='xs' mb='4px' className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`}>
                                                        {t('confirmBooking.period')}
                                                    </Text>

                                                    <Flex alignItems='center' justifyContent='space-between'>
                                                        <VStack spacing={0} alignItems={'flex-start'}>
                                                            <Text color='white' fontSize='sm' fontWeight='bold' className={`${dir === 'ltr' ? 'jakB' : 'cairoB'}`}>
                                                                {formatDate(demand.startingDate)}
                                                            </Text>
                                                            <Text color='white' fontSize='xs' className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`}>
                                                                {demand.startingTime}
                                                            </Text>
                                                        </VStack>

                                                        {/* <Icon as={FaArrowRight} color='white' mx={2} /> */}
                                                        <Icon as={direction === 'ltr' ? FaArrowRight : FaArrowLeft} color='white' mx={2} />

                                                        <VStack spacing={0} alignItems={'flex-end'}>
                                                            <Text color='white' fontSize='sm' fontWeight='bold' className={`${dir === 'ltr' ? 'jakB' : 'cairoB'}`}>
                                                                {formatDate(demand.endingDate)}
                                                            </Text>
                                                            <Text color='white' fontSize='xs'  className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`}>
                                                                {demand.endingTime}
                                                            </Text>
                                                        </VStack>
                                                    </Flex>
                                                </Flex>

                                                <Flex
                                                    direction='column'
                                                    p='16px'
                                                    pe={{ sm: '16px', md: '8px', lg: '16px' }}
                                                    minW={{ sm: '140px', md: '120px', lg: '140px' }}
                                                    borderRadius='16px'
                                                    {...bgStyle}>
                                                    <Text color='gray.400' fontSize='xs' mb='4px' className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`}>
                                                        {t('confirmBooking.amount')}
                                                    </Text>
                                                    <Text color='white' fontSize='sm' fontWeight='bold' className={`${dir === 'ltr' ? 'jakB' : 'cairoB'}`}>
                                                        {demand.totalPrice} {t('card.currency')}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                            <Box mx={{ base: 'auto', md: '0px' }}>
                                            <CircularProgress
                                            size={window.innerWidth >= 1024 ? 160 : window.innerWidth >= 768 ? 140 : 160}
                                            value={percentage}
                                            thickness={5}
                                            color={colorMode === "dark" ? '#929293'  : '#513138'}
                                            variant='vision'>
                                            <CircularProgressLabel>
                                                <Flex direction='column' justify='center' align='center'>
                                                <Text
                                                    className={'jakB'}
                                                    fontSize={{ md: '34px', lg: '42px' }}
                                                    fontWeight='bold'
                                                    mb='1px'
                                                    color={'#fff'}>
                                                    {daysBetween+1}                        
                                                </Text>
                                                <Text color='gray.400' fontSize='md' className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`}>
                                                {t('confirmBooking.days')}
                                                </Text>
                                                </Flex>
                                            </CircularProgressLabel>
                                            </CircularProgress>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Card>
                                <Divider mt={10}/>
                               <HStack justifyContent={'space-between'} width={'100%'} >
                                    <Button w={'100%'} className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`} colorScheme="purple" onClick={handleBookNow}
                                    isLoading={isLoading} 
                                    loadingText=""
                                    >{t('confirmBooking.bookNow')}</Button>
                                    <Button w={'100%'} className={`${dir === 'ltr' ? 'jakR' : 'cairoR'}`} colorScheme="yellow"  variant={'solid'} onClick={onClose}>{t('confirmBooking.modify')}</Button>
                                </HStack>
                            </Flex>
                        </Card>
                    </ModalBody>
                   
                </ModalContent>
            </Modal>
        </>
    );
};

export default ConfirmBookingModal;
