import React, { useEffect, useState } from 'react';
import Accordion from './Accordion';
import colors from '../../../utils/colors';
import { Box, HStack, Image, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import img from './faqImg.svg';
import cornerLeftBlack from './cornerImg/cornerLeftBlack.svg';
import cornerRightBlack from './cornerImg/cornerRightBlack.svg';
import cornerLeftGreen from './cornerImg/cornerLeftGreen.svg';
import cornerRightGreen from './cornerImg/cornerRightGreen.svg';
import { useTranslation } from 'react-i18next';

const Faq = ({dir}) => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const bgColorCard = useColorModeValue(colors.greenPrimary, '#18191a');
  const divStyle = {
    backgroundColor: '#6415FF',
    position: 'relative'
  };
 
  const [leftCornerImage, setLeftCornerImage] = useState(cornerLeftGreen);
  const [rightCornerImage, setRightCornerImage] = useState(cornerRightGreen);

  useEffect(() => {
    if (colorMode === 'dark') {
      setLeftCornerImage(cornerLeftBlack);
      setRightCornerImage(cornerRightBlack);
    } else {
      setLeftCornerImage(cornerLeftGreen);
      setRightCornerImage(cornerRightGreen);
    }
  }, [colorMode]);

  return (
    <>
      <div className="div container-fluid py-5 mt-5" style={divStyle}>
        <Image src={leftCornerImage} position={'absolute'} top={'-30px'} left={0} height={'30px'} />
        <Image src={rightCornerImage} position={'absolute'} top={'-30px'} right={0} height={'30px'} />
        <div className="container">
          <div className="d-flex row mt-0 py-5">
            <div className="div col-md-12 text-left">
              <h2 className={` ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{ color: '#fff' }}>
                {t('faq.title')}
              </h2>
              <h6 className={` ${dir === 'ltr' ? 'MontserratL' : 'cairoR'}`} style={{ color: '#fff' }}>
                {t('faq.description')}
              </h6>
            </div>
            <Stack direction={{ base: 'column', lg: 'row' }} spacing={8} className='' justifyContent={'space-between'} mt={6}>
              <Box className='div col-md-12'>
                <Accordion dir={dir}/>
              </Box>
             
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;



// import React, { useEffect, useState } from 'react';
// import Accordion from './Accordion';
// import colors from '../../../utils/colors';
// import { Box, HStack, Image, position, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
// import img from './faqImg.svg'
// import cornerLeftBlack from './cornerImg/cornerLeftBlack.svg'
// import cornerRightBlack from './cornerImg/cornerRightBlack.svg'
// import cornerLeftGreen from './cornerImg/cornerLeftGreen.svg'
// import cornerRightGreen from './cornerImg/cornerRightGreen.svg'

// const Faq = () => {

//   const colorMode = useColorMode();
//   const bgColorCard = useColorModeValue(colors.greenPrimary, '#18191a');
//   const divStyle = {
//     backgroundColor: bgColorCard,
//     position: 'relative'
//   };

//   const [leftCornerImage, setLeftCornerImage] = useState(cornerLeftGreen);
//   const [rightCornerImage, setRightCornerImage] = useState(cornerRightGreen);


//   useEffect(() => {
//     if (colorMode === 'dark') {
//       setLeftCornerImage(cornerLeftBlack);
//       setRightCornerImage(cornerRightBlack);
//     } else {
//       setLeftCornerImage(cornerLeftGreen);
//       setRightCornerImage(cornerRightGreen);
//     }
//   }, [colorMode]);





//   return (
//     <>
//       <div className="div container-fluid py-5 mt-5" style={divStyle}>
//         <Image src={leftCornerImage} position={'absolute'} top={'-30px'} left={0} height={'30px'}/>
//         <Image src={rightCornerImage} position={'absolute'} top={'-30px'} right={0} height={'30px'}/>
//         <div className="container">
//           <div className="d-flex row mt-0 py-5" style={{ backgroundColor: '' }}>
//             <div className="div col-md-12 text-left">
//               <h2 className="MontserratB" style={{ color: '#fff' }}>
//                 Frequently Asked Questions (FAQ) - Car Rental
//               </h2>
//               <h6 className="//fw-lighter MontserratL" style={{ color: '#fff' }}>
//                 Here are some common questions and answers about renting a car from our agency.
//                 We hope this information will help you plan your trip and make the most of
//                 your rental experience.
//               </h6>
//             </div>
//             <Stack direction={{base:'column', lg:'row'}} spacing={8} className='' justifyContent={'space-between'} mt={6}>
//               <Box className='div col-md-7'>
//                 <Accordion />
//               </Box>
//               <Box className='div col-md-4' bgColor={''}>
//                   <Image src={img} alt='Faq Image' />
//               </Box>
              
              
//             </Stack>
            
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Faq;
