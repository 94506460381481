import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import FullButton from "../../components/Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/HeaderImgV2.png";
import HeaderImageRtl from "../../assets/img/HeaderImgV2rtl.png";
// import zIndex from "@mui/material/styles/zIndex";
import "./Home.css";
import { Box, Button, Stack, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Link } from "react-scroll";

export default function Header({scrollCallBack, dir}) {
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const { t } = useTranslation();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

    const [backgroundImage, setBackgroundImage] = useState(HeaderImage);

  useEffect(() => {
    setBackgroundImage(dir === "rtl" ? HeaderImageRtl : HeaderImage);
  }, [dir]); 


  return (
    <>
    

<Stack className="bg-image m-0" style={{position: "relative", backgroundImage:  `url(${backgroundImage})`,backgroundColor:'#fff'}} height={{sm:'100vh', md:'100vh'}} zIndex={666}>
    <Wrapper id="home" className="container flexSpaceCenter" >
      
      <Box style={{backgroundColor:''}} mx={4} px={3} py={8} bgColor={bgProfile} >

          <h1   className={`extraBold  ${isDesktop ? 'font40' : 'font25'} ${dir === 'ltr' ? '' : 'cairoB'}`}  style={{ textAlign: isDesktop ? dir === 'ltr' ? 'left' : 'right' : "center"  }}>
            {t('header.welcome_message')}
          </h1>
       
          <HeaderP className={` font14 ${dir === 'ltr' ? 'MontserratM' : 'cairoR'}`}   style={{textAlign: isDesktop ? dir === 'ltr' ? 'left' : 'right' : 'justify', fontSize: '0.86rem' }}>
              {t('header.description')}
            </HeaderP>
       

          <Stack direction={{ base: 'column', md: 'row' }}>
               <Link to="ListCars" aria-label="List Cars" spy={true} smooth={true} offset={-70} duration={0}>
                 <Button
                  className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} 
                  width={'full'}
                  rightIcon={dir === 'ltr' ? <HiChevronRight size={24} color="black" /> : <HiChevronLeft size={24} color="black" />}
                  rounded={'full'}
                  size={'lg'}
                  colorScheme={"purple"}
                  variant={'solid'}
                  // border={`4px solid ${colors.selectedBtnText}`}
                  px={8}
                  borderRadius={'1rem'}
                  action={scrollCallBack}
                  onClick={() => scrollCallBack()}
                >
                  {t('header.view_cars_button')}
                </Button>
              </Link>
            </Stack>
        </Box>
    </Wrapper>
</Stack>
    

  
    
    

    </>
  );
}


const Wrapper = styled.section`
  width: 100%;
  min-height: 700px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 40%;
  }
`;
// const LeftSide = styled.div`
//   width: 50%;
//   height: 100%;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 2;
//     margin: 50px 0;
//     text-align: center;
//   }
//   @media (max-width: 560px) {
//     margin: 80px 0 50px 0;
//   }
// `;
// const RightSide = styled.div`
//   width: 100%;
//   height: 100%;
//   margin-left: calc(10rem + 2vw);
//   @media (max-width: 993px) {
//     width: 100%;
//     order: 1;
//     margin-top: 30px;
//     margin-left: auto;
//   }

//   @media (max-width: 960px) {
//     margin-top: 7rem;
//   }
// `;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 20px 0;
  line-height: 1.5rem;
 
`;
const BtnWrapper = styled.div`
  max-width: 200px;
  @media (max-width: 960px) {
    margin: 0 auto;
    max-width: 200px;
  }
// `;






// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import HeaderImage from "../../assets/img/HeaderImgV2.png";
// import HeaderImageRtl from "../../assets/img/HeaderImgV2.png";
// import "./Home.css";
// import {
//   Avatar,
//   AvatarBadge,
//   Badge,
//   Box,
//   Button,
//   HStack,
//   Icon,
//   Image,
//   Stack,
//   Text,
//   useBreakpointValue,
//   useColorMode,
//   useColorModeValue
// } from "@chakra-ui/react";
// // import carHeader from './carHeader.png';
// import { HiChevronLeft, HiChevronRight, HiUser } from "react-icons/hi";
// import { Link } from "react-scroll";
// import { FaThumbsUp } from "react-icons/fa";
// import colors from "../../utils/colors";
// import { useTranslation } from "react-i18next";

// export default function Header({ scrollCallBack, dir }) {
//   const { t } = useTranslation();
//   const bgProfile = useColorModeValue(
//     "hsla(0,0%,100%,.8)",
//     "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
//   );

//   const { colorMode } = useColorMode();
//   const isDesktop = useBreakpointValue({ base: false, lg: true });

  
//   // State to track direction and selected background image
//   const [direction, setDirection] = useState(dir);
//   const [backgroundImage, setBackgroundImage] = useState(HeaderImage);

//   useEffect(() => {
//     setBackgroundImage(dir === "rtl" ? HeaderImageRtl : HeaderImage);
//   }, [dir]); 


 

//   return (
//     <>
     

        
//       <Stack
//         className="bg-image m-0"
//         position={'absolute'}
//         top={isDesktop ? '-80px' : '-121px'}
//         style={{
//           position: "relative",
//           backgroundRepeat: 'no-repeat',
//           backgroundImage: `url(${backgroundImage})`, 
//           backgroundSize: 'cover',
//           transform: dir === 'rtl' ? 'scaleX(-1)' : 'none',
//         }}
//         backgroundColor={colorMode === 'dark' ? '' : ''}
        
//         height={{ sm: '100vh', md: '100vh' }}
//         zIndex={7}
//       >
      
        
//         <Wrapper id="home" className={`${isDesktop ? 'container flexSpaceCenter' : ''}`}>
//           <Box
//             style={{ backgroundColor: '' }}
//             mx={isDesktop ? '4' : 'auto'}
//             px={3}
//             py={3}
//             bgColor={''}
//             borderRadius={18}
//             textAlign={isDesktop ? "" : "center"}
//           >
//             <Badge
//               colorScheme="teal" 
//               alignSelf="start"
//               size={useBreakpointValue({ base: '3xs', md: 'lg' })}
//               textTransform={'none'}
//               whiteSpace={'none'}
//               px={4} 
//               py={3}
//               borderRadius={'full'}
//               mb={2}
//             >
//               <HStack>
//                 <Icon as={FaThumbsUp} fontSize={'1rem'} />
//                 <Text className={dir === 'ltr' ? 'jakB' : 'cairoM'} mb={0}>{t('header.trusted_badge')}</Text>
//               </HStack>
//             </Badge> 

//           {isDesktop && 
//             <h1   className={`extraBold  ${isDesktop ? 'font40' : 'font30'} ${dir === 'ltr' ? '' : 'cairoB'}`}  style={{ textAlign: isDesktop ? dir === 'ltr' ? 'left' : 'right' : 'center' }}>
//               {t('header.welcome_message')}
//             </h1>
//             }

//             <HeaderP className={` font14 ${dir === 'ltr' ? 'MontserratM' : 'cairoR'}`}   style={{textAlign: isDesktop ? '' : 'justify', fontSize: '0.86rem' }}>
//               {t('header.description')}
//             </HeaderP>

//             <Stack direction={{ base: 'column', md: 'row' }}>
//               <Link to="ListCars" aria-label="List Cars" spy={true} smooth={true} offset={-70} duration={0}>
//                 <Button
//                   className={`${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} 
//                   width={'full'}
//                   rightIcon={dir === 'ltr' ? <HiChevronRight size={24} color="black" /> : <HiChevronLeft size={24} color="black" />}
//                   rounded={'full'}
//                   size={'lg'}
//                   colorScheme={"yellow"}
//                   variant={'solid'}
//                   border={`4px solid ${colors.selectedBtnText}`}
//                   px={8}
//                   borderRadius={'1rem'}
//                 >
//                   {t('header.view_cars_button')}
//                 </Button>
//               </Link>
//             </Stack>
//           </Box>
//         </Wrapper>
//       </Stack>
//     </>
//   );
// }

// const Wrapper = styled.section`
//   width: 90%;
//   margin: auto;
//   min-height: 700px;
//   @media (max-width: 960px) {
//     flex-direction: column;
//     margin-top: 40%;
//   }
// `;

// const HeaderP = styled.div`
//   max-width: 470px;
//   padding: 10px 0 30px 0;
//   line-height: 1.5rem;
// `;


