import { Box } from '@chakra-ui/react'
import * as React from 'react'
// import { images } from './_data'
import { Gallery } from './Gallery'
import { baseUrlNet } from '../../../utils/useRequest';

const ImgCarCarousel = ({mainImage, otherImages, dir }) => {

  const images = [
    { id: 'main', src: `${baseUrlNet}${mainImage}`, alt: 'Main image' },
    ...otherImages.map((src, index) => ({ id: `other-${index}`, src: `${baseUrlNet}${src}`, alt: `Image ${index + 1}` }))
  ];

  // Calculate the total number of images
  const totalImages = images.length;
  return (
    <Box
      maxW="6xl"
      mx="auto"
      // px={{ base: '4', md: '8', lg: '12',}}
      pb={{ base: '6', md: '8', lg: '12',}}
      bgColor={''}
    >
      <Gallery images={images} totalImages={totalImages} dir={dir}  />
    </Box>
  )
}

export default ImgCarCarousel
