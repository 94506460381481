
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Reviews from './pages/reviews/Reviews';
import Login from './pages/login/Login';
import CarDetails from './pages/carDetails/CarDetails';
import Contact from './pages/contact/Contact';
import ScrollToTop from "react-scroll-to-top";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Protected from './components/protected/Protected';
import './App.css';
import metadata from './metadata.json';
import TopNavbar from './components/navbars/TopNavbar';
import Dash from './pages/dashboard/Dash';
import Form from './pages/dashboard/addCarForm/Form';
import DemandsCars from './pages/dashboard/demandsCars/DemandsCars';
import ListMessage from './pages/dashboard/listMessage/ListMessage';
import ListReviews from './pages/dashboard/listReview/ListReviews';
import AdminProfil from './pages/dashboard/adminProfil/AdminProfil';
import UpdateCar from './pages/dashboard/updateCar/UpdateCar';
import ListCar from './pages/dashboard/ListCar/ListCar';
import DashDefault from './pages/dashboard/dashDefault/DashDefault';

import ListMembre from './components/addMembre/listMembre/ListMembre'; 
import AddMembre from './components/addMembre/AddMembre';
import EditMembre from './components/addMembre/EditMembre';

import ListChefProjet from './components/listChefProjet/listChefProjet/ListChefProjet';
import AddChefProjet from './components/listChefProjet/AddChefProjet';
import EditChef from './components/listChefProjet/EditChef';
import IconBox from './components/Icons/IconBox';
import { Avatar, AvatarBadge, ChakraProvider, extendTheme, Stack, useBreakpointValue } from '@chakra-ui/react';
import { BsWhatsapp } from 'react-icons/bs';
import ToggleMode from './components/Elements/toggleMode/ToggleMode';

const AppContent = ({ dir, handleLanguageChange }) => {
  const location = useLocation();
  const showNavbarRoutes = ['/', '/about', '/reviews', '/contact', '/form', '/test'];
  const path = location.pathname;

  // Scroll to the top when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Determine if the current path should show the Navbar
  const showNavbar = (
    showNavbarRoutes.some((route) => path.startsWith(route)) ||
    path.match(/^\/car-details\/\d+$/)
  ) && !path.startsWith('/dash') && !path.startsWith('/login');

  const getWhatsAppMessage = () => {
    const userLang = navigator.language || navigator.userLanguage;
    let message = '';
    switch (userLang) {
        case 'fr':
            message = 'Bonjour, je suis intéressé(e) par la location de voiture. Pouvez-vous m\'aider ?';
            break;
        case 'es':
            message = 'Hola, estoy interesado en alquilar un coche. ¿Puedes ayudarme?';
            break;
        case 'ar':
            message = 'مرحبًا، أنا مهتم بتأجير سيارة. هل يمكنك مساعدتي؟';
            break;
        case 'de':
            message = 'Hallo, ich interessiere mich für die Autovermietung. Können Sie mir helfen?';
            break;
        case 'zh':
            message = '你好，我对租车感兴趣。你可以帮我吗？';
            break;
        case 'ja':
            message = 'こんにちは、車のレンタルに興味があります。お手伝いいただけますか？';
            break;
        default:
            message = 'Hello, I am interested in renting a car. Can you help me?';
            break;
    }
    return encodeURIComponent(message);
};


const isMobile = useBreakpointValue({
  base: true,
  md: false,
});

  return (
    <>
      {showNavbar && <TopNavbar dir={dir} handleLanguageChange={handleLanguageChange} />}
      <div className="App">
        <Routes>
          <Route path='/' element={<Home dir={dir}/>} />
          <Route path='/about' element={<About dir={dir} />} />
          <Route path='/reviews' element={<Reviews dir={dir} />} />
          <Route path='/contact' element={<Contact dir={dir} />} />
          <Route path='/car-details/:id' element={<CarDetails dir={dir} />} />
          <Route path='/login' element={<Login />} />
          <Route path='/form' element={<Form />} />

          <Route path='/dash' element={<Protected component={<Dash />} />}>
            <Route path="" element={<DashDefault />} />
            <Route path='booking' element={<DemandsCars />} />
            <Route path='new-car' element={<Form />} />
            <Route path='update-car/:id' element={<UpdateCar />} />
            <Route path='cars-list' element={<ListCar />} />
            <Route path='messagerie' element={<ListMessage />} />
            <Route path='reviews' element={<ListReviews />} />

            <Route path="liste-membre" element={<ListMembre />} />
            <Route path="ajouter-membre" element={<AddMembre />} />
            <Route path="modifier-membre" element={<EditMembre />} />

            <Route path="liste-chef" element={<ListChefProjet/>} />
            <Route path="ajouter-chef" element={<AddChefProjet />} />
            <Route path="modifier-chef" element={<EditChef />} />


            <Route path="settings" element={<AdminProfil />} />
          </Route>
          <Route path="*" element={<Navigate to='/' />} />
        </Routes>
      </div>
    </>
  );
};

const App = () => {
  const [dir, setDir] = useState("ltr");

  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);

  const handleLanguageChange = (newDir) => {
    setDir(newDir);
  };


  const caching = () => {
    let build = localStorage.getItem('build');
    if (build !== metadata.build.toString()) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Supprimer tous les fichiers cache
          names.forEach(name => {
            caches.delete(name);
          });
        });

        // Assurez-vous que la page se recharge. Les modifications ne sont visibles qu'après un rafraîchissement.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('build', metadata.build);
    }
  };

  useEffect(() => {
    caching();
  }, []);

  return (
    <ChakraProvider theme={extendTheme({ direction: dir })}>
      <Stack m={0} dir={dir}>
        <Router>
          <AppContent dir={dir} handleLanguageChange={handleLanguageChange} />
        </Router>
      </Stack>
    </ChakraProvider>
  );
};

export default App;





// import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Home from './pages/home/Home';
// import About from './pages/about/About';
// import Reviews from './pages/reviews/Reviews';
// import Login from './pages/login/Login';
// import CarDetails from './pages/carDetails/CarDetails';
// import Contact from './pages/contact/Contact';
// import Test from './components/test/Test';
// import ScrollToTop from "react-scroll-to-top";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import Protected from './components/protected/Protected';
// import './App.css';
// import metadata from './metadata.json';
// import TopNavbar from './components/navbars/TopNavbar';
// import Dash from './pages/dashboard/Dash';
// import Form from './pages/dashboard/addCarForm/Form';
// import DemandsCars from './pages/dashboard/demandsCars/DemandsCars';
// import ListMessage from './pages/dashboard/listMessage/ListMessage';
// import ListReviews from './pages/dashboard/listReview/ListReviews';
// import AdminProfil from './pages/dashboard/adminProfil/AdminProfil';
// import UpdateCar from './pages/dashboard/updateCar/UpdateCar';
// import ListCar from './pages/dashboard/ListCar/ListCar';
// import DashDefault from './pages/dashboard/dashDefault/DashDefault';

// import ListMembre from './components/addMembre/listMembre/ListMembre'; 
// import AddMembre from './components/addMembre/AddMembre';
// import EditMembre from './components/addMembre/EditMembre';

// import ListChefProjet from './components/listChefProjet/listChefProjet/ListChefProjet';
// import AddChefProjet from './components/listChefProjet/AddChefProjet';
// import EditChef from './components/listChefProjet/EditChef';

// const App = () => {

//   const caching = () => {
//     let build = localStorage.getItem('build');
//     if (build !== metadata.build.toString()) {
//       if ('caches' in window) {
//         caches.keys().then((names) => {
//           // Supprimer tous les fichiers cache
//           names.forEach(name => {
//             caches.delete(name);
//           });
//         });

//         // Assurez-vous que la page se recharge. Les modifications ne sont visibles qu'après un rafraîchissement.
//         window.location.reload(true);
//       }

//       localStorage.clear();
//       localStorage.setItem('build', metadata.build);
//     }
//   };

//   useEffect(() => {
//     caching();
//   }, []);

//   return (
//     <Router>
//       <div className="App">
//         <ScrollToTop component={<ArrowUpwardIcon />} style={{ borderRadius: '50%' }} />
        
//         <Routes>
//           <Route path='/' element={<Home />} />
//           <Route path='/about' element={<About />} />
//           <Route path='/reviews' element={<Reviews />} />
//           <Route path='/contact' element={<Contact />} />
//           <Route path='/CarDetails/:id' element={<CarDetails />} />
//           <Route path='/login' element={<Login />} />
//           <Route path='/form' element={<Form />} />
//           <Route path='/test' element={<Test />} />

//           <Route path='/dash' element={<Protected component={<Dash />} />}>
//             <Route path="" element={<DashDefault />} />
//             <Route path='booking' element={<DemandsCars />} />
//             <Route path='new-car' element={<Form />} />
//             <Route path='update-car/:id' element={<UpdateCar />} />
//             <Route path='cars-list' element={<ListCar />} />
//             <Route path='messagerie' element={<ListMessage />} />
//             <Route path='reviews' element={<ListReviews />} />

//             <Route path="liste-membre" element={<ListMembre />} />
//             <Route path="ajouter-membre" element={<AddMembre />} />
//             <Route path="modifier-membre" element={<EditMembre />} />

//             <Route path="liste-chef" element={<ListChefProjet/>} />
//             <Route path="ajouter-chef" element={<AddChefProjet />} />
//             <Route path="modifier-chef" element={<EditChef />} />


//             <Route path="settings" element={<AdminProfil />} />
//           </Route>
//           <Route path="*" element={<Navigate to='/' />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;
