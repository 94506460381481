import React from 'react'
import './review.css'
import styled from "styled-components";
import AddReview from './AddReview';
import Ftr from "../../components/footer/Ftr";
import ReviewSlider from './ReviewSlider';
import colors from '../../utils/colors';
import { useTranslation } from 'react-i18next';
import ReviewFormModal from './ReviewFormModal';

const Reviews = ({dir}) => {

  const { t } = useTranslation();

  return (
    <>
    <div  className="container-fluid  " style={{paddingTop:'20px',marginBottom:'65px',marginTop:'65px', backgroundColor:""}} >     
      <Wrapper id="blog">
    
        <div className="" style={{padding: '50px 0'}}>
          <div className="container">
            <HeaderInfo className='row d-flex justify-content-between'>
              <div className="div col-md-7">
                <h1  className={`${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{ textAlign: dir === 'ltr' ? 'left' : "right" }}>
                  {t('reviewsPage.heading')}
                </h1>
                <p  className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} style={{ textAlign: dir === 'ltr' ? 'left' : "right" }} >
                {t('reviewsPage.description')}
                </p>
              </div>
              <div className="div col-md-2">
                  <ReviewFormModal dir={dir}/>  
              </div>
            </HeaderInfo>
            <ReviewSlider/>
          </div>
        </div>
      </Wrapper>
      
    </div>
    <Ftr/>
    </>
  )
}
const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

export default Reviews