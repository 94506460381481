import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import MemberTable  from './MemberTable';
import { Link, useParams } from 'react-router-dom';
import { IoAddSharp } from "react-icons/io5";
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { baseURL } from '../../../utils/useRequest';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { setMemberInfo } from '../../../redux/slices/memberSlice';
import colors from '../../../utils/colors';

const ITEMS_PER_PAGE = 3;

const ListMembre = () => {
  const { colorMode } = useColorMode();
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [forceRender, setForceRender] = useState(false); // Boolean value to force render

  const { id } = useParams();

  const dispatch = useDispatch();

  const useUserId = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.id;
  };

  const token = useSelector(state => state.token.token.access_token);

  const userId = useUserId(); 
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(`${baseURL}/users/getUsersByChefId/${id ? id : userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setMembers(response.data);
        // console.log('response.data',response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des membres : ', error);
      }
    };
    
    fetchMembers();
  }, [forceRender]); 

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const filteredMembers = members.filter((member) =>
    member.cin.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalItems = filteredMembers.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const useRole = () => {
    const token = useSelector(state => state.token.token.access_token);
    const decodedToken = jwtDecode(token);
    return decodedToken.role.toString();
  };

  const role = useRole();

  const removeDeletedMember = (email) => {
    setMembers(members.filter(member => member.email !== email));
    setForceRender(prevState => !prevState); // Toggle forceRender to trigger useEffect
  };
  return (
    <>
      {/* {role === 'ADMIN' && (
       <Stack maxW={'6xl'} mx={"auto"} mb={2} mt={2} px={4} alignItems={"flex-start"} bgColor={''}>
          <Link  to='/dash/liste-chef' aria-label="Home">
                <IconButton  aria-label="Go back" icon={<ArrowBackIcon boxSize="8"  />} borderRadius="full" boxSize="12" variant={'solid'} />
          </Link>  
        </Stack>
        )}  */}


      {/* {role === 'CPI' && ( */}
        <Stack maxW={'6xl'} mx={'auto'} mb={2} mt={2} px={4} alignItems={"flex-end"} bgColor={''}>
           
           <Link to="/dash/ajouter-membre" aria-label="Home">
                <IconButton aria-label="Go back" icon={<AddIcon boxSize="6"  />} borderRadius="full" boxSize="12" bgColor={colors.primary} color="white" _hover={{ bgColor: colors.secondary }} _active={{ bgColor: 'orange.700' }} />
            </Link> 
          
        </Stack>
        {/* )} */}
        
    
    <Container maxW={'6xl'} py={{ base: '6', md: '6' }} alignItems={"center"} justifyContent={"center"} >

      <Box
        bg={colorMode === "dark" ? '#242526'  : "white"} 
        boxShadow={useColorModeValue('md', 'md-dark')}
        borderRadius='20px'
      >
        <Stack spacing="5">
          <Box px={{ base: '4', md: '6' }} pt="5">
            <Stack direction={{ base: 'column', md: 'row' }} justify="space-between">
              <Text className='jakB' fontSize="2xl" fontWeight="medium" >
                  Liste des membres
              </Text>
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="muted" boxSize="5" />
                </InputLeftElement>
                <Input
                  className='jakR' 
                  placeholder="Chercher avec CIN"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>
            </Stack>
          </Box>
          <Box overflowY="auto">
            <MemberTable initialMembers={filteredMembers} startIndex={startIndex} endIndex={endIndex} removeDeletedMember={removeDeletedMember} />
          </Box>
          <Box px={{ base: '4', md: '6' }} pb="5">
            <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text className='jakR' color="muted" fontSize="sm">
                  Affichage de {startIndex + 1} à {Math.min(endIndex, totalItems)} sur {totalItems} résultats
                </Text>
              )}
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{ base: 'full', md: 'auto' }}
                variant="secondary"
              >
                <Button
                  className='jakR' 
                  color={'white'}
                  bg="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  _selected={{ bg: 'gray.500' }}
                  _active={{ bg: 'gray.400' }}
                  onClick={handlePrevious}
                  isDisabled={currentPage === 1}
                >
                  Précédent
                </Button>
                <Button
                  className='jakR' 
                  color={'white'}
                  bg="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  _selected={{ bg: 'gray.500' }}
                  _active={{ bg: 'gray.400' }}
                  onClick={handleNext}
                  isDisabled={currentPage === totalPages}
                >
                  Suivant
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </Container>
    </>
  );
};

export default ListMembre;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   Button,
//   ButtonGroup,
//   Container,
//   HStack,
//   Heading,
//   Icon,
//   IconButton,
//   Input,
//   InputGroup,
//   InputLeftElement,
//   Stack,
//   Text,
//   useBreakpointValue,
//   useColorMode,
//   useColorModeValue,
// } from '@chakra-ui/react';
// import { FiSearch } from 'react-icons/fi';
// import MemberTable  from './MemberTable';
// import { Link, useParams } from 'react-router-dom';
// import { IoAddSharp } from "react-icons/io5";
// import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
// import { baseURL } from '../../../../utils/useRequest';
// import { useDispatch, useSelector } from 'react-redux';
// import { jwtDecode } from 'jwt-decode';
// import { setMemberInfo } from '../../../../redux/slices/memberSlice';

// const ITEMS_PER_PAGE = 3;

// const ListMembre = () => {
//   const { colorMode } = useColorMode();
//   const [members, setMembers] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [forceRender, setForceRender] = useState(false); // Boolean value to force render


//   const { id } = useParams();

//   const dispatch = useDispatch();


//   const useUserId = () => {
//     const token = useSelector(state => state.token.token.access_token);
//     const decodedToken = jwtDecode(token);
//     return decodedToken.id;
//   };

//   const token = useSelector(state => state.token.token.access_token);


//   const userId = useUserId();
//   useEffect(() => {
//     const fetchMembers = async () => {
//       try {
//         const response = await axios.get(`${baseURL}/v1/users/getUsersByChefId/${id ? id : userId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         });
//         setMembers(response.data);
//         console.log('response.data',response.data);
//       } catch (error) {
//         console.error('Erreur lors de la récupération des membres : ', error);
//       }
//     };
    
//     fetchMembers();
//   }, []);

//   const isMobile = useBreakpointValue({
//     base: true,
//     md: false,
//   });

//   const filteredMembers = members.filter((member) =>
//     member.cin.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const totalItems = filteredMembers.length;
//   const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

//   const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
//   const endIndex = startIndex + ITEMS_PER_PAGE;

//   const handlePrevious = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   const handleNext = () => {
//     setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };


//   const useRole = () => {
//     const token = useSelector(state => state.token.token.access_token);
//     const decodedToken = jwtDecode(token);
//     return decodedToken.role.toString();
//   };

//   const role = useRole();

//   const removeDeletedMember = (email) => {
//     setMembers(members.filter(member => member.email !== email));
//   };

//   return (
//     <>
//       {role === 'ADMIN' && (
//        <Stack maxW={'6xl'} mx={"auto"} mb={2} mt={2} px={4} alignItems={"flex-start"} bgColor={''}>

//           <Link  to='/dash/liste-chef' aria-label="Home">
//                 <IconButton  aria-label="Go back" icon={<ArrowBackIcon boxSize="8"  />} borderRadius="full" boxSize="12" variant={'solid'} />
//           </Link>  

//         </Stack>
//         )} 


//       {role === 'CPI' && (
//         <Stack maxW={'6xl'} mx={'auto'} mb={2} mt={2} px={4} alignItems={"flex-end"} bgColor={''}>
           
//            <Link to="/dash/ajouter-membre" aria-label="Home">
//                 <IconButton aria-label="Go back" icon={<AddIcon boxSize="6"  />} borderRadius="full" boxSize="12" bgColor="teal.500" color="white" _hover={{ bgColor: 'teal.600' }} _active={{ bgColor: 'teal.700' }} />
//             </Link> 
          
//         </Stack>
//         )}
        
    
//     <Container maxW={'6xl'} py={{ base: '6', md: '6' }} alignItems={"center"} justifyContent={"center"} >

//       <Box
//         bg={colorMode === "dark" ? '#242526'  : "white"} 
//         boxShadow={useColorModeValue('md', 'md-dark')}
//         borderRadius='20px'
//       >
//         <Stack spacing="5">
//           <Box px={{ base: '4', md: '6' }} pt="5">
//             <Stack direction={{ base: 'column', md: 'row' }} justify="space-between">
//               <Text className='jakB' fontSize="2xl" fontWeight="medium" >
//                Membres
//               </Text>
//               <InputGroup maxW="xs">
//                 <InputLeftElement pointerEvents="none">
//                   <Icon as={FiSearch} color="muted" boxSize="5" />
//                 </InputLeftElement>
//                 <Input
//                   className='jakR' 
//                   placeholder="Chercher avec CIN"
//                   value={searchQuery}
//                   onChange={(e) => setSearchQuery(e.target.value)}
//                 />
//               </InputGroup>
//             </Stack>
//           </Box>
//           <Box overflowY="auto">
//             <MemberTable initialMembers={filteredMembers} startIndex={startIndex} endIndex={endIndex} removeDeletedMember={removeDeletedMember} />
//           </Box>
//           <Box px={{ base: '4', md: '6' }} pb="5">
//             <HStack spacing="3" justify="space-between">
//               {!isMobile && (
//                 <Text className='jakR' color="muted" fontSize="sm">
//                   Affichage de {startIndex + 1} à {Math.min(endIndex, totalItems)} sur {totalItems} résultats
//                 </Text>
//               )}
//               <ButtonGroup
//                 spacing="3"
//                 justifyContent="space-between"
//                 width={{ base: 'full', md: 'auto' }}
//                 variant="secondary"
//               >
//                 <Button
//                   className='jakR' 
//                   color={'white'}
//                   bg="gray.700"
//                   _hover={{ bg: 'gray.600' }}
//                   _selected={{ bg: 'gray.500' }}
//                   _active={{ bg: 'gray.400' }}
//                   onClick={handlePrevious}
//                   isDisabled={currentPage === 1}
//                 >
//                   Précédent
//                 </Button>
//                 <Button
//                   className='jakR' 
//                   color={'white'}
//                   bg="gray.700"
//                   _hover={{ bg: 'gray.600' }}
//                   _selected={{ bg: 'gray.500' }}
//                   _active={{ bg: 'gray.400' }}
//                   onClick={handleNext}
//                   isDisabled={currentPage === totalPages}
//                 >
//                   Suivant
//                 </Button>
//               </ButtonGroup>
//             </HStack>
//           </Box>
//         </Stack>
//       </Box>
//     </Container>
//     </>
//   );
// };

// export default ListMembre;

