// toggleCompteur.js
import { createSlice } from '@reduxjs/toolkit';

const toggleCompteur = createSlice({
  name: 'toggle',
  initialState: {
    isShown: false, // Initial state
  },
  reducers: {
    toggle(state) {
      // Toggle the boolean value
      state.isShown = !state.isShown;
    },
  },
});

// Export action creator
export const { toggle } = toggleCompteur.actions;

// Export reducer
export default toggleCompteur.reducer;
