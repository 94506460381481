import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Circle } from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/styles.css'; // Import fullscreen styles
import L from 'leaflet';
import clientMarker from './clientMarker.png';

// Fix the default marker icon issue in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});



const clientMarkerIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const WorkZoneMap = () => {
  const [workZone, setWorkZone] = useState({
    latitude: 33.372767, 
    longitude: -7.579786,
    radius: 0 // Default radius in km
  });


  return (
    <MapContainer center={[33.372767,  -7.579786]} zoom={17} style={{ height: '384px', width: '100%', borderRadius: '20px', zIndex:'1' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <FullscreenControl position="topright" /> {/* Add FullscreenControl here */}
      {/* <Marker position={[workZone.latitude, workZone.longitude]} icon={clientMarkerIcon} /> */}
      {/* <Circle center={[workZone.latitude, workZone.longitude]} radius={workZone.radius * 1000} color='#00aaff' /> */}

    </MapContainer>
  );
};

export default WorkZoneMap;