import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Divider,
    Heading,
    Stack,
    VStack,
    HStack,
    IconButton,
    Badge,
    useToast,
  } from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi';
import { baseURL } from '../../../utils/useRequest';
import axios from 'axios';
import { useSelector } from 'react-redux';


const DeleteItem = ({firstname, lastname, cin, email, removeDeletedMember}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast();
    const token = useSelector(state => state.token.token.access_token);


    const handleDelete = async () => {
        try {
            const response = await axios.delete(
                `${baseURL}/users/deleteUser`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    data: { email: email }
                }
            );
            removeDeletedMember(email);
            // console.log('removeDeletedMembe', email )
            toast({
                title: "Membre supprimé avec succès",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            onClose();
           

        } catch (error) {
            console.error("Erreur lors de la suppression du membre :", error);
            toast({
                title: "Erreur lors de la suppression du membre",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            onClose();
        }
    };
    

    

  return (
    <>
       <IconButton icon={<FiTrash2 fontSize="1.25rem" />} colorScheme='red' aria-label="Delete member" onClick={onOpen}/>
        <Modal size={{ base: 'sm', md: 'lg',sm:'sm' }} isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" blockScrollOnMount={false}
        borderRadius="3xl" borderLeft="16px" borderColor="teal.400">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader className={'jakB'} >Supprimer un membre</ModalHeader>
            <ModalCloseButton />
            <Divider mt={0}/>
   
            <ModalBody>
            <Text className='jakB' fontSize='sm' mb={2}>Êtes-vous sûr de vouloir supprimer ce membre de votre équipe ?</Text>
                <Stack spacing={-4}  >

                        <Stack direction={'row'}>
                            <Text className={'jakM'} >Le Nom Complet :</Text>
                            <Text className={'jakR'} >{firstname} {lastname}</Text>
                        </Stack>

                    
                        <Stack direction={'row'}>
                            <Text className={'jakM'} >CIN :</Text>
                            <Text className={'jakR'} >{cin}</Text>
                        </Stack>
                        
                        
    
                </Stack>
               
            
            </ModalBody>

            <ModalFooter>
                <Button className={'jakR'} width={'10em'} variant={'solid'} mr={3} onClick={onClose}>
                    Annuler
                </Button>
                <Button className={'jakR'} colorScheme="red" width={'10em'} onClick={handleDelete}>
                    Supprimer
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
  )
}

export default DeleteItem