import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  IconButton,
  Badge,
  Avatar,
  VStack,
  useColorMode,
  Tooltip,
} from '@chakra-ui/react';
import DeleteItem from './DeleteItem';
import { FiEdit2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMemberInfo } from '../../../redux/slices/memberSlice';
import { FaUserGroup } from 'react-icons/fa6';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { baseURL } from '../../../utils/useRequest';

const MemberTable = ({ initialMembers, startIndex, endIndex, removeDeletedMember }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colorMode = useColorMode();
  const [members, setMembers] = useState(initialMembers);
  const [imageDataMap, setImageDataMap] = useState({});
  const token = useSelector(state => state.token.token.access_token);
  const role = useSelector(state => jwtDecode(state.token.token.access_token).role.toString());

  useEffect(() => {
    const fetchAvatars = async () => {
      const imageDataMapCopy = {};
      await Promise.all(members.slice(startIndex, endIndex).map(async (member) => {
        if (member.imageFileId) {
          try {
            const response = await axios.get(`${baseURL}/v1/file/files/${member.imageFileId}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            const imageData = `data:image/png;base64,${response.data.data}`;
            imageDataMapCopy[member.id] = imageData;
          } catch (error) {
            console.error("Error fetching image data:", error);
          }
        } else {
          
          imageDataMapCopy[member.id] = null; 
        }
      }));
      setImageDataMap(imageDataMapCopy);
    };

    fetchAvatars();
  }, [members, startIndex, endIndex, token]);

  
  useEffect(() => {
    setMembers(initialMembers);
  }, [initialMembers]);

  const handleButtonClick = (member) => {
    dispatch(setMemberInfo(member));
    navigate(`/dash/modifier-chef`); 
  };

  const fetchMembers = async (id) => {
    try {
      const response = await axios.get(`${baseURL}/users/getUsersByChefId/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMembers(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des membres : ', error);
    }
  };

  const handleShowMembers = (id) => {
    fetchMembers(id);
    
  };

 
  return (
    <Table>
      <Thead>
        <Tr bgColor={'gray.200'}>
          <Th>Le nom complet</Th>
          <Th>Cin</Th>
          <Th>Télephone</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {members.slice(startIndex, endIndex).map((member) => (
          <Tr key={member.id}>
            <Td>
              <HStack>
                <Avatar me={{ md: "22px" }} src={imageDataMap[member.id]} w="80px" h="80px" borderRadius="full" name={`${member.firstname}`+ " " + `${member.lastname}`} />
                <VStack align={'left'}>
                  <Text color="muted" className='jakB'>{member.firstname} {member.lastname}</Text>
                  <Text color="muted" className='jakR'>{member.email} </Text>
                </VStack>
              </HStack>
            </Td>
            <Td><Text fontWeight="medium">{member.cin}</Text></Td>
            <Td><Text color="muted" className='jakR'>{member.phone}</Text></Td>
            <Td>
              <HStack spacing="1">
              {role === 'ADMIN' && (
                  <Tooltip label="Membres d'équipe">
                    <IconButton onClick={() => handleShowMembers(member.id)} me={3} icon={<FaUserGroup fontSize="1.25rem" />} colorScheme='messenger' color={colorMode === 'dark' ? '#2D3748' : 'white'} aria-label="members" />
                  </Tooltip>
                )}

                <Tooltip label="Modifier directeur"> 
                  <IconButton onClick={() => handleButtonClick(member)} me={3} icon={<FiEdit2 fontSize="1.25rem" />} colorScheme='green' aria-label="Edit member" />
                </Tooltip>
                <DeleteItem  firstname={member.firstname} lastname={member.lastname} email={member.email} cin={member.cin} removeDeletedMember={removeDeletedMember}/>
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default MemberTable;