
import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Divider,
    Heading,
    Stack,
    VStack,
    HStack,
    IconButton,
    Badge,
    useToast,
    FormControl,
    useColorMode,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    useColorModeValue,
  } from '@chakra-ui/react'
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import './reactCalendar.css'
import { CalendarIcon } from '@chakra-ui/icons';
import fr from 'date-fns/locale/fr';
import { getDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

const EndDateModal = ({onEndDateSelect, dir}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t } = useTranslation();

    const toast = useToast();
    const { colorMode } = useColorMode();

    const [endDate, setEndDate] = useState(null);
    const todayDate = new Date();

    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
      };

    const handleConfirmEndDate = () => {
        onEndDateSelect(endDate);
        onClose();
    };


    const bgColorInput = useColorModeValue('#eee', '#eee');
    

  return (
    <>
        
        <InputGroup onClick={onOpen} >
            <InputRightElement pointerEvents='none'>
            <CalendarIcon color='gray.300' />
            </InputRightElement>
            <Input
                size={'lg'}
                fontSize={"md"}
                py={3}
                type='text'
                bgColor={bgColorInput}
                focusBorderColor="whiteAlpha"
                placeholder={t('form.returnDate')}
                value={endDate ? endDate.toLocaleDateString('fr-FR') : ''}
                readOnly
                className={' form-control border-0 py-3'}
                
            />
        </InputGroup>
        <Modal size={{ base: 'sm', md: 'lg',sm:'sm' }} isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" blockScrollOnMount={false}
        borderRadius="3xl" borderLeft="16px" borderColor="teal.400">
        <ModalOverlay />
        <ModalContent >
            <Text pt={4} pl={dir === 'ltr' ? 4 : 0} pr={dir === 'rtl' ? 4 : 0} className={` ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} bgColor={''}>{t('form.returnDate')}</Text>
            <ModalCloseButton />
   
            <ModalBody>
                <Stack spacing={-4}  >

                
                    <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    className={'Montserrat'}
                    name="endDate"
                    dateFormat="dd/MM/yyyy"
                    calendarClassName={colorMode === 'dark' ? 'react-datepicker__calendar--dark' : 'react-datepicker__calendar--light'}
                    popperClassName={colorMode === 'dark' ? 'react-datepicker-popper--dark' : ''}
                    minDate={todayDate}
                    locale={fr} 
                    shouldCloseOnSelect={false}
                    inline
                    // filterDate={isWeekday}
                    />
    
                </Stack>

            {/* <Divider mt={6}/> */}

            </ModalBody>

            <ModalFooter>
                <Button className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}  variant={'ghost'} mr={3} onClick={onClose}>
                    {t('car_details.annuler_btn')}
                </Button>
                <Button  className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`} colorScheme="teal" onClick={handleConfirmEndDate}>
                    {t('car_details.confirm_btn')}
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
  )
}

export default EndDateModal

