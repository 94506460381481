import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo, setUserInfo } from "../../../redux/slices/userSlice";
import { useToast } from "@chakra-ui/react";
import colors from "../../../utils/colors";
// import api from "../../../../../utils/api";



const Header = ({
  backgroundHeader,
  backgroundProfile,
  name,
  email,
  tabs,
  activeTab,
  setActiveTab,
}) => {

  const toast = useToast();

  const dispatch = useDispatch();

  const colorMode = useColorModeValue();


  const [selectedFile, setSelectedFile] = useState(null);



  
  const token = useSelector(state => state.token.token.access_token);
  const userInfo = useSelector(selectUserInfo);

  const [imageData, setImageData] = useState(null);


 





  const textColor = useColorModeValue("gray.700", "white");
  const borderProfileColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const bgColorCard = useColorModeValue(colors.yellowPrimary,  '#242526');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


  


  return (
    <Box
      mb={{ sm: "205px", md: "75px", xl: "60px" }}
      borderRadius='0px 0px 15px 15px'
      px='0px'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'
      top={0}>
      <Box
        // bgImage={backgroundHeader}
        bgColor={bgColorCard}
        w='100%'
        h='150px'
        borderRadius='0px 0px 25px 25px'
        bgPosition='50%'
        bgRepeat='no-repeat'
        position='relative'
        display='flex'
        justifyContent='center'>
        <Flex
          direction={{ sm: "column", md: "row" }}
          mx='1.5rem'
          maxH='330px'
          w={{ sm: "90%", xl: "90%" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          align='center'
          backdropFilter='saturate(200%) blur(50px)' 
          position='absolute'
          boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
          border='2px solid'
          borderColor={borderProfileColor}
          bg={backgroundProfile}
          p='24px'
          borderRadius='20px'
          transform={{
            sm: "translateY(13%)",
            md: "translateY(40%)",
            lg: "translateY(50%)",
          }}>
          <Flex
            align='center'
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}>
           <Avatar
              me={{ md: "22px" }}
              src={imageData}
              name={name}
              w="80px"
              h="80px"
              borderRadius="15px"
              // borderRadius={'30% 70% 70% 30% / 30% 30% 70% 30%'}
            />
            <Flex direction='column' maxWidth='100%' my={{ sm: "14px" }}>
              <Text className={'jakB'}
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight='bold'
                ms={{ sm: "8px", md: "0px" }} >
                {name}
              </Text>
              <Text className={'jakR'}
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight='semibold'>
                {email}
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction={{ sm: "column", lg: "row" }}
            w={{ sm: "100%", md: "50%", lg: "auto" }}>
            {tabs.map((tab) => (
              <Button p='0px' bg='transparent' _hover={{ bg: "none" }} key={tab.name}>
                <Flex
                  align='center'
                  w={{ sm: "100%", lg: "150px" }}
                  bg={activeTab === tab.name ? 'hsla(0,0%,100%,.3)' : 'transparent'}
                  borderRadius='15px'
                  justifyContent='center'
                  py='10px'
                  // py='10px'
                  boxShadow={activeTab === tab.name ? 'inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)' : 'none'}
                  border={activeTab === tab.name ? '1px solid gray.200' : 'none'}
                  cursor='pointer'
                  onClick={() => handleTabClick(tab.name)}
                >
                  {tab.icon}
                  <Text
                    fontSize='xs'
                    color={textColor}
                    fontWeight='bold'
                    ms='6px'
                  >
                    {tab.name}
                  </Text>
                </Flex>
              </Button>
            ))}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;