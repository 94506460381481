import React from 'react';
import './loader.css';

function Loader({absolute, size, tickness}) {
  return (
    <div className={`loader-container${absolute ? " abs": ""}`}>
      <div className="loader" style={{borderWidth: tickness, width: size, height: size}}></div>
    </div>
  );
}

Loader.defaultProps={
  absolute: true,
  tickness: "0.3rem",
  size: "3rem"
}

export default Loader;
