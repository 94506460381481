
import React, { useState } from 'react'
import './login.css'
import {Link, useNavigate } from 'react-router-dom'
import { baseURL } from '../../utils/useRequest';
import LogoIcon from "../../assets/svg/sirycar.svg";
import axios from 'axios';
import ImageLogin from "../../assets/img/imgLoginV3.jpg";
import { setToken } from '../../redux/slices/tokenSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Image, Input, Stack, Text, VStack, useBreakpointValue, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react';
import { login } from '../../redux/slices/isLoggedInSlice';
import { setUserInfo } from '../../redux/slices/userSlice';
import { jwtDecode } from 'jwt-decode';
// import LogoYW from '../../assets/logo/SiryCarYW.svg';
// import LogoDark from '../../assets/logo/SiryCar.svg';
import colors from '../../utils/colors';


const Login = () => {
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setLoading]=useState(false);
    const toast = useToast();
    const colorMode = useColorMode();
    const bgCOlor = useColorModeValue( '#fff', colors.buttonBgColor);
    // const logoSwitch = useColorModeValue( LogoDark, LogoYW);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => { 
      try {
        const response = await axios.post(`${baseURL}/auth/authenticate`, {
          email,
          password
        });
  
        const token = response.data;
        dispatch(setToken(token));
  
        // console.log("response:", response);
        const accessToken = response.data.access_token;
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.id;
  
        
        const userInfoResponse = await axios.get(`${baseURL}/users/getUserById/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
        );
  
        const userInfo = userInfoResponse.data;
        // console.log("User Information:", userInfo);
        dispatch(setUserInfo(userInfo));
  
        // Extract the value of `isLoggedIn` from the decoded token
        const isLoggedIn = decodedToken.isLoggedIn === 'true';
  
        // Dispatch the appropriate action based on `isLoggedIn`
        if (isLoggedIn) {
          dispatch(login());
        } 
  
        navigate('/dash');
      } catch (error) {
        toast({
          // title: 'Error',
          description: 'Le mot de passe que vous avez entré est incorrect.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    };
    
    const isDesktop = useBreakpointValue({
        md: true,
        base: false
      });
    
      return (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }} spacing={0}>
          {isDesktop && (
            <>
              <Flex position='relative'  flex={1} >
                <Image alt={'Login Image'} objectFit={'cover'} src={ImageLogin} />
              </Flex>
            </>
          )}
          <VStack p={8} flex={1} align={'center'} justify={'center'} >

                <Link to="/">
                    <Stack mb={8}>
                        {/* <Image src={LogoIcon} alt="Logo" height="16" width="auto" /> */}
                        <Image
                            src={LogoIcon}
                            alt="Logo"
                            height="16"
                            width="auto"
                          />
                    </Stack>
                </Link>

               
             
    
          
            <Stack spacing={4} w={'full'} px={8} py={8} maxW={'450px'}  bgColor={bgCOlor}  borderRadius={18} boxShadow={'xl'}>
              <FormControl id="email">
                <FormLabel className={'MontserratB'}>Email</FormLabel>
                <Input type="email" id="email"  value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormControl>
              <FormControl id="password">
                <FormLabel className={'MontserratB'}>Password</FormLabel>
                <Input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}  />
              </FormControl>
              <Stack  >
                <Divider mt={4}/>
                {/* <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox className={'Montserrat'}>Se souvenir de moi</Checkbox>
                  <Link to="/forgot-password">
                    <Text color={''} className={'Montserrat'}>Mot de passe oublié ?</Text>
                  </Link>
                </Stack> */}
                <Button className={'MontserratM'} width={'full'} colorScheme={'purple'} variant={'solid'} onClick={handleLogin}>
                  Se connecter
                </Button>
              </Stack>
            </Stack> 
          </VStack>
        </Stack>
      );
    };
    
    export default Login;
   