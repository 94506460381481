import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Image,
  Input,
  IconButton,
  VStack,
  Divider,
  Text,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
import imageIcon from './image.png';
import { baseURL, baseUrlNet } from '../../../utils/useRequest';
import { useSelector } from 'react-redux'; 

const CarImageUploader = ({ mainImage, otherImages, carId }) => {
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [otherImagePreviews, setOtherImagePreviews] = useState([]);
  const token = useSelector(state => state.token.token.access_token);
  const colorSchemeButton = useColorModeValue('blackAlpha', 'whiteAlpha');
  const colorText = useColorModeValue('#fff', '#fff');
  const bgColor = useColorModeValue('#eee', '#4c4e4f');



  // Initialize previews with existing images
  useEffect(() => {
    if (mainImage) {
      setMainImagePreview(`${baseUrlNet}${mainImage}`);
    }
    if (otherImages) {
      setOtherImagePreviews(otherImages.map(src => `${baseUrlNet}${src}`));
    }
  }, [mainImage, otherImages]);

  const deleteImage = async (carId, imageName) => {
    try {
      const response = await axios.delete(`${baseURL}/cars/deleteImage`, {
        params: { carId, imageName },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('Image deleted successfully:', response.data);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const addImageToCar = async (file) => {
    const formData = new FormData();
    formData.append('carId', carId);
    formData.append('newImage', file);

    try {
      const response = await axios.post(`${baseURL}/cars/addImgToOtherImages`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      });
      // console.log('Image added successfully:', response.data);
    } catch (error) {
      console.error('Error adding image:', error);
    }
  };

  const handleDeleteImage = (index) => {
    const imageToDelete = otherImages[index];
    deleteImage(carId, imageToDelete);

    setOtherImagePreviews((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const uploadImage = async (file, carId, imageName) => {
    const formData = new FormData();
    formData.append('newImage', file);
    formData.append('carId', carId);
    formData.append('imageName', imageName);

    try {
      const response = await axios.post(`${baseURL}/cars/updateImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      });
      // console.log('Image uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setMainImagePreview(imageUrl);
      uploadImage(file, carId, mainImage);
    }
  };

  const handleOtherImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setOtherImagePreviews((prevPreviews) => [...prevPreviews, ...imageUrls]);

    files.forEach((file) => {
      addImageToCar(file);
    });
  };



  return (
    <Box padding="" backgroundColor={''} mb={6}>
      <Stack direction="row" spacing="4" justifyContent={'center'} bgColor={''} >
        <Box
          position="relative"
          width="610px"
          height="250px"
          bg={bgColor}
          borderRadius="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          {mainImagePreview ? (
            <Image src={mainImagePreview} alt="Main" objectFit="contain" width="100%" height="100%" p={9} />
          ) : (
            <Image src={imageIcon} alt='imgIcon' height={'50px'} />
          )}
          <IconButton
            aria-label="Add main image"
            icon={<EditIcon />}
            position="absolute"
            top="6"
            right="6"
            bg="teal.600"
            color={'white'}
            onClick={() => document.getElementById('main-image-input').click()}
          />
          <Stack px={8} w={'100%'} position={'absolute'} bgColor={'rgba(246, 224, 94, 0.6)'} bottom={2} backdropFilter={"blur(4px)"}>
            <Divider  mb={0} borderColor={'black'}/>
            <Text className={'jakM'}>Main Image</Text> 
          </Stack>
          <Input
            type="file"
            id="main-image-input"
            display="none"
            onChange={handleMainImageChange}
          />
        </Box>
        <Button onClick={() => document.getElementById('other-images-input').click()} className={'jakB'} p={5} variant={'solid'} size={"4xl"} 
          border='1px solid lightgray' borderRadius='40px' height="250px" colorScheme={colorSchemeButton}>
            <VStack spacing={2}>
            <FaPlus fontSize='2xl' mb='12px' color={colorText}/>
            <Text fontSize='lg' fontWeight='bold' color={colorText}>Plus d'images</Text>
          </VStack>
          <Input
            type="file"
            id="other-images-input"
            display="none"
            multiple
            onChange={handleOtherImagesChange}
          />
        </Button>
      </Stack>

      <SimpleGrid columns={{ base: '2', md: '4' }} spacing={4} marginTop="4">
        {otherImagePreviews.map((image, index) => (
          <Box
            key={index}
            position="relative"
            width="205px"
            height="100px"
            borderRadius="md"
            overflow="hidden"
          >
            <Image src={image} alt={`Other ${index + 1}`} objectFit="cover" width="100%" height="100%" />
            <IconButton
              aria-label="Delete image"
              icon={<CloseIcon />}
              position="absolute"
              top="2"
              right="2"
              bg="red.600"
              size="sm"
              color={'white'}
              onClick={() => handleDeleteImage(index)}
            />
           
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CarImageUploader;


