import React from 'react';
import { Grid, Box, Flex, Text, Heading, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import IconBox from '../../components/Icons/IconBox';
import colors from '../../utils/colors';
import { useTranslation } from 'react-i18next';

const ContactElements = ({dir}) => {
  const { t } = useTranslation();
  const bgColorCard = useColorModeValue('#fff', '#202c33');

  return (
    <Stack maxW="7xl" mx="auto" p={0}>
      <Grid 
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} 
        gap={12} 
        justifyContent="center" 
        textAlign="center" 
        px={{ base: '4', lg: '0' }}
      >
        <Box p={6} bgColor={bgColorCard} borderRadius="25px" boxShadow="lg">
          <Flex justifyContent="center" alignItems="center" mb={4}>
            <IconBox as="box" p={3} rounded="full" h="50px" w="50px" bg={'#6200EE'}>
              <FaPhoneAlt color="white" />
            </IconBox>
          </Flex>
          <Text  fontWeight={'bold'} mb={3} className={` ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}>
            {t('contactPage.phoneNumberLabel')}
          </Text>
          <Text dir='ltr' className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>+212 6 62 52 74 35</Text>
          <Text dir='ltr' className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>+212 6 24 62 41 85</Text>
        </Box>

        <Box p={6} bgColor={bgColorCard} borderRadius="25px" boxShadow="lg">
          <Flex justifyContent="center" alignItems="center" mb={4}>
            <IconBox as="box" p={3} rounded="full" h="50px" w="50px" bg={'#181c2e'}>
              <MdEmail color="white" />
            </IconBox>
          </Flex>
          <Text  fontWeight={'bold'} mb={3} className={` ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}>
            {t('contactPage.emailLabel')}
          </Text>
          <Text className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>support@SiryCar.com</Text>
          <Text className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>contact@SiryCar.com</Text>
        </Box>

        <Box p={6} bgColor={bgColorCard} borderRadius="25px" boxShadow="lg">
          <Flex justifyContent="center" alignItems="center" mb={4}>
            <IconBox as="box" p={3} rounded="full" h="50px" w="50px" bg={'#6200EE'}>
              <HiLocationMarker color="white" />
            </IconBox>
          </Flex>
          <Text  fontWeight={'bold'} mb={3} className={` ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}>
            {t('contactPage.addressLabel')}
          </Text>
          <Text className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>Parking Lot Terminal 2
          </Text>
          <Text className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}>Casablanca Airport
          </Text>
        </Box>
      </Grid>
    </Stack>
  );
};

export default ContactElements;