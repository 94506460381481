import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  token: null,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
  },
});

export const { setToken, clearToken } = tokenSlice.actions;

// Async action to refresh the access token
export const refreshAccessToken = (refreshToken) => async (dispatch) => {
  try {
    await axios.post('/api/v1/auth/refresh-token', { refreshToken });
    // No need to update the Redux state here because the backend will handle it
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error; // Propagate the error back to the caller
  }
};

export default tokenSlice.reducer;



// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   token: null,
// };

// const tokenSlice = createSlice({
//   name: 'token',
//   initialState,
//   reducers: {
//     setToken: (state, action) => {
//       state.token = action.payload;
//     },
//     clearToken: (state) => {
//       state.token = null;
//     },
//   },
// });

// export const { setToken, clearToken } = tokenSlice.actions;

// export default tokenSlice.reducer;
