import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Ftr from '../../components/footer/Ftr';

import Swiper from '../../components/Swiper/Swiper';
import Card from '../../components/Swiper/Card';
import LogoSlider from '../../components/LogoSlider/LogoSlider';
import Header from './Header';
import MouseGradientTracker from '../../components/Elements/MouseGradientTracker';
import Steps from './Steps';
import Loader from '../../components/Elements/loaders/Loader';
import Modal from '../../components/Elements/modals/Modal';
import Faq from './Faq/Faq';
import glowBottom from '../../assets/svg/glow-bottom.svg';
import SearchCars from './searchCars/SearchCars';
import { baseURL } from '../../utils/useRequest';
import { IconButton, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { BsWhatsapp } from 'react-icons/bs'
import IconBox from '../../components/Icons/IconBox';
import RentalNumbers from '../../components/Elements/rentalNumbers/RentalNumbers';
import { useTranslation } from 'react-i18next';


const Home = ({dir}) => {
  const divStyle = {
    backgroundColor: '#181c2e',
    backgroundImage: `url(${glowBottom})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '0px 0px 20px 20px',
    overflowX: 'hidden',
    // height:'78vh'
  };

  const [carsLength, setCarsLength] = useState(0);
  const [carsPerPage, setCarsPerPage] = useState(null);
  const [carsList, setCarsList] = useState(null);
  const [initialCarsList, setInitialCarsList] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [type, setType] = useState('success');
  const [page, setPage] = useState(0);


  ////////////////////

  // const [notificationCount, setNotificationCount] = useState(0);
  // const token = useSelector(state => state.token.token.access_token);


  //   useEffect(() => {
  //       const fetchNotificationCount = async () => {
  //           try {
  //               const response = await axios.get(`${baseURL}/notifications/unread-notification-count`, {
  //                 headers: {
  //                   Authorization: `Bearer ${token}`
  //                 }
  //               });
  //               setNotificationCount(response.data);
  //               console.log('response notifications',response)
  //           } catch (error) {
  //               console.error('Error fetching notification count:', error);
  //           }
  //       };

  //       fetchNotificationCount();

  //       // Cleanup function
  //       return () => {
  //           // Add cleanup logic if needed
  //       };
  //   }, []); // Empty dependency array to only run once on component mount


  ///////////////////

  useEffect(() => {
    axios
      .get(`${baseURL}/open/cars`)
      .then(({ data }) => {
        setCarsList(data ?? []);
        // console.log('carList', data);
        setInitialCarsList(data ?? []);
        setErrorMsg('');
        setCarsLength(data.length);
      })
      .catch((e) => {
        // console.log(e);
        setErrorMsg('Whoops something went wrong, please make sure you are connected or try later');
        setType('error');
      });
  }, []);

  const getCarsPerPage = (carsList, page) => {
    if (carsList === null) {
      return null;
    }
    const start = page * 6;
    return carsList.slice(start, start + 6);
  };

  useEffect(() => {
    setCarsPerPage(getCarsPerPage(carsList, page));
  }, [carsList, page]);

  const handleChange = (value) => {
    setPage(value);
    setCarsPerPage(getCarsPerPage(carsList, value));
    scrollToCarsListCallback();
  };

  const handleMove = (by) => {
    return () => {
      if (by + page < 0 || by + page >= parseInt(carsLength / 6) + (carsLength % 6 === 0 ? 0 : 1)) return;
      handleChange(page + by);
      scrollToCarsListCallback();
    };
  };

  const bookNowRef = useRef(null);
  const carsListRef = useRef(null);

  const scrollCallBack = () => {
    bookNowRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };

  const scrollToCarsListCallback = () => {
    carsListRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
  };

  const internalSearch=(searchPattern)=>{
    const filteredCars=initialCarsList.filter(c=>c?.name?.toLowerCase()?.includes(searchPattern?.toLowerCase()));
    setCarsList(_=>filteredCars);
    setCarsLength(_=>filteredCars.length);
    setPage(_=>0);
  }

  const getWhatsAppMessage = () => {
    const userLang = navigator.language || navigator.userLanguage;
    let message = '';
    switch (userLang) {
        case 'fr':
            message = 'Bonjour, je suis intéressé(e) par la location de voiture. Pouvez-vous m\'aider ?';
            break;
        case 'es':
            message = 'Hola, estoy interesado en alquilar un coche. ¿Puedes ayudarme?';
            break;
        case 'ar':
            message = 'مرحبًا، أنا مهتم بتأجير سيارة. هل يمكنك مساعدتي؟';
            break;
        case 'de':
            message = 'Hallo, ich interessiere mich für die Autovermietung. Können Sie mir helfen?';
            break;
        case 'zh':
            message = '你好，我对租车感兴趣。你可以帮我吗？';
            break;
        case 'ja':
            message = 'こんにちは、車のレンタルに興味があります。お手伝いいただけますか？';
            break;
        default:
            message = 'Hello, I am interested in renting a car. Can you help me?';
            break;
    }
    return encodeURIComponent(message);
};

const isDesktop = useBreakpointValue({ base: false, lg: true });

const { t } = useTranslation();

  return (
    <>
   
      
      <Header dir={dir} scrollCallBack={scrollCallBack} />
      <LogoSlider  dir={dir}/>
      <div className="Steps">
        <Steps dir={dir} scrollToCarsListCallback={scrollToCarsListCallback} />
      </div>
      <div ref={bookNowRef} className="container mb-5 pt-5" style={{ backgroundColor: '' }}>
        <MouseGradientTracker dir={dir} className="divLuxuryCars m-0 p-0" />
        <div className="py-5" style={divStyle}>
          {/* {carsList === null ? <Loader absolute={false} /> : <Swiper products={carsList.filter((c) => c.isLuxuryCar)} />} */}
          {carsList !== null && (
                <Swiper products={carsList.filter((c) => c.isLuxuryCar)} />
            )}


        </div>
      </div>
      <div className="container mt-5">
        <div className="d-flex row justify-content-between">
          <div className="col-md-6">
            <VStack alignItems={'flex-start'}>
                    <Text as={isDesktop ? 'h2' : 'h4'} className={` ${dir === 'ltr' ? 'MontserratB' : 'cairoB'}`} style={{ color: '#6415FF' }}>
                        {t('listing.title')}
                    </Text>
                    {isDesktop &&
                        <Text as={'h4'} fontSize={'1.1rem'} className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoS'}`}>
                            {t('listing.subtitle')}
                        </Text>
                    }
            </VStack>
          </div>
          <div className="col-md-4 d-flex justify-content-end" style={{ backgroundColor: '' }}>
            {/* Pass the handleSelectCar callback function to SearchCars */}
            <SearchCars cb={internalSearch} />
          </div>
        </div>
        <br />

        {/* Render selectedCarData here */}
        <div className="divListCars" style={{ backgroundColor: '', height: '', borderRadius: '26px' }}>
          <div
            ref={carsListRef}
            className="CarListScroll py-2"
            style={{ height: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '1rem' }}
          >
            {carsPerPage &&
              carsPerPage.map((car, index) => (
                <div key={uuid()} style={{ margin: 'auto' }} className="">
                  <Card
                    imageFileId={car.image}
                    hasHeater={car.hasHeater}
                    name={car.name}
                    mainImage={car.mainImage}
                    placesNumber={car.placesNumber}
                    lettersPer90Kms={car.lettersPer90Kms}
                    pricePerDay={car.pricePerDay}
                    id={car.id}
                    model={car.model}
                    outOfStock={car.outOfStock}
                    highDemand={car.highDemand}
                  />
                </div>
              ))}
          </div>
        </div>


        <div className="justify-content-center m-0" style={{ backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="pagination ">
              <div className="left" onClick={handleMove(-1)} style={{ opacity: page ? 1 : 0.5 }}>
                <ChevronLeftIcon />
              </div>
              {Array.from({ length: parseInt(carsLength / 6) + (carsLength % 6 === 0 ? 0 : 1) }, (_, index) => index).map((i) => (
                <div key={uuid()} style={{height:'30px', width:'30px', display:'flex', justifyContent:'center', alignItems:'center'}}
                onClick={() => handleChange(i)} className={`Montserrat page${i === page ? ' selected__page' : ''} `}>
                  {i + 1}
                </div>
              ))}
              <div className="right" onClick={handleMove(1)} style={{ opacity: page === parseInt(carsLength / 6) + (carsLength % 6 === 0 ? 0 : 1) - 1 ? 0.5 : 1 }}>
                <ChevronRightIcon />
              </div>
            </div>
        </div>
      </div>
      
      <RentalNumbers dir={dir}/>
      <Faq dir={dir}/>
      <Ftr dir={dir}/>
    </>
  );
};

export default Home;
