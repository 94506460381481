import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/imgLogoSlider/Audi.svg";
import ClientLogo02 from "../../assets/imgLogoSlider/Bmw.svg";
import ClientLogo03 from "../../assets/imgLogoSlider/Ferrari.svg";
import ClientLogo04 from "../../assets/imgLogoSlider/Hyundai.svg";
import ClientLogo05 from "../../assets/imgLogoSlider/Mercedes.svg";
import ClientLogo06 from "../../assets/imgLogoSlider/Porsche.svg";
import { useTranslation } from "react-i18next";


export default function LogoSlider({dir}) {
  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {t} = useTranslation();
  return (
    
    <Wrapper id="services " className='mt-5 '>
      <h4 className={`text-center p-3 ${dir === 'ltr' ? 'MontserratM' : 'cairoM'}`}  style={{backgroundColor:'#6415FF',color:'#fff'}}>{t('popularBrandText')}</h4>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo05} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo06} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>
      </Slider>
      </div>
      </div>
    </Wrapper>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }

`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;

const Wrapper = styled.section`
  width: 100%;
`;