import { Box, ChakraProvider, Circle, Container, Flex, HStack, Heading, Icon, IconButton, SimpleGrid, Stack, Text, useBreakpointValue, useColorModeValue  } from '@chakra-ui/react'
import * as React from 'react'
import { ProductCard } from './ProductCard'
import { products } from './_data'
import { MdOutlineMap } from "react-icons/md";
import colors from '../../../utils/colors'
import { useTranslation } from 'react-i18next'

const RentalNumbers = ({dir}) => {
  const { t } = useTranslation();
  
  const bgColorCard = useColorModeValue(colors.selectedBtnText, colors.buttonBgColor);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return(
  //  maxW={'9xl'} mx={'auto'} mt={20} py={6} px={{ base: '6', lg: '14' }} 
  <Stack  maxW={{base :'xl', md:'7xl'}} mx={{base:'4', md:'auto'}} px={{base :'6', lg:'8'}} py={6}  spacing={4} position={'relative'} mt={12} >
  <Box  position="absolute" top={0} left={0} width="100%" height="100%"  bgColor={ "#f2f1ff"} zIndex={-1} borderRadius={'3xl'}/>

           
              <Flex justify="space-between" gap="0" bg="">
                  <Flex direction="column" bg="">
                    <Heading
                      as="h1"
                      fontWeight="bold"
                      fontSize={{base:'2rem', lg:'3rem'}}
                      lineHeight="1.1"
                      position="relative"
                      
                    >
                      <Box as="span" color={'#6415FF' } position="relative" className={` ${dir === 'ltr' ? '' : 'cairoB'}`}>
                          {t('rentalNumbers.locations')}
                        <Box
                          position="absolute"
                          width="100%"
                          height="30%"
                          bottom="1px"
                          left="0"
                          bg={'#181c2e'}
                          zIndex="-1"
                        />
                      </Box>
                    </Heading>
                    
                  </Flex>
                
                <Stack  bgColor={''}>
                  
                  {/* <IconBox  as='box' p={2}  variant={''} rounded={'full'} h={{base:"40px", lg:"60px"}} w={{base:"40px", lg:"60px"}} bg={colors.greenPrimary}    
                  >
                      <MdOutlineMap h={"16px"} w={"16px"} color={'white'}  />
                </IconBox> */}

                <IconButton
                  icon={<MdOutlineMap fontSize={isDesktop ? '1.6rem' : '1.1rem'} color={'white'}/>}
                  h={{base:"40px", lg:"60px"}} w={{base:"40px", lg:"60px"}} bg={'#6518ff'}
                  rounded="full"
                  size={'lg'}
                />
              </Stack>

              </Flex>

              <Text
                className={` ${dir === 'ltr' ? 'Montserrat' : 'cairoR'}`}
                fontSize="1rem"
                mb="2"
              >
                {t('rentalNumbers.exploreLocations')}
              </Text>

    <SimpleGrid
      columns={{
        base: 1,
        sm: 2,
        md: 3,
        lg: 4,
      }}
      gap={{
        base: '8',
        lg: '10',
      }}
      
    >
      {products.map((product) => (
        <ProductCard  key={product.id} product={product} />
      ))}
    </SimpleGrid>
  </Stack>
)
      }


export default RentalNumbers
